import React from 'react';
import clsx from 'clsx';

const ManageAlertsConfirm = ({isSingleDeleted, isVisible, closeDialog, deleteClicked }) => {

    return (
        <div data-testid="ManageAlertsConfirm" tabIndex="-1" role="dialog" id="confirm-centered-small-content" className={clsx("wk-modal wk-modal-small wk-modal-vertical-middle wk-modal-with-overlay wk-modal-small-content", { "wk-modal-opened": isVisible })}>
            <section className="wk-modal-content wk-modal-content-layout wk-modal-delete-confirm compare-overlay-center ">
               <header className="wk-modal-header">
                  <h4 className="wk-modal-title">Confirm Deletion</h4>      
                  <button id="btnManageAlertsConfirmOverlay" aria-label="close dialog" className="wk-button-icon wk-modal-close wk-overlay-button" onClick={closeDialog}><span wk-icon="close"></span></button>
               </header>
               <div className="wk-modal-body">
                    <p><span className="ui-icon ui-icon-alert" > </span> Are you sure you want to delete this Alert Setting{isSingleDeleted ? '' : 's'}? This action cannot be undone</p>
               </div>
               <footer className="wk-modal-footer wk-modal-delete-confirm-footer">
                  <div className="wk-button-bar wk-modal-bar">            
                        <div className="wk-button-group-right">
                            <button data-testid="ManageAlertsConfirm_Close" className="wk-button-secondary" onClick={closeDialog} type="button">Cancel</button>
                            <button data-testid="ManageAlertsConfirm_Delete" type="submit" onClick={deleteClicked}>Delete</button>                       
                     </div>
                 </div >
               </footer >
            </section >
        </div >
    );
}

export default ManageAlertsConfirm

