import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { readcheckedresetAllReadData } from "state/actions/readCheckedActions";
import { spinnerChangedAction } from "state/actions/spinnerActions";
import { downloadLawyers } from "services/DownloadService";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useHistory } from "react-router";
import { post } from 'services/ApiService';

export const ReadCheckedHeader = ({ isToolbarActive, print, showParentAlertOverlay }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const currentLawyers = _.values(useSelector(state => state.readchecked.currentLawyers));
    const companyResult = useSelector(state => state.readchecked.companyResult);
    const allLawyers = _.values(useSelector(state => state.readchecked.allLawyers));
    const userInfo = useSelector(state => state.home.userInfo);
    const showAlertButton = !userInfo?.Sub?.startsWith('TempUser');
    const downloadCsv = e => {
        dispatch(spinnerChangedAction(true));
        const lawyerIds = currentLawyers.filter(lawyer => lawyer.checked).map(lawyer => lawyer.lId).join(",");
        downloadLawyers(lawyerIds).catch(error => logAndRedirectToErrorPage(error, history)).finally(() => dispatch(spinnerChangedAction(false)));
    }

    const processingParentCompanies = () => {
        let parentCompaniesList = [];
        let checkedUberIds = currentLawyers.filter(item => item.checked).map(item => item.uberId);
        let filteredParentCompanies = companyResult.filter(i => checkedUberIds.includes(i.uberId && i.cId));
        let sortedParentCompanies = filteredParentCompanies.sort((a, b) => (a.currentName > b.currentName) ? 1 : -1);
        let parentSelections = sortedParentCompanies.map((i) => ({ 'checked': true, 'company': i.currentName, 'fkParentCompany': i.uberId.toString() }));
        parentSelections.map(item => parentCompaniesList.push(item.fkParentCompany));
        post('/api/Alert/getAlertsPresent', parentCompaniesList.join(','))
            .then(data => {
                showParentAlertOverlay(data, parentSelections)
                dispatch(spinnerChangedAction(false));
            });
    };

    const onCreateAlertClicked = () => {
        dispatch(spinnerChangedAction(true));
        setTimeout(() => {
            processingParentCompanies();
        }, 100);
    };

    return (<>
        <div className="wk-button-bar" id="readFilterContainer">
            <div className="wk-button-group-right">
                <button wk-icon="close" type="button"
                        disabled={currentLawyers.length === allLawyers.length}
                        onClick={() => dispatch(readcheckedresetAllReadData())}
                >
                    &nbsp;Clear Filters
                </button>
                <button wk-icon="print" type="button" disabled={!isToolbarActive} onClick={() => print()}/>
                <button wk-icon="file-down" type="button" disabled={!isToolbarActive} onClick={downloadCsv}/>               
                {showAlertButton && (
                    <button wk-icon="alarm-on" type="button" disabled={!isToolbarActive} onClick={onCreateAlertClicked} />
                )}
                {/* <button wk-icon="star" type="button" disabled></button>
                <button wk-icon="alarm-on" type="button" disabled></button>
                
                <button wk-icon="mail" type="button" disabled></button>*/}
            </div>
        </div>
    </>);
};
