import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetAllSearchData, setSelectAll as setSelectAllAction } from "state/actions/searchActions";
import { useHistory } from "react-router";
import { downloadLawyers } from "services/DownloadService";
import { post } from 'services/ApiService';
import { reduceSelections } from '../utils/reduceSelections';
import { spinnerChangedAction } from "state/actions/spinnerActions"
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import SearchResultOptions from 'pages/Search/SearchResultOptions';

export const SearchResultHeader = ({ print, showParentAlertOverlay, changeHandler, clearHandler }) => {
    const currentPage = useSelector(state => state.search.searchPage);
    const mainData = useSelector(state => state.search.currentMainResults);
    const allData = useSelector(state => state.search.allMainResults);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector(state => state.home.userInfo);
    const showAlertButton = !userInfo?.Sub?.startsWith('TempUser');

    useEffect(() => {
        if (mainData.length > 0 && mainData.length === mainData.filter(item => item.checked === true).length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [mainData]);
    const isToolbarActive = mainData.filter(item => item.checked).length > 0 || selectAll;
    const onChangeSelectAll = e => {
        setSelectAll(e.target.checked);
        dispatch(setSelectAllAction(e.target.checked));
    };
    const downloadCsv = e => {
        dispatch(spinnerChangedAction(true));
        const lawyerIds = mainData.filter(lawyer => lawyer.checked).map(lawyer => lawyer.lawyerId).join(",");
        downloadLawyers(lawyerIds).catch(error => logAndRedirectToErrorPage(error, history)).finally(() => dispatch(spinnerChangedAction(false)));
    }
    const viewCheckedOnChange = () => {
        let lastIndex = currentPage * 100;
        let firstIndex = lastIndex - 100;
        let lawyerIds = [];
        if (selectAll) {
            lawyerIds = mainData.slice(firstIndex, lastIndex).map(item => item.lawyerId);
            let path = `/search/readchecked`;
            let queryString = `lids=${lawyerIds}`;
            history.push({ pathname: path, search: queryString });
            return;
        }
        lawyerIds = mainData.slice(firstIndex, lastIndex).filter(item => item.checked).map(item => item.lawyerId);
        let path = `/search/readchecked`;
        let queryString = `lids=${lawyerIds}`;
        history.push({ pathname: path, search: queryString });
    };
    const processingParentCompanies = () => {
        let showWarning = false;
        let parentCompaniesList = [];
        let checked = mainData.filter(item => item.checked)
        let deduped = checked.filter((v, i, a) => a.findIndex(t => (t.fkParentCompany === v.fkParentCompany)) === i)
        let sorted = deduped.sort((a, b) => (a.company > b.company) ? 1 : -1)
        if (sorted.length > 100) {
            sorted = reduceSelections(sorted, 100);
            showWarning = true;
        }
        sorted.map(item => parentCompaniesList.push(item.fkParentCompany));
        post('/api/Alert/getAlertsPresent', parentCompaniesList.join(','))
            .then(data => {
                showParentAlertOverlay(data, sorted, showWarning)
                dispatch(spinnerChangedAction(false));
            });
    };
    const onCreateAlertClicked = () => {
        dispatch(spinnerChangedAction(true));
        setTimeout(() => {
            processingParentCompanies();
        }, 100);
    };
    return (
        <>
            <div>
                <ul id="searchIcon" className="wk-nav" style={{ height: 48.4 }}>
                    <li>
                        <span>
                            <input type="checkbox"
                                style={{ marginTop: 15 }}
                                onChange={onChangeSelectAll}
                                checked={selectAll} />
                        </span>
                    </li>
                    <li>
                        <button wk-icon="print" type="button" disabled={!isToolbarActive} onClick={print} />
                    </li>
                    <li>
                        <button wk-icon="file-down" disabled={!isToolbarActive} onClick={downloadCsv} />
                        {/*{*/}
                        {/*    !isToolbarActive ? <button wk-icon="file-down" type="button" disabled/> :*/}
                        {/*        mainData && mainData.length > 0 && (*/}
                        {/*            <CSVLink*/}
                        {/*                style={{ color: "black", padding: 0 }}*/}
                        {/*                data={csv}*/}
                        {/*                filename={`ccp-data-${date}.csv`}*/}
                        {/*            >*/}
                        {/*                <button wk-icon="file-down" type="button"/>*/}
                        {/*            </CSVLink>*/}

                        {/*        )}*/}

                    </li>
                    {showAlertButton && (
                        <li>
                            <button wk-icon="alarm-on" type="button" disabled={!isToolbarActive} onClick={onCreateAlertClicked} />
                        </li>
                    )}
                    <li style={{ height: "100%" }}>
                        <button wk-button="full" type="button" onClick={viewCheckedOnChange} disabled={!isToolbarActive}>View
                        Checked
                        </button>
                    </li>
                    <li style={{ float: 'right' }}>
                        <button wk-button="full" type="button" onClick={clearHandler}
                            disabled={(mainData.length === allData.length)}>Clear Filters
                        </button>
                    </li>
                    <li style={{ float: 'right', marginRight: 8, marginTop: -1, height: 48.2 }}>
                        <SearchResultOptions changeHandler={changeHandler} />
                    </li>
                  
                    {/*<li><a><span wk-icon="star"/></a></li>
        <li><a><span wk-icon="alarm-on"/></a></li>
        <li><a onClick={props.print}><span wk-icon="print"/></a></li>
        <li>
            {mainData && mainData.length > 0 && (
                <CSVLink
                    style={{color: "black"}}
                    data={csv}
                    filename={`ccp-data-${date}.csv`}
                >
                    <span wk-icon="file-down"/>
                </CSVLink>
            )}

        </li>
        <li><a><span wk-icon="mail"></span></a></li>*/}
                </ul>   
            </div>      
            <div className="wk-search-view-result wk-text-align-center"> Viewing {mainData ? mainData.length : 0} Results</div>
     </>
    );
};
