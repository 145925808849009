import React, { useState } from 'react';
import clsx from 'clsx';
import {useForm} from "react-hook-form";
import {useHistory} from "react-router";
import querystring from "query-string";
import WaringDialog from "pages/Common/WarningDialog";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { companyStates, usStateBars, nonUSStateBars4CA, nonUSStateBars4Others } from '../Home/Data/config';
const checkboxClassName = 'wk-checkbox-field';
const checkboxRedColorClassName = 'wk-checkbox-error';
const SearchForm = ({ practiceAreas, searchWarning }) => {

    const { handleSubmit, register, errors } = useForm();
    const [selectedCompanyStates] = useState([]);
    const [selectedPracticeAreas] = useState([]);
    const [selectedNonUSStates] = useState([]);
    const [selectedUSStates] = useState([]);
    const [header, setHeader] = useState('');
    const [message, setMessage] = useState('');
    const [isVisible, setVisible] = useState(false);

    let history = useHistory();
     const onSubmit = values => {
        const queryParams = querystring.stringify(values);
        let Params = queryParams.split('&');
        let queryString = '';
        let companystates = '';
        let practiceAreas = '';
        let USStates = '';
        let NonUSState = '';
        let canPush = true;
        let startYear = 0;
         let EndYear = 0;

         Params.map((item) => {

            let Param = item.split('=');
             if (!(Param[1] == '' || Param[1] == 'false')) {

                let prefix = '';
                let elementId = '';
                Param[0].split('_').map((item, index) => {
                    switch (index) {
                        case 0:
                            prefix = item;
                            break;
                        case 1:
                            elementId = item;
                            break;
                        default:
                    }
                })

                if (!elementId.length) {
                    queryString += Param[0] + '=' + Param[1] + '&';
                    if (Param[0] == 'yf')
                        startYear = parseInt(Param[1]);
                    if (Param[0] == 'yt')
                        EndYear = parseInt(Param[1]);

                    if (startYear > 0 && EndYear > 0) {
                        if (EndYear < startYear) {
                            canPush = false;
                            setHeader("Invalid Graduation Year Warning");
                            setMessage("Graduation year from is greater than graduation year to");
                        }
                    }

                } else {
                    switch (prefix) {
                        case 'cs':
                            companystates += elementId + '|';
                            break;
                        case 'ps':
                            practiceAreas += elementId + '|';
                            break;
                        case 'us':
                            USStates += elementId + '|';
                            break;
                        case 'no':
                            NonUSState += elementId + '|';
                            break;
                        default:
                    }
                }
            }
        })

        if (companystates.length > 0) {
            companystates = companystates.substring(0, companystates.length - 1)
            queryString += 'cs=' + decodeURI(companystates) + '&';
        }
        if (practiceAreas.length > 0) {
            practiceAreas = practiceAreas.substring(0, practiceAreas.length - 1)
            queryString += 'ap=' + practiceAreas + '&';
        }
        if (USStates.length > 0) {
            USStates = USStates.substring(0, USStates.length - 1)
            queryString += 'auj=' + USStates + '&';
        }
        if (NonUSState.length > 0) {
            NonUSState = NonUSState.substring(0, NonUSState.length - 1)
            queryString += 'anj=' + NonUSState + '&';
        }

         if (queryString.length > 0) {
             queryString = queryString.substring(0, queryString.length - 1)
         }
         else {
             canPush = false;
             setHeader("No Parameters Warning")
             setMessage("Please select search parameters");

         }

        if (!canPush) {
            setVisible(true);
        }
        else {
            searchWarning(queryString, null,"You have entered a term that delivers too many results. Please enter a new term or add additional terms/options to limit your search.");            
        }
    };

    const closeDialog = (e) => {
        setVisible(false);
    }
    const onCheckChange = (e) => {
        try {
            let prefix = '';
            let elementId = '';

            e.target.name.split('_').map((item, index) => {
                switch (index) {
                    case 0:
                        prefix = item;
                        break;
                    case 1:
                        elementId = item;
                        break;
                    default:
                }
            })

            switch (prefix) {
                case 'cs':
                    updatedStatus(selectedCompanyStates, prefix, elementId, e);
                    break;
                case 'ps':
                    updatedStatus(selectedPracticeAreas, prefix, elementId, e);
                    break;
                case 'us':
                    updatedStatus(selectedUSStates, prefix, elementId, e);
                    break;
                case 'no':
                    updatedStatus(selectedNonUSStates, prefix, elementId, e);
                    break;
                default:
            }
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }

    const updatedStatus = (selectedItems, prefix, elementId, e) => {        
        let parentEle = e.target.parentElement.parentElement;
        const itemsLimit = parentEle.id.includes("_cs_") ? 3 : 4;
        if (e.target.checked) {
            if (selectedItems.length >= itemsLimit) {
                e.target.checked = false;
                parentEle.className = checkboxRedColorClassName;
            }
            else {
                selectedItems.push(parentEle.id);
            }
        }
        else {

            if (selectedItems.length >= itemsLimit) {
                updatedBackgroundColor(prefix);
            }
            selectedItems.splice(parentEle.id, 1);
        }
    }

    const updatedBackgroundColor = (prefix) => {

        switch (prefix) {
            case 'cs':
                companyStates.map((item) => {
                    document.getElementById('_cs_' + item.id).className = checkboxClassName;
                });
                break;
            case 'ps':
                practiceAreas.map((item) => {
                    document.getElementById('_ps_' + item.id).className = checkboxClassName;
                });
                break;
            case 'us':
                usStateBars.map((item, index) => {
                    document.getElementById('_us_' + index).className = checkboxClassName;
                });
                break;
            case 'no':
                nonUSStateBars4CA.map((item, index) => {
                    document.getElementById('_no_' + index).className = checkboxClassName;
                });
                break;
            default:
        }
    }

    const filterTitle = `Corporate Counsel Search`;
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="wk-content-filter-layout">
                    <div className="wk-content-filter-left wk-search-filter-wrap">
                        <div className="wk-content-filter">
                            <div className="wk-search-filter-title" style={{ whiteSpace: 'pre' }}>{filterTitle}</div>

                            <div className="wk-field-header wk-search-filter-center">Company</div>
                            <div className="wk-font-label">
                                Company Name
                        </div>
                            <input id="cn" ref={register()} type="text" placeholder="Enter Company Name"
                                className="wk-input-height" maxLength="100" name="cn" />
                            <div className="wk-font-label">
                                Company City
                        </div>
                            <input id="cc" ref={register()} type="text" placeholder="Enter City Name"
                                className="wk-input-height" maxLength="30" name="cc" />
                            <div className="wk-font-label">
                                Company State
                        </div>
                            <div id="stateReminder">
                                Choose up to three options
                        </div>
                            <div className="wk-checkbox-wrap">
                                {companyStates.map((item) => (
                                    <div id={'_cs_' + item.id} className="wk-checkbox-field" key={item.id}>
                                        <label>
                                            <input ref={register()} type="checkbox" onChange={onCheckChange}
                                                name={'cs_' + item.id} /><span>{item.desc}</span>
                                        </label>
                                    </div>

                                ))}
                            </div>
                            <div className="wk-field-header wk-search-filter-center">Attorney</div>
                            <div className="wk-font-label">
                                Attorney Name:
                        </div>
                            <input id="an" ref={register()} type="text" placeholder="Enter attorney name"
                                className="wk-input-height" name="an" />

                            <div className="wk-font-label">
                                Practice Area(s)
                        </div>
                            <div id="stateReminder">
                                Choose up to four options
                        </div>
                            <div className="wk-checkbox-wrap">
                                {practiceAreas.map((item) => (
                                    <div id={'_ps_' + item.id} className="wk-checkbox-field" key={item.id}>
                                        <label>
                                            <input ref={register()} type="checkbox" onChange={onCheckChange}
                                                name={'ps_' + item.id} /><span>{item.desc}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="wk-font-label-empty" />
                            <div className="wk-font-label">
                                US State Bar Jurisdictions
                        </div>
                            <div id="stateReminder">
                                Choose up to four options
                        </div>
                            <div className="wk-checkbox-wrap">
                                {usStateBars.map((item, index) => (

                                    <div id={'_us_' + index} className="wk-checkbox-field" key={'div_us_' + index}>
                                        <label>
                                            <input ref={register()} type="checkbox" onChange={onCheckChange}
                                                name={'us_' + item} /><span>{item}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="wk-font-label-empty" />
                            <div className="wk-font-label">
                                Non-US Bar Jurisdictions
                        </div>
                            <div id="stateReminder">
                                Choose up to four options
                        </div>
                            <div className="wk-checkbox-wrap">
                                <div className="wk-font-label-nonUs" >Canada</div>
                                {nonUSStateBars4CA.map((item, index) => (
                                    <div id={'_no_' + index} className="wk-checkbox-field" key={'div_no_' + index}>
                                        <label>
                                            <input ref={register()} type="checkbox" onChange={onCheckChange}
                                                name={'no_' + item} /><span>{item}</span>
                                        </label>
                                    </div>
                                ))}
                                <div className="wk-font-label-nonUs wk-margin-top-minu-5">Others</div>
                                {nonUSStateBars4Others.map((item, index) => (
                                    <div id={'_no_' + (index + nonUSStateBars4CA.length)} className="wk-checkbox-field" key={'div_no_' + (index + nonUSStateBars4CA.length)}>
                                        <label>
                                            <input ref={register()} type="checkbox" onChange={onCheckChange}
                                                name={'no_' + item} /><span>{item}</span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="wk-font-label-empty">

                            </div>
                            <div className="wk-font-label">
                                Current Position
                        </div>
                            <input id="at" ref={register()} type="text" placeholder="Enter position name"
                                className="wk-input-height" maxLength="100" name="at" />
                            <div className="wk-font-label">
                                Memberships / Affiliations
                        </div>
                            <input id="am" ref={register()} type="text" placeholder="Enter organization name"
                                className="wk-input-height" maxLength="100" name="am" />
                            <div className="wk-font-label">
                                School
                        </div>
                            <input id="al" type="text" ref={register()} placeholder="Enter school name"
                                className="wk-input-height" maxLength="30" name="al" />
                            <div className="wk-font-label">
                                Degree
                        </div>
                            <input id="ad" type="text" ref={register()} placeholder="Enter Degree"
                                className="wk-input-height" maxLength="100" name="ad" />
                            <div className="wk-font-label">
                                Graduation Year
                        </div>
                            <div className={clsx("wk-field-item wk-field-item-margin", { "wk-field-has-error": errors.yf })}>
                                <label id="gradYearFrom" htmlFor="yf">From:</label>
                                <input id="yf" type="text" placeholder="yyyy" className="wk-input-height" maxLength="4" name="yf"
                                    ref={register({ validate: value => !isNaN(value) })} />
                                {errors.yf && <div className="wk-field-error">The input is number only</div>}
                            </div>
                            <div className={clsx("wk-field-item wk-field-item-margin", { "wk-field-has-error": errors.yt })}>
                                <label id="gradYearTo" htmlFor="yt">To:</label>
                                <input id="yt" type="text" placeholder="yyyy" className="wk-input-height" maxLength="4" name="yt"
                                    ref={register({ validate: value => !isNaN(value) })} />
                                {errors.yt && <div className="wk-field-error">The input is number only</div>}
                            </div>
                            <div className="wk-button-submit"><button className="submit-search-form" type="submit">Submit</button></div>
                        </div>
                    </div>
                </div>
            </form>
            <WaringDialog isVisible={isVisible} header={header} message={message} closeDialog={closeDialog} />
        </div>
    );
};

export default SearchForm;
