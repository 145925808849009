import React, { Component, useEffect, useState } from 'react';
import clsx from "clsx";
import { Link } from 'react-router-dom';
import Products from './Products';
import UserID from './UserID';
import ManageAlertsOverlay from '../../components/layout/ManageAlerts/ManageAlertsOverlay';
import ManageAlertsConfirm from '../../components/layout/ManageAlerts/ManagerAlertsConfirm';
import { get } from "services/ApiService";
import { useSelector } from "react-redux";


const Header = ({ AlertUpdated }) => {
    let displayName = Header.name;
    const [collapsed, setCollapsed] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [managealertList, setManagealertList] = useState([]);
    const userInfo = useSelector(state => state.home.userInfo);
    const showAlertButton = !userInfo?.Sub?.startsWith('TempUser');
   
   
    useEffect(() => {
        getManageAlertsData();
    }, [AlertUpdated]);
   
    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }

    const showManageAlerts = () => {
        setVisible(true);
    };

    const closeDialog = (e) => {
        getManageAlertsData();
        setVisible(false);
    }

    const getManageAlertsData = () => {
        get('./api/ManageAlerts/searchManageAlerts/?')
            .then(data => setManagealertList(data));
    }

    return (
        <header role="banner" className="wk-header">
            <div className="wk-header-container"><Link to="/" className="wk-logo"><img src="https://cdn.wolterskluwer.io/wk-logos/1.0.x/wk-brand-small.svg" alt="Wolters Kluwer Product Name - Page anchor links to" className="wk-logo-small"></img><img src="https://cdn.wolterskluwer.io/wk-logos/1.0.x/wk-brand.svg" alt="Wolters Kluwer Product Name - Page anchor links to" className="wk-logo-large"></img></Link>
                <ul className={clsx("wk-more-menu", { "open": collapsed }, "wk-green-icons")} >
                    <li role="presentation"><a onClick={() => toggleNavbar()}><span wk-icon="more"></span>More</a></li>
                </ul>
                <ul className={clsx("wk-nav", { "open": collapsed }, "wk-green-icons")}>
                    {showAlertButton && (
                    <li role="presentation">
                        <a className={managealertList.length ? "wk-link" : "manage-alerts-disabled"} onClick={() => showManageAlerts()}>
                            <span wk-icon="alarm-on"></span>Alert
                            </a>
                    </li>
                    )}
                    <li role="presentation">
                        <a href="https://d11pd7eb7943x.cloudfront.net/ccp/ccphelp.mp4" target="_blank" rel="noopener noreferrer">
                            <span wk-icon="question"></span>Help
                            </a>
                    </li>
                    <li role="presentation" className="wk-dropdown"><a id="nav-dropdown-logout" aria-haspopup="true" aria-expanded="false" className="wk-dropdown-toggle"><span wk-icon="lock-open"></span>Logout</a>
                        <div aria-hidden="true" aria-label="submenu" aria-labelledby="nav-dropdown-logout" className="wk-dropdown-menu wk-dropdown-right">
                            <div className="wk-dropdown-body">
                                <p>Logged in as <UserID /></p>
                                <button className="wk-button-success wk-button-full" type="button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.href = '/account/logout';
                                    }}>Log out</button>
                            </div>
                        </div>
                    </li>
                    <li role="presentation" className="wk-dropdown"><a id="nav-dropdown-language" aria-haspopup="true" aria-expanded="false" className="wk-dropdown-toggle"><span wk-icon="grid-square"></span>My Solutions</a>
                        <div aria-hidden="true" aria-label="submenu" aria-labelledby="nav-dropdown-language" className="wk-dropdown-menu wk-dropdown-right">
                            <Products />
                        </div>
                    </li>

                </ul>
            </div>

            { isVisible && <ManageAlertsOverlay isVisible={isVisible} closeDialog={closeDialog} managealertList={managealertList} />}

        </header>
    );
}

export default Header;