import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import color from "../../utils/color";
import { HorizontalBarchart } from "components/charts/HorizontalBarchart";
import { getLawyerPACount } from '../utils/lawyerPACount';
import { getLawyerCollegeCount } from '../utils/lawyerCollegeCount';
import _, { result } from "lodash";

const getTitleCount = (currentPracticeAreasResults, top) => {
    let practiceAreas = currentPracticeAreasResults.map(a => a.title);
    let occurrences = {};
    for (let i = 0, j = practiceAreas.length; i < j; i++) {
        occurrences[practiceAreas[i]] = (occurrences[practiceAreas[i]] || 0) + 1;
    }
    let result = Object.entries(occurrences).map(([title, count]) => ({ title, count }));
    result.sort((a, b) => b.count - a.count);
    return result.slice(0, top);
}

const ViewCheckedAllBIs = ({ currentResult, selectedBIIndex, filterByLawyerIds, isPieChart }) => {
    const [stats, setStats] = useState([]);
    const [BIIndexs, setBIIndexs] = useState([]);
    useEffect(() => {
        if (currentResult) {
            let resultAll = [];
            let resultNotSorted = [];
            let indexs = [];
            switch (selectedBIIndex) {
                case 0:
                    //Colleges
                    resultAll = getLawyerCollegeCount(_.cloneDeep(currentResult), 0);
                    break;
                case 1:
                    //PA's
                    resultAll = getLawyerPACount(currentResult, 0);
                    break;
                case 2:
                    //Titles
                    resultAll = getTitleCount(currentResult, currentResult.length);
                    break;
                default:
                    resultAll = [];
            }

            setStats(resultAll);
            setBIIndexs(indexs);
        }
    }, [currentResult]);
    return (
        <div
            id="psfreadCheckedAllBIs"
        >
            {isPieChart && selectedBIIndex == 0 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Colleges"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.college)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_COLLEGES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 0 && <HorizontalBarchart
                selectedBIIndex={selectedBIIndex}
                label="Colleges"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.college)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_COLLEGES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}

            {isPieChart && selectedBIIndex == 1 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.desc)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 1 && <HorizontalBarchart
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.desc)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}

            {isPieChart && selectedBIIndex == 2 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Titles"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.title)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_TITLES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 2 && <HorizontalBarchart
                label="Titles"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.title)}
                ref={registerTestObject(BI.READ_CHECKED_OVERLAY_TITLES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}

        </div>
    );
};
export default ViewCheckedAllBIs;
