import { getStateName } from './getStateName.js';

export function getStateCountryTitle(selstatecodes) {
    var title = "";
    var i;

    if (typeof selstatecodes === "object") {
        for (i = 0; i < selstatecodes.length; i++) {
            title = title + getStateName(selstatecodes[i]) + ", ";
        }
        return title.substr(0, title.length - 2);
    } else {
        return getStateName(selstatecodes);
    }
}