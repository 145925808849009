import React from 'react';
import { useSelector } from "react-redux";
import { REPORTS_TYPE } from 'pages/utils/common';

export const RBSFilings = ({ ticker }) => {
    const rbsfilingsInfo = useSelector(state => state.read.rbsfilingsInfo);

    const url = (rbsfilings) => {
        if (rbsfilings) {
            return rbsfilings.rootUrl + 'id=' + rbsfilings.userId + "&date=" + rbsfilings.date + "&hash=" + rbsfilings.hash + "&tickersymbol=" + ticker;
        }
        return "";
    }

    return (
        <>
            <span className="wk-font-weight-bold">{REPORTS_TYPE[0]}</span>
            <ul>
                <li> <span><a className="wk-link" onClick={() => window.open(url(rbsfilingsInfo) + '&formtype=10-K&formtype=20-F')}>{REPORTS_TYPE[1]}</a></span></li>
                <li><span><a className="wk-link" onClick={() => window.open(url(rbsfilingsInfo) + '&formtype=10-Q')}>{REPORTS_TYPE[2]}</a></span></li>
                <li><span><a className="wk-link" onClick={() => window.open(url(rbsfilingsInfo) + '&formtype=8-k&formtype=6-K')}>{REPORTS_TYPE[3]}</a></span></li>
            </ul>         
        </>
    );
};