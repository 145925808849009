import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router";
import queryString from "querystring";
import { resetAllReadData } from "state/actions/readActions";
import { downloadLawyers } from "services/DownloadService";
import { spinnerChangedAction } from "state/actions/spinnerActions";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useHistory } from "react-router";
import { post } from 'services/ApiService';

export const ReadHeader = ({ isToolbarActive, print, showParentAlertOverlay }) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const location = useLocation();
    const { pid } = queryString.parse(location.search.substring(1));
    const currentCompanyLawyers = _.values(useSelector(state => state.read.currentCompanyLawyers));
    const allCompanyLawyers = _.values(useSelector(state => state.read.allCompanyLawyers));
    const companyResult = useSelector(state => state.read.companyResult);
    const parentCompanyResult = useSelector(state => state.read.parentCompanyResult);
    const userInfo = useSelector(state => state.home.userInfo);
    const showAlertButton = !userInfo?.Sub?.startsWith('TempUser');

    const downloadCsv = e => {
        dispatch(spinnerChangedAction(true));
        const lawyerIds = currentCompanyLawyers.filter(lawyer => lawyer.isChecked).map(lawyer => lawyer.lId).join(",");
        downloadLawyers(lawyerIds).catch((error) => logAndRedirectToErrorPage(error, history)).finally(() => dispatch(spinnerChangedAction(false)));
    }
    const processingParentCompanies = () => {
        let company = "";
        if (parentCompanyResult) {
            company = parentCompanyResult.currentName;
        } else {
            company = companyResult.currentName;
        }
        let parentSelection = [{ 'checked': true, 'company': company, 'fkParentCompany': pid }];
        post('/api/Alert/getAlertsPresent', pid)
            .then(data => {
                showParentAlertOverlay(data, parentSelection)
                dispatch(spinnerChangedAction(false));
            });
    };
    const onCreateAlertClicked = () => {
        dispatch(spinnerChangedAction(true));
        setTimeout(() => {
            processingParentCompanies();
        }, 100);
    };
    return (<>
        <div className="wk-button-bar" id="readFilterContainer">
            <div className="wk-button-group-right">
                <button wk-icon="close" type="button"
                        disabled={currentCompanyLawyers.length === allCompanyLawyers.length}
                        onClick={() => dispatch(resetAllReadData())}
                >
                    &nbsp;Clear Filters
                </button>
                <button wk-icon="print" type="button" disabled={!isToolbarActive} onClick={() => print()}/>
                <button wk-icon="file-down" type="button" disabled={!isToolbarActive} onClick={downloadCsv}/>
                {showAlertButton && (
                    <button wk-icon="alarm-on" type="button" onClick={onCreateAlertClicked} />
                )}
                {/* <button wk-icon="star" type="button" disabled></button>                
                <button wk-icon="mail" type="button" disabled></button>*/}
            </div>
        </div>
    </>);
};
