import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import UserID from 'components/layout/UserID';
import { get } from "../../services/ApiService";
import EmailAdditionalRecipients from '../../common/EmailAdditionalRecipients/EmailAdditionalRecipients';
import { useSelector } from 'react-redux';

const EditParentAlertOverlay = ({ isVisible, selectedAlert, saveAlert, closeOverlay }) => {

    const [originalAdditionalRecipients, setOriginalAdditionalRecipients] = useState('');
    const [additionalRecipients, setAdditionalRecipients] = useState('');
    const [isDirty, setIsDirty] = useState(false);
    const userInfo = useSelector(state => state.home.userInfo);

    useEffect(() => {
        get('./api/ManageAlerts/getAdditionalRecipients?alertId=' + selectedAlert.alertId)
            .then(data => {                
                if (data && data.additionalrecipients) {
                    setAdditionalRecipients(data.additionalrecipients);
                    setOriginalAdditionalRecipients(data.additionalrecipients);
                }
            });
    }, [selectedAlert]);
    const syncAdditionalRecipients = (additionalRecipients) => {
        setIsDirty(true);
        setAdditionalRecipients(additionalRecipients);
    }
    return (
        <div data-testid="EditParentAlertOverlay" id="EditParentAlertOverlay" tabIndex="-1" role="dialog" id="centered-form"
            className={clsx("wk-modal wk-modal-large wk-modal-vertical-middle wk-modal-with-overlay", { "wk-modal-opened": isVisible })}>
            <section className="wk-modal-content">
                <header className="wk-modal-header">
                    <div className="wk-modal-title">Edit Alert</div>
                    <button data-testid="EditParentAlertOverlay_Close" id="btnWarningDialog" onClick={closeOverlay} aria-label="close dialog" className="wk-button-icon wk-modal-close"><span wk-icon="close"></span></button>
                </header>
                <div className="wk-modal-body">
                    <div className="wk-field-item">
                        <div className="wk-edit-alert-font-label">Company Alert Name</div>
                        <div className="scroll-checkbox-5">
                            <span>{selectedAlert ?.company}</span>
                        </div>
                        <br />
                        <p>Your selected Company Alert will be sent weekly to your email account at {userInfo.Email}.</p>
                        <EmailAdditionalRecipients isVisible={true} additionalRecipients={additionalRecipients} syncAdditionalRecipients={syncAdditionalRecipients} />
                    </div>
                </div>
                <footer className="wk-modal-footer">
                    <div className="wk-button-bar">
                        <div className="wk-button-group-right">
                            <button data-testid="EditParentAlertOverlay_Cancel" className="wk-button-secondary" onClick={closeOverlay}>Cancel</button>
                            <button data-testid="EditParentAlertOverlay_Save" onClick={() => { closeOverlay(); saveAlert(additionalRecipients, selectedAlert.alertId, selectedAlert.parentcompanyid, originalAdditionalRecipients); }} disabled={!isDirty}>Save</button>
                        </div>
                    </div>
                </footer>
            </section>

        </div>
    );
}

export default EditParentAlertOverlay;