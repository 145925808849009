import React, { useState } from 'react';
import './Footer.css';
import dayjs from "dayjs";
import Feedback from './Feedback';

const Footer = () => {
    const [isVisible, setVisible] = useState(false);

    const showFeedbackOverlay = () => {
        setVisible(true);
    }

    const closeFeedback = (e) => {
        setVisible(false);
    }

    return (
        <footer className="wk-footer" role="contentinfo">
            <div className="wk-footer-container">
                <div className="wk-footer-nav">
                    <div className="wk-footer-nav-list">
                        <h3 className="wk-footer-heading">Corporate Counsel Profiler </h3>
                        <ul>
                            <li><a href="https://lrus.wolterskluwer.com/policies/privacy-statement/" target="_blank" rel="noopener noreferrer">Privacy Statement </a></li>
                            <li><a href="https://lrus.wolterskluwer.com/policies/online-terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use </a></li>
                            <li><a href="https://d11pd7eb7943x.cloudfront.net/ccp/ccphelp.mp4" target="_blank" rel="noopener noreferrer">Help </a></li>
                        </ul>
                    </div>
                    <div className="wk-footer-nav-list">
                        <h3 className="wk-footer-heading"></h3>
                        <ul>
                            <li></li>
                        </ul>
                    </div>
                    <div className="wk-footer-nav-list">
                        <h3 className="wk-footer-heading">Support </h3>
                        <ul>
                            <li>Tel: 1-866-529-6600</li>
                            <li><a href="mailto:LRUSCustomerCare@wolterskluwer.com">E-mail: LRUSCustomerCare@wolterskluwer.com </a></li>
                            
                        </ul>
                    </div>
                    <div className="wk-footer-nav-list">
                        <ul>
                            <li className="wk-feedback"><a onClick={showFeedbackOverlay}><span wk-icon="mail"></span> Feedback</a></li>
                        </ul>
                    </div>
                </div><a className="wk-logo" href="https://www.wolterskluwer.com/en" target="_blank" rel="noopener noreferrer"><img className="wk-logo-small" src="https://cdn.wolterskluwer.io/wk-logos/1.0.x/wk-brand-small-white.svg" alt="Wolters Kluwer Product Name - Page anchor links to" /><img className="wk-logo-large" src="https://cdn.wolterskluwer.io/wk-logos/1.0.x/wk-brand-white.svg" alt="Wolters Kluwer Product Name - Page anchor links to" /></a>
                
            </div>
            <div className="wk-footer-copyright">
                <div className="wk-footer-copyright-container">
                    <ul className="wk-footer-meta-info">
                        <li>©{dayjs().format("YYYY")}. Wolters Kluwer. All Rights Reserved.</li>
                    </ul>
                </div>
            </div>
            <Feedback isVisible={isVisible} closeFeedback={closeFeedback} />
        </footer>

    );
}

export default Footer;