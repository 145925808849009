import * as React from 'react';
import './newsTickerStyle.css';
import './styles.css';

const NewsTicker = (props) => {
    return (
        <a className="newsticker tickerItem" href={props.url} target="_blank">
            <div className="newsWrapper">
                <span className="icon" wk-icon="triangle-right" wk-icon-type="filled"/>
                <div className="title">{props.title}</div>
            </div>
        </a>
    )
}

export default NewsTicker;
