import { call, put, fork, takeLatest } from "redux-saga/effects";
import {
    SEARCH_DATA_REQUESTED,
    PRACTICEAREA_DATA_REQUESTED,
    YEARSADMITEDTOBAR_DATA_REQUESTED,
    COLLEGES_DATA_REQUESTED,
    YEARSADMITEDTOBAR_DATA_BY_SEARCH_REQUEST,
    COLLEGES_DATA_BY_SEARCH_REQUEST,
    PRACTICEAREA_DATA_BY_SEARCH_REQUEST,
    practiceAreaDataLazyLoading,
    collegesDataLazyLoading,
    yearsAdmittedToBarDataLazyLoading,
    collegesDataRequested,
    collegesDataLoaded,
    collegesCurrentMainDataLoaded,
    searchDataLoaded,
    searchCurrentMainResultsLoaded,
    practiceAreaDataLoaded,
    practiceAreaDataRequested,
    practiceAreaCurrentMainResultsLoaded,
    yearsAdmitedtoBarDataLoaded,
    yearsAdmitedtoBarCurrentMainResultsLoaded,
    yearsAdmitedtoBarDataRequested,
    collegesDataBySearchLoaded,
    yearsAdmitedtoBarDataBySearchLoaded,
    practiceAreaDataBySearchLoaded,
} from '../actions/searchActions';
import { spinnerChangedAction } from "../actions/spinnerActions";
import { getSearchResults } from '../../services/searchDataService';
import { getPracticeAreasByLawyerId, getPracticeAreasBySearch } from '../../services/practiceAreaDataService';
import { getCollegesByLawyerId, getCollegesBySearch } from '../../services/collegeDataService';
import {
    getYearsAdmitedtoBarResults,
    getYearsAdmittedtoBarResultsBySearch
} from '../../services/yearsAdmitedtoBarDataService';
import { logAndRedirectToErrorPage } from "../../components/ErrorComponent";
import { history } from "../../routerHistory";
import { AuthenticationError } from "../../common/AuthenticationError";
import { SEARCH_RESULT_OPTIONS } from 'pages/utils/common';
import { getCurrentMainResultsSortBy } from 'pages/utils/searchResultSort';

function* getPracticeAreaResultsLazyLoading(action) {
    try {
        const practiceAreaResults = yield call(getPracticeAreasBySearch, action.payload);
        yield put(practiceAreaDataBySearchLoaded(practiceAreaResults));
        yield put(practiceAreaCurrentMainResultsLoaded(practiceAreaResults));
    }
    catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }

}
function* getYearsAdmitedtoBarResultsLazyLoading(action) {
    try {        
        const yearAdmittedResults = yield call(getYearsAdmittedtoBarResultsBySearch, action.payload);
        yield put(yearsAdmitedtoBarDataBySearchLoaded(yearAdmittedResults));
        yield put(yearsAdmitedtoBarCurrentMainResultsLoaded(yearAdmittedResults));
    }
    catch (e) {        
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }

}
function* getCollegesLazyLoading(action) {
    try {
        const collegesResults = yield call(getCollegesBySearch, action.payload);
        yield put(collegesDataBySearchLoaded(collegesResults));
        yield put(collegesCurrentMainDataLoaded(collegesResults));
    }
    catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* getSearchRsults(action) {
    try {
        yield put(spinnerChangedAction(true));
        const searchResult = yield call(getSearchResults, action.payload);
        //concat lawyer ids and trigger practice areas search
        if (searchResult.results && searchResult.results.length > 0) {
            yield put(collegesDataLazyLoading(action.payload));
            yield put(yearsAdmittedToBarDataLazyLoading(action.payload));
            yield put(practiceAreaDataLazyLoading(action.payload));
            searchResult.results.forEach((resultItem) => {
                if (!resultItem.company) resultItem.company = '';
                if (!resultItem.subsidiary) resultItem.subsidiary = '';
                if (!resultItem.lName) resultItem.lName = '';
                if (!resultItem.city) resultItem.city = '';
                if (!resultItem.lookupStateProvince) resultItem.lookupStateProvince = '';        
            });
            searchResult.results = getCurrentMainResultsSortBy(searchResult.results, SEARCH_RESULT_OPTIONS.Company_Name_Value);
            yield put(searchDataLoaded(searchResult.results));
            yield put(searchCurrentMainResultsLoaded(searchResult.results));
        } else {
            //for No results
            yield put(searchDataLoaded(searchResult.results));
            yield put(searchCurrentMainResultsLoaded(searchResult.results));
            yield put(practiceAreaDataRequested(""));
            yield put(yearsAdmitedtoBarDataRequested(""));
            yield put(collegesDataRequested(""));
        }
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* getPracticeAreaRsults(action) {
    try {
        const results = yield call(getPracticeAreasByLawyerId, action.querystring);
        yield put(practiceAreaDataLoaded(results));
        yield put(practiceAreaCurrentMainResultsLoaded(results));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* getYearsAdmitedtoBarRsults(action) {
    try {
        const results = yield call(getYearsAdmitedtoBarResults, action.querystring);
        yield put(yearsAdmitedtoBarDataLoaded(results));
        yield put(yearsAdmitedtoBarCurrentMainResultsLoaded(results));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* getCollegesResults(action) {
    try {
        const results = yield call(getCollegesByLawyerId, action.payload);
        yield put(collegesDataLoaded(results));
        yield put(collegesCurrentMainDataLoaded(results));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* watchGetPracticeAreResults() {
    yield takeLatest(PRACTICEAREA_DATA_REQUESTED, getPracticeAreaRsults);
}
function* watchGetSearchResults() {
    yield takeLatest(SEARCH_DATA_REQUESTED, getSearchRsults);
}
function* watchGetYearsAdmitedtoBarResults() {
    yield takeLatest(YEARSADMITEDTOBAR_DATA_REQUESTED, getYearsAdmitedtoBarRsults);
}
function* watchGetCollegesResults() {
    yield takeLatest(COLLEGES_DATA_REQUESTED, getCollegesResults);
}
function* watchCollegesDataLazyLoading() {
    yield takeLatest(COLLEGES_DATA_BY_SEARCH_REQUEST, getCollegesLazyLoading);
}
function* watchYearsAdmittedToBarDataLazyLoading() {
    yield takeLatest(YEARSADMITEDTOBAR_DATA_BY_SEARCH_REQUEST, getYearsAdmitedtoBarResultsLazyLoading);
}
function* watchPracticeAreaDataLazyLoading() {
    yield takeLatest(PRACTICEAREA_DATA_BY_SEARCH_REQUEST, getPracticeAreaResultsLazyLoading);
}
export default [
    fork(watchGetSearchResults),
    fork(watchGetPracticeAreResults),
    fork(watchGetYearsAdmitedtoBarResults),
    fork(watchGetCollegesResults),
    fork(watchCollegesDataLazyLoading),
    fork(watchYearsAdmittedToBarDataLazyLoading),
    fork(watchPracticeAreaDataLazyLoading)
];