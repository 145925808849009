export const getLawyerCollegeCount = (currentCollegesResult, top, sorted = true) => {
    let colleges = currentCollegesResult.map(a => a.attbr);
    let occurrences = {};
    for (let i = 0, j = colleges.length; i < j; i++) {
        occurrences[colleges[i]] = (occurrences[colleges[i]] || 0) + 1;
    }
    let result = Object.entries(occurrences).map(([attbr, count]) => ({ college:attbr, count }));
    if (sorted) result.sort((a, b) => b.count - a.count);

    if (top > 0)
        return result.slice(0, top);
    else
        return result;
}