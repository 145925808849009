import React from "react";
import UserID from "components/layout/UserID";
import { Link } from "react-router-dom";
import { post } from "services/ApiService";
const defaultShortInfo = "Something went wrong";
const defaultLogInfo = "Sorry, an error has occured. Please try again.";
export const ErrorComponent = ({ errorMessage, resetErrorBoundary }) => {       
    const returnToHomeLink = resetErrorBoundary ? <Link className="wk-button" onClick={resetErrorBoundary} to="/">Return to Home</Link > : <Link  className="wk-button" to="/">Return to Home</Link>
    return <div className="wk-page-error">
        <div wk-icon="caution" wk-icon-type="filled" aria-hidden="true" className="wk-page-error-icon"></div>
        <h2 className="wk-page-error-heading">Hello <UserID /></h2>
        <p className="wk-page-error-message">{errorMessage||"Sorry, an error has occured. Please try again."}</p>
        {returnToHomeLink}
        <div className="wk-page-error-meta"><a href="https://support.cch.com/ticket/">Customer Support</a><br /><span className="wk-page-error-reference">If you need any technical or content support while using Corporate Counsel Profiler, contact customer support at 1-866-529-6600 (between 8 AM and 8 PM Central Time) or email LRUSCustomerCare@wolterskluwer.com.</span></div></div>;
};
export const logAndRedirectToErrorPage = (error, history) => {
    post('./api/logs', { shortInfo: defaultShortInfo, logInfo: error.message || defaultLogInfo })
        .then(data => {
            // using fallback for source that does not use hooks
            history ? history.push("/error") : window.location.href = "/error";           
        }).catch(e=>{history ? history.push("/error") : window.location.href = "/error";});
}
export const redirectToErrorPage = (history) => {    
    // using fallback for source that does not use hooks
    history ? history.push("/error") : window.location.href = "/error";  
}
export const logError = (error, info) => {
    post('./api/logs', { shortInfo: defaultShortInfo, logInfo: error.message || defaultLogInfo });    
}