import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import queryString from "querystring";
import { useDispatch, useSelector, useStore } from "react-redux";
import { resetSearchToInitialState, resetBarChartPage, resetAllSearchData, searchDataRequested, searchDataSortBy, setSearchPage} from "state/actions/searchActions";
import { SearchResultHeader } from './SearchResultHeader';
import { SearchResultDetail } from './SearchResultDetail';
import { scrollToTop } from '../../utils/scrollToTop';
import { reduceSelections } from '../utils/reduceSelections';
import CitiesResultsBI from "./CitiesResultsBI";
import StatesResultsBI from "./StatesResultsBI";
import YearAdmittedToBarSRPBI from "./YearsAdmitedtoBarSRPBI";
import SRPBIOverlay from "./SRPBIOverlay";
import PracticeAreasResultsBI from "./PracticeAreasResultsBI";
import CollegeBi from "./CollegesBI";
import { filterMainResultsByLawyerIdsAll } from "state/actions/searchActions";
import NoRecordFound from 'components/NoRecordFound';
import CreateParentAlertOverlay from "pages/Common/CreateParentAlertOverlay";
import { SEARCH_RESULT_OPTIONS } from 'pages/utils/common';
import { post } from 'services/ApiService';
import { onLocationChanged } from "state/actions/locationActions";

export const Search = ({ saveAlert }) => {

    const previousLocation = useSelector(state => state.location.previousLocation);
    const [isParentAlertVisible, setParentAlertVisible] = useState(false);
    const [maxWarningCompanies, setMaxWarningCompanies] = useState(false);
    const [selections, setSelections] = useState([]);
    const [parentCompanyAlertsPresent, setParentCompanyAlertsPresent] = useState([]);
    const location = useLocation();
    const { ...searchCriteria } = queryString.parse(location.search.substring(1));
    const dispatch = useDispatch();
    const [isPrinting, setIsPrinting] = useState(false);
    const [isPieChartVisible, setPieChartVisible] = useState(false);
    const [isHorizontalBarVisible, setHorizontalBarVisible] = useState(false);
    const [selectedBIIndex, setSelectedBIIndex] = useState(-1);
    const [sortBy, setSortBy] = useState(SEARCH_RESULT_OPTIONS.Company_Name_Value);
    const [currentResult, setCurrentResult] = useState([]);
    const result = useSelector(state => state.search.currentYearsAdmitedtoBarResults);
    const resultCity = useSelector(state => state.search.cityBiData);
    const resultStates = useSelector(state => state.search.statesBiData);
    const resultPA = useSelector(state => state.search.currentPracticeAreasResults);
    const resultColleges = useSelector(state => state.search.currentYearsCollegesResults);
    const store = useStore();
    const showParentAlertOverlay = (data, sorted, showWarning) => {
        setParentCompanyAlertsPresent([]);
        setMaxWarningCompanies(showWarning);
        setSelections(sorted)
        setParentCompanyAlertsPresent(data);
        setParentAlertVisible(true);
    };

    const closeParentAlertOverlay = (e) => {
        setParentAlertVisible(false);
        setMaxWarningCompanies(false)
    }

    const filterData = legendItem => {
        const mainCurrentResults = store.getState().search.currentMainResults;
        if (mainCurrentResults.length > 0) {
            let lawyerIds;
            switch (selectedBIIndex) {
                case 0:
                    lawyerIds = mainCurrentResults.filter(item => item.lookupStateProvince === legendItem.text).map(item => item.lawyerId);
                    break;
                case 1:
                    lawyerIds = mainCurrentResults.filter(item => item.city === legendItem.text).map(item => item.lawyerId);
                    break;
                case 2:
                    lawyerIds = result.filter(item => item.attbr === legendItem.text).map(item => parseInt(item.lid));
                    break;
                case 3:
                    lawyerIds = resultPA.filter(item => item.desc === legendItem.text).map(item => parseInt(item.lId));
                    break;
                case 4:
                    lawyerIds = resultColleges.filter(item => item.attbr === legendItem.text).map(item => item.lId);
                    break;
                default:
                    setCurrentResult([]);
            }
            dispatch(filterMainResultsByLawyerIdsAll(lawyerIds, sortBy));
        }
        setPieChartVisible(false);
        setHorizontalBarVisible(false);
        setSelectedBIIndex(-1);
    }

    const showSRPBIOverlay4Pie = (index) => {
        setPieChartVisible(true);
        setSelectedBIIndex(index);
        const mainCurrentResults = store.getState().search.currentMainResults;
        switch (index) {
            case 0:
                setCurrentResult(mainCurrentResults);
                break;
            case 1:
                setCurrentResult(mainCurrentResults);
                break;
            case 2:
                setCurrentResult(result);
                break;
            case 3:
                setCurrentResult(resultPA);
                break;
            case 4:
                setCurrentResult(resultColleges);
                break;
            default:
                setCurrentResult([]);
        }
        
    }

    const showSRPBIOverlay4Horizontal = (index) => {
        setHorizontalBarVisible(true);
        setSelectedBIIndex(index);
        const mainCurrentResults = store.getState().search.currentMainResults;
        switch (index) {
            case 0:
                setCurrentResult(mainCurrentResults);
                break;
            case 1:
                setCurrentResult(mainCurrentResults);
                break;
            case 2:
                setCurrentResult(result);
                break;
            case 3:
                setCurrentResult(resultPA);
                break;
            case 4:
                setCurrentResult(resultColleges);
                break;
            default:
                setCurrentResult([]);
        }
    }

    const closeDialog = (e) => {
        setPieChartVisible(false);
        setHorizontalBarVisible(false);
        dispatch(resetBarChartPage());
    }

    const print = async e => {
        await setIsPrinting(true);
        window.print();
        const mainCurrentResults = store.getState().search.currentMainResults;
        await post('./api/logs', { shortInfo: "Print", logInfo: mainCurrentResults.filter(item => item.checked).map(c=>c.lawyerId).toString() })
    }

    const onKeyPress = e => {
        if (e.ctrlKey && e.keyCode === 80 && !isPrinting) {
            e.preventDefault();
            print();
        }
    }

    const changeHandler = (val) => {
        dispatch(searchDataSortBy(val)); 
        setSortBy(val);
    }

    const clearHandler = () => {
        dispatch(resetAllSearchData(sortBy));
    };
    useEffect(() => {
        //dispatch search data requested action
        if(previousLocation === "/"){
            dispatch(searchDataRequested(searchCriteria));
        }
        //scroll to top of page
        scrollToTop();
        const printMq = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');
        const mqEvent = mqList => {
            if (!mqList.matches)
                setIsPrinting(mqList.matches)
        };
        dispatch(onLocationChanged("/search"));
        document.onkeydown = onKeyPress;
        printMq.addListener(mqEvent);
        if (navigator.userAgent.indexOf("Firefox") != -1)
        {
            window.onafterprint = () => {
                setIsPrinting(false)
            }
        }
        return () => {
            document.onkeydown = null;
            window.onafterprint = null;
            printMq.removeListener(mqEvent);
            //Commented because we don't need to reset state on component unmount.
            // dispatch(resetSearchToInitialState());
        }
    }, [])
    return (
        <div className="wk-row">
            <div className="wk-col-12">
                <div className="wk-col-4 sticky search-bi-section" id="searchBi">
                    <div className="wk-col-12 wk-search-bi-bottom">
                        <h4 style={{ textAlign: "center" }}>STATES</h4>
                        {
                            (resultStates && resultStates.length > 0) ?
                                <div>
                                    <StatesResultsBI/>
                                    <p className="wk-search-bi-showall"><a className="wk-link"
                                        onClick={() => showSRPBIOverlay4Pie(0)}>Show More - Pie Chart</a></p>
                                    <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                        onClick={() => showSRPBIOverlay4Horizontal(0)}>Show More - Bar Chart</a></p>
                                </div>
                                : <NoRecordFound/>
                        }
                 
                    </div>
                    <div className="wk-col-12 wk-search-bi-bottom">
                        <h4 style={{ textAlign: "center" }}>CITIES</h4>
                        {
                            (resultCity && resultCity.length > 0) ?
                                <div>
                                    <CitiesResultsBI/>
                                    <p className="wk-search-bi-showall"><a className="wk-link"
                                        onClick={() => showSRPBIOverlay4Pie(1)}>Show More - Pie Chart</a></p>
                                    <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                        onClick={() => showSRPBIOverlay4Horizontal(1)}>Show More - Bar Chart</a></p>
                                </div>
                                : <NoRecordFound/>
                        }
                    </div>
                    <div className="wk-col-12 wk-search-bi-bottom">
                        <h4 style={{ textAlign: "center" }}>YEARS ADMITTED TO BAR</h4>
                        {
                                <div>
                                    <YearAdmittedToBarSRPBI/>
                                    {result && result.length > 0 && <>
                                        <p className="wk-search-bi-showall">
                                            <a className="wk-link" onClick={() => showSRPBIOverlay4Pie(2)}>Show More - Pie Chart</a>
                                        </p>
                                        <p className="wk-search-bi-showall-hb">
                                            <a className="wk-link" onClick={() => showSRPBIOverlay4Horizontal(2)}>Show More - Bar Chart</a>
                                        </p>
                                    </>}
                                </div>
                        }
                    </div>
                    <div className="wk-col-12 wk-search-bi-bottom">
                        <h4 style={{ textAlign: "center" }}>PRACTICE AREAS</h4>
                        {
                                <div>
                                    <PracticeAreasResultsBI/>
                                    {resultPA && resultPA.length > 0 && <>
                                        <p className="wk-search-bi-showall">
                                            <a className="wk-link" onClick={() => showSRPBIOverlay4Pie(3)}>Show More - Pie Chart</a>
                                        </p>
                                        <p className="wk-search-bi-showall-hb">
                                            <a className="wk-link" onClick={() => showSRPBIOverlay4Horizontal(3)}>Show More - Bar Chart</a>
                                        </p>

                                    </>}
                                </div>
                        }
                    </div>
                    <div className="wk-col-12 wk-search-bi-bottom">
                        <h4 style={{ textAlign: "center" }}>COLLEGES</h4>
                        <div>
                            <CollegeBi/>
                            {resultColleges && resultColleges.length > 0 && <>
                                    <p className="wk-search-bi-showall">
                                    <a className="wk-link" onClick={() => showSRPBIOverlay4Pie(4)}>
                                        Show More - Pie Chart
                                    </a>
                                </p>
                                    <p className="wk-search-bi-showall-hb">
                                        <a className="wk-link" onClick={() => showSRPBIOverlay4Horizontal(4)}>
                                            Show More - Bar Chart
                                        </a>
                                    </p>
                                </>
                            }
                        </div>

                    </div>
                </div>
                <div className="wk-col-8" id="searchResults">
                    <div className="wk-col-12">
                        <div className="wk-col-12 search-header sticky">
                            {!isPrinting && <SearchResultHeader print={print} showParentAlertOverlay={showParentAlertOverlay} changeHandler={changeHandler} clearHandler={clearHandler} />}
                        </div>
                        <div className="wk-col-12">
                            <SearchResultDetail pageSize={100} isPrinting={isPrinting} sortBy={sortBy}/>
                        </div>
                    </div>
                </div>
            </div>
            
            {isPieChartVisible && <SRPBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isPieChartVisible} closeDialog={closeDialog} filterData={filterData} chartType="PieChart" />}
            {isHorizontalBarVisible && <SRPBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isHorizontalBarVisible} closeDialog={closeDialog} filterData={filterData} chartType="HorizontalBarChart" />}
            {isParentAlertVisible && <CreateParentAlertOverlay saveAlert={saveAlert} isVisible={isParentAlertVisible} closeOverlay={closeParentAlertOverlay} selections={selections} parentCompanyAlertsPresent={parentCompanyAlertsPresent} maxWarningCompanies={maxWarningCompanies} />}
        </div>
    );
};


