import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from "react-redux"
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import store from "./state/store";
import Spinner from "./components/Spinner";
import { history } from "./routerHistory";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const getCookie = (name) => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};
let cookie = getCookie(".CCP.Security.Cookie");
if (!cookie) {
    window.location.href = window.location.origin + "/Account/Login?returnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
} else {
    ReactDOM.render(
        <Provider store={store}>
            <Router history={history}>
                <App/>
                <Spinner/>
            </Router>
        </Provider>,
        rootElement);
}

serviceWorker.unregister();

