import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getLawyerCount } from 'pages/utils/lawyerYATBCount';
import { filterMainResultsByLawyerIds } from "state/actions/searchActions";
import color from "../../utils/color";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";
import NoRecordFound from "../../components/NoRecordFound";


const YearAdmittedToBarSRPBI = ({ isOverlay = false }) => {
    const [stats, setStats] = useState([]);
    const currentYearsAdmittedBarResults = useSelector(state => state.search.currentYearsAdmitedtoBarResults);
    const store = useStore();
    const dispatch = useDispatch();
    const loadingSelector = createLoadingSelector(['YEARSADMITEDTOBAR_DATA_BY_SEARCH']);
    const loading = useSelector(state => loadingSelector(state));
    useEffect(() => {
        if (currentYearsAdmittedBarResults) {
            let top = 5;
            if(isOverlay)
                top = 0;
            let resultTop = getLawyerCount(currentYearsAdmittedBarResults, top, true);
            setStats(resultTop);
        }
    }, [currentYearsAdmittedBarResults]);
    const filterByLawyerIds = legendItem => {
        const result = store.getState().search.currentYearsAdmitedtoBarResults;
        if (result) {
            const lawyerIds = result.filter(item => item.attbr === legendItem.text).map(item => parseInt(item.lid));
            dispatch(filterMainResultsByLawyerIds(lawyerIds));
        }
    }
    return (
        <div id="psfColleges">
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && stats.length > 0 && <Piechart
                label="States"
                data={stats.map(item => item.relevance)}
                labels={stats.map(item => item.year)}
                ref={registerTestObject(BI.PSF_YEARS_ADMITTED_TO_BAR_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]}/>
            }
            {!loading && !stats.length && <NoRecordFound/>}
        </div>
    );
};
export default YearAdmittedToBarSRPBI;
