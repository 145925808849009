import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";

const getCookie = (name) => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

export function ProtectedRoute({ children, ...rest }) {
    let cookie = getCookie(".CCP.Security.Cookie");
    return (
        cookie ? <Route {...rest}>{children}</Route> : <Redirect
            to={{
                pathname: "/login",
            }}
        />
    );
}