import {get,post} from "./ApiService";
import querystring from "query-string";

export const getCompanyData = (ids) => {
    let url = 'api/readchecked/companies/' + ids ;
    return get(url).then(response =>
        response
    );
};
export const getLawyerData = (ids) => {
    let url = 'api/readchecked/lawyers/' + ids;
    return get(url).then(response =>
        response
    );
};
export const postLawyerData = async (ids) => {
    let url = 'api/readchecked';
    return await post(url, { lawyerIds: ids });
};
export const getCollegesByLawyerId = async lawyerIds => {
    let url = 'api/colleges/byLawyerId';
    return await post(url, { lawyerIds });
};
export const getPAsByLawyerId = async lawyerIds => {
    let url = 'api/practicearea/byLawyerId';
    return await post(url, { lawyerIds });
};
export const getTitlesByLawyerId = async lawyerIds => {
    let url = 'api/Company/lawyersTitles';
    return await post(url, { lawyerIds });
};