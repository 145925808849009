import {
    COMPANY_DATA_LOADED,
    COMPANYLAWYERS_DATA_LOADED,
    PARENTCOMPANYLAWYERS_DATA_LOADED,
    FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS,
    LAWYER_DATA_LOADED,
    UPDATE_COMPANYLAWYERS_DATA,
    COMPANY_TITLE_DATA_LOADED,
    COMPANY_PA_DATA_LOADED,
    COMPANY_COLLEGE_DATA_LOADED,
    PARENTCOMPANY_DATA_LOADED,
    RESET_ALL_READ_DATA,
    RBSFILINGS_DATA_LOADED, READ_RESET_INITIAL_STATE
} from "state/actions/readActions";

const initialState = {
    isToolbarActive: false,
    companyResult: [],
    allCompanyLawyers: [],
    lawyerIds: [],
    currentCompanyLawyers: [],
    allCollegesResults: [],
    currentCollegesResults: [],
    allPaResults: [],
    currentPaResults: [],
    allTitlesResults: [],
    currentTitlesResults: [],
    rbsfilingsInfo: null
}
export default function (state = initialState, action) {
    switch (action.type) {
        case COMPANY_DATA_LOADED:
            return {...state, companyResult: action.payload};
        case PARENTCOMPANY_DATA_LOADED:
            return {...state, parentCompanyResult: action.payload};
        case COMPANYLAWYERS_DATA_LOADED:
            return {...state, allCompanyLawyers: action.payload};
        case PARENTCOMPANYLAWYERS_DATA_LOADED:
            return {...state, allCompanyLawyers: action.payload};
        case LAWYER_DATA_LOADED:
            return {...state, allCompanyLawyers: action.payload};
        case FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS:
            const filteredCompanyLawyers = state.allCompanyLawyers?.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) > -1);
            const filteredColleges = state.currentCollegesResults?.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) !== -1);
            const filteredPracticeAreas = state.currentPaResults?.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) !== -1);
            const filteredTitles = state.currentTitlesResults?.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) !== -1);
            return {
                ...state,
                lawyerIds: action.lawyerIds,
                currentCompanyLawyers: filteredCompanyLawyers,
                currentCollegesResults: filteredColleges,
                currentPaResults: filteredPracticeAreas,
                currentTitlesResults: filteredTitles
            };
        case RESET_ALL_READ_DATA:
            return {
                ...state,
                lawyerIds: state.allCompanyLawyers.map(item => item.lId),
                currentCompanyLawyers: state.allCompanyLawyers,
                currentCollegesResults: state.allCollegesResults,
                currentPaResults: state.allPaResults,
                currentTitlesResults: state.allTitlesResults,
            };
        case UPDATE_COMPANYLAWYERS_DATA:
            return {
                ...state,
                currentCompanyLawyers: action.payload
            };
        case COMPANY_COLLEGE_DATA_LOADED:
            return {
                ...state,
                currentCollegesResults: action.payload,
                allCollegesResults: action.payload
            }
        case COMPANY_PA_DATA_LOADED:
            return {
                ...state,
                currentPaResults: action.payload,
                allPaResults: action.payload
            }
        case COMPANY_TITLE_DATA_LOADED:
            return {
                ...state,
                currentTitlesResults: action.payload,
                allTitlesResults: action.payload
            }
        case RBSFILINGS_DATA_LOADED:
            return {
                ...state,
                rbsfilingsInfo: action.payload
            }
        case READ_RESET_INITIAL_STATE:
            return {
                ...initialState
            }
        default:
            return state;
    }
}
