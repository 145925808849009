import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import queryString from "querystring";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ReadCheckedHeader } from "pages/ReadChecked/ReadCheckedHeader";
import { scrollToTop } from 'utils/scrollToTop';
import {
    readcheckedLawyerDataRequested, 
    resetReadCheckedToInitialState, 
    readcheckedfilterAllCompanyResultsByLawyerIds
} from 'state/actions/readCheckedActions';
import {
    RBSFilingsDataRequested
} from 'state/actions/readActions';

import { ReadCheckedResult } from "pages/ReadChecked/ReadCheckedResult";
import ReadCheckedCollegeBi from "./ReadCheckedCollegesBi";
import ViewCheckedBIOverlay from "./ViewCheckedBIOverlay";
import ReadCheckedPracticeAreaBi from "./ReadCheckedPracticeAreaBi";
import ReadCheckedTitlesBi from "./ReadCheckedTitlesBi";
import NoRecordFound from "../../components/NoRecordFound";
import CreateParentAlertOverlay from "pages/Common/CreateParentAlertOverlay";
import { post } from 'services/ApiService';
import { onLocationChanged } from "state/actions/locationActions";

export const ReadChecked = ({ saveAlert }) => {
    const location = useLocation();
    const { lids } = queryString.parse(location.search.substring(1));
    const [isToolbarActive, setIsToolbarActive] = useState(false);
    const [isPrinting, setIsPrinting] = useState(false);
    const readSelectedcurrentCollegesResults = useSelector(state => state.readchecked.readSelectedcurrentCollegesResults);
    const readSelectedcurrentPAResults = useSelector(state => state.readchecked.readSelectedcurrentPAResults);
    const readSelectedcurrentTitlesResults = useSelector(state => state.readchecked.readSelectedcurrentTitlesResults);
    const [selectedBIIndex, setSelectedBIIndex] = useState(-1);
    const [currentResult, setCurrentResult] = useState([]);
    const companyLawyers = useSelector(state => state.readchecked.currentLawyers);
    const [selections, setSelections] = useState([]);
    const [isPieChartVisible, setPieChartVisible] = useState(false);
    const [isHorizontalBarVisible, setHorizontalBarVisible] = useState(false);
    const [isParentAlertVisible, setParentAlertVisible] = useState(false);
    const store = useStore();
    const [parentCompanyAlertsPresent, setParentCompanyAlertsPresent] = useState([]);

    const dispatch = useDispatch();
    useEffect(() => {
        if (lids) {
            dispatch(readcheckedLawyerDataRequested(lids));
        }
        dispatch(RBSFilingsDataRequested());
        dispatch(onLocationChanged("/readchecked"));
        //scroll to top of page
        scrollToTop();
        const printMq = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');
        const mqEvent = mqList => {
            if (!mqList.matches)
                setIsPrinting(mqList.matches)
        };
        document.onkeydown = onKeyPress;
        printMq.addListener(mqEvent);
        if (navigator.userAgent.indexOf("Firefox") != -1) {
            window.onafterprint = () => {
                setIsPrinting(false)
            }
        }
        return () => {
            document.onkeydown = null;
            window.onafterprint = null;
            printMq.removeListener(mqEvent);
            dispatch(resetReadCheckedToInitialState());
        }
    }, [])
    const toggleToolbar = (isActive) => {
        setIsToolbarActive(isActive);
    }
    const print = async e => {
        await setIsPrinting(true);
        window.print();
        await post('./api/logs', { shortInfo: "Print", logInfo: companyLawyers.filter(item => item.checked).map(c=>c.lId).toString() })
    }
    const onKeyPress = e => {
        if (e.ctrlKey && e.keyCode === 80 && !isPrinting) {
            e.preventDefault();
            print();
        }
    }

    const closeDialog = (e) => {
        setPieChartVisible(false);
        setHorizontalBarVisible(false);
    }

    const filterData = legendItem => {
        let lawyerIds;
        switch (selectedBIIndex) {
            case 0:
                lawyerIds = readSelectedcurrentCollegesResults.filter(item => item.attbr === legendItem.text).map(item => item.lId);
                break;
            case 1:
                lawyerIds = readSelectedcurrentPAResults.filter(item => item.desc === legendItem.text).map(item => item.lId);
                break;
            case 2:
                lawyerIds = readSelectedcurrentTitlesResults.filter(item => item.title === legendItem.text).map(item => item.lawyerId);
                break;
            default:
                setCurrentResult([]);
        }
        dispatch(readcheckedfilterAllCompanyResultsByLawyerIds(lawyerIds));

        setPieChartVisible(false);
        setHorizontalBarVisible(false);
        setSelectedBIIndex(-1);
    }

    const showViewCheckedBIOverlay4Pie = (index) => {
        setPieChartVisible(true);
        setSelectedBIIndex(index);

        switch (index) {
            case 0:
                setCurrentResult(readSelectedcurrentCollegesResults);
                break;
            case 1:
                setCurrentResult(readSelectedcurrentPAResults);
                break;
            case 2:
                setCurrentResult(readSelectedcurrentTitlesResults);
                break;
            default:
                setCurrentResult([]);
        }

    }

    const showViewCheckedBIOverlay4Horizontal = (index) => {
        setHorizontalBarVisible(true);
        setSelectedBIIndex(index);

        switch (index) {
            case 0:
                setCurrentResult(readSelectedcurrentCollegesResults);
                break;
            case 1:
                setCurrentResult(readSelectedcurrentPAResults);
                break;
            case 2:
                setCurrentResult(readSelectedcurrentTitlesResults);
                break;
            default:
                setCurrentResult([]);
        }
    }

    const showParentAlertOverlay = (data, parentSelections) => {
        setParentCompanyAlertsPresent([]);
        setSelections(parentSelections)
        setParentCompanyAlertsPresent(data);
        setParentAlertVisible(true);
    };

    const closeParentAlertOverlay = (e) => {
        setParentAlertVisible(false);
    }

    return (
        <>
            <div className="wk-row">
                <div className="wk-col-6">
                </div>
                <div className="wk-col-6">
                    <ReadCheckedHeader isToolbarActive={isToolbarActive} print={print} showParentAlertOverlay={showParentAlertOverlay} />
                </div>
            </div>
            <div id="ReadWell" className="wk-row">
                {!isPrinting &&
                    <div className="wk-row">
                        <div className="wk-col-12" style={{ paddingBottom: "2em" }}>
                            <h5 style={{ textAlign: 'center' }}>Corporate Counsel</h5>
                            <div id="ReadBISection" className="wk-row">
                                <div className="wk-col-4">
                                <h6 align="center">Colleges</h6>
                                    {readSelectedcurrentCollegesResults?.length > 0 && (
                                        <>
                                            <ReadCheckedCollegeBi
                                                currentCollegesResults={readSelectedcurrentCollegesResults} chartType="Pie" />
                                            <p className="wk-search-bi-showall"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Pie(0)}>Show More - Pie Chart</a></p>
                                            <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Horizontal(0)}>Show More - Bar Chart</a></p>

                                        </>)}
                                    {readSelectedcurrentCollegesResults?.length === 0 && <NoRecordFound />}
                                </div>
                                <div className="wk-col-4">
                                <h6 align="center">Practice Areas</h6>
                                    {readSelectedcurrentPAResults?.length > 0 && (
                                        <>
                                            <ReadCheckedPracticeAreaBi currentPaResults={readSelectedcurrentPAResults} chartType="Pie" />

                                            <p className="wk-search-bi-showall"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Pie(1)}>Show More - Pie Chart</a></p>
                                            <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Horizontal(1)}>Show More - Bar Chart</a></p>

                                        </>)}
                                    {readSelectedcurrentPAResults?.length === 0 && <NoRecordFound />}
                                </div>
                                <div className="wk-col-4">
                                <h6 align="center">Titles</h6>
                                    {readSelectedcurrentTitlesResults?.length > 0 && (
                                        <>
                                            <ReadCheckedTitlesBi currentTitlesResults={readSelectedcurrentTitlesResults} chartType="Pie" />

                                            <p className="wk-search-bi-showall"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Pie(2)}>Show More - Pie Chart</a></p>
                                            <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                                onClick={() => showViewCheckedBIOverlay4Horizontal(2)}>Show More - Bar Chart</a></p>

                                        </>)}
                                    {readSelectedcurrentTitlesResults?.length === 0 && <NoRecordFound />}
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="wk-row">
                    <div className="wk-col-12">
                        <ReadCheckedResult toggleToolbar={toggleToolbar} isPrinting={isPrinting} companyLawyers={companyLawyers} />
                    </div>
                </div>

                {isPieChartVisible && <ViewCheckedBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isPieChartVisible} closeDialog={closeDialog} filterData={filterData} chartType="PieChart" />}
                {isHorizontalBarVisible && <ViewCheckedBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isHorizontalBarVisible} closeDialog={closeDialog} filterData={filterData} chartType="HorizontalBarChart" />}
                {isParentAlertVisible && <CreateParentAlertOverlay saveAlert={saveAlert} isVisible={isParentAlertVisible} closeOverlay={closeParentAlertOverlay} selections={selections} parentCompanyAlertsPresent={parentCompanyAlertsPresent} maxWarningCompanies={false} />}
            </div>
        </>
    );
};


