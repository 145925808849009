import React, {Component} from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";

class PieChartComponent extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        this.chart = new Chart(myChartRef, {
            type: "pie",
            data: {
                //Bring in data
                labels: this.props.labels,
                datasets: [
                    {
                        label: this.props.label,
                        data: this.props.data,
                        backgroundColor: this.props.backgroundColors,
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                hover: {
                    onHover: function(e) {
                        let point = this.getElementAtEvent(e);
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                }
            },
        });
    };
    chartClick = (evt) => {
        let activePoints = this.chart.getElementsAtEvent(evt);
        if (activePoints[0]) {
            let chartData = activePoints[0]['_chart'].config.data;
            let idx = activePoints[0]['_index'];
            let label = chartData.labels[idx];
            let value = chartData.datasets[0].data[idx];
            this.props.legendCallBack(evt, {text: label})
        }
    };

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.chart.data.labels = this.props.labels;
        this.chart.data.datasets = [
            {
                label: this.props.label,
                data: this.props.data,
                backgroundColor: this.props.backgroundColors,
            },
        ];
        this.chart.update();
    }

    render() {
        return (
            <>
                <canvas ref={this.chartRef} onClick={this.chartClick}/>
                <div className="legend">
                    {this.props.labels.map((item, i) => (
                        <div
                            className="legend-item"
                            key={item + i}
                            onClick={(evt) => this.props.legendCallBack(evt, {text: item})}
                        >
                            <div className="color"
                                 style={{backgroundColor: this.props.backgroundColors[i]}}/>
                            <span>{item}</span>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

PieChartComponent.propTypes = {
    label: PropTypes.string.isRequired,
    labels: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    backgroundColors: PropTypes.array.isRequired,
}

export const Piechart = PieChartComponent;
