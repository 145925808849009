import { SEARCH_RESULT_OPTIONS } from 'pages/utils/common';
export const getCurrentMainResultsSortBy = (currentMainResults, val) => {
    currentMainResults.sort((a, b) => {
        switch (val) {
            case SEARCH_RESULT_OPTIONS.Company_Name_Value:
                if (a.company.toLowerCase() < b.company.toLowerCase()) { return -1; }
                if (a.company.toLowerCase() > b.company.toLowerCase()) { return 1; }
                return 0;
            case SEARCH_RESULT_OPTIONS.Subsidiary_Name_Value:
                if (a.subsidiary.toLowerCase() < b.subsidiary.toLowerCase()) { return -1; }
                if (a.subsidiary.toLowerCase() > b.subsidiary.toLowerCase()) { return 1; }
                return 0;
            case SEARCH_RESULT_OPTIONS.Attorneys_last_name_Value:
                if (a.lName.toLowerCase() < b.lName.toLowerCase()) { return -1; }
                if (a.lName.toLowerCase() > b.lName.toLowerCase()) { return 1; }
                return 0;
            case SEARCH_RESULT_OPTIONS.Company_City_Value:
                if (a.city.toLowerCase() < b.city.toLowerCase()) { return -1; }
                if (a.city.toLowerCase() > b.city.toLowerCase()) { return 1; }
                return 0;
            case SEARCH_RESULT_OPTIONS.Company_State_Value:
                if (a.lookupStateProvince.toLowerCase() < b.lookupStateProvince.toLowerCase()) { return -1; }
                if (a.lookupStateProvince.toLowerCase() > b.lookupStateProvince.toLowerCase()) { return 1; }
                return 0;
            default:
        }
    });

    return currentMainResults;
}