import { call, put, fork, takeLatest, all } from "redux-saga/effects";
import {
    COMPANY_DATA_REQUESTED,
    COMPANYLAWYERS_DATA_REQUESTED,
    COMPANY_COLLEGE_DATA_REQUESTED,
    COMPANY_TITLE_DATA_REQUESTED,
    COMPANY_PA_DATA_REQUESTED,
    LAWYER_DATA_REQUESTED,
    PARENTCOMPANY_DATA_REQUESTED,
    PARENTCOMPANYLAWYERS_DATA_REQUESTED,
    RBSFILINGS_DATA_REQUESTED,
    parentCompanyDataLoaded,
    companyDataLoaded,
    companyLawyersDataLoaded,
    parentCompanyLawyersDataLoaded,
    filterAllCompanyResultsByLawyerIds,
    lawyerDataLoaded,
    companyCollegeDataLoaded,
    companyPaDataLoaded,
    companyTitleDataLoaded,
    companyCollegeDataRequested,
    companyTitleDataRequested,
    companyPaDataRequested,
    RBSFilingsDataLoaded,
    RBSFilingsDataRequested
} from 'state/actions/readActions';
import {
    getCompanyData,
    getParentCompanyData,
    getCompanyLawyersData,
    getLawyerData,
    getCompanyColleges,
    getCompanyPa,
    getCompanyTitles,
    getParentCompanyLawyersData
} from 'services/readDataService';
import { getRBSFilingsInfo } from 'services/RBSFilingsService';
import { spinnerChangedAction } from "../actions/spinnerActions";
import { history } from "routerHistory"
import { logAndRedirectToErrorPage } from "../../components/ErrorComponent";
import { AuthenticationError } from "../../common/AuthenticationError";

function* getCompanyResult(action) {
    try {
        const searchResult = yield call(getCompanyData, action.payload);
        yield put(companyDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getParentCompanyResult(action) {
    try {
        const searchResult = yield call(getParentCompanyData, action.payload);
        yield put(parentCompanyDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getCompanyLawyersResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const searchResult = yield call(getCompanyLawyersData, action.payload);
        yield put(companyLawyersDataLoaded(searchResult));
        yield all([
            put(companyCollegeDataRequested(null, action.payload, null)),
            put(companyTitleDataRequested(null, action.payload, null)),
            put(companyPaDataRequested(null, action.payload, null))
        ]);
        //get lawyer Ids and filter all results based on lawyerids
        const lawyerIds = searchResult.map(item => item.lId);
        yield put(filterAllCompanyResultsByLawyerIds(lawyerIds));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }

}

function* getParentCompanyLawyersResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const searchResult = yield call(getParentCompanyLawyersData, action.payload);
        yield put(parentCompanyLawyersDataLoaded(searchResult));
        yield all([
            put(companyCollegeDataRequested(action.payload, null)),
            put(companyTitleDataRequested(action.payload, null)),
            put(companyPaDataRequested(action.payload, null))
        ]);
        //get lawyer Ids and filter all results based on lawyerids
        const lawyerIds = searchResult.map(item => item.lId);
        yield put(filterAllCompanyResultsByLawyerIds(lawyerIds));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getLawyerResult(action) {
    try {
        const searchResult = yield call(getLawyerData, action.payload.lawyerId);
        yield put(lawyerDataLoaded(new Array(searchResult)));
        let lawyerIds = [];
        //get lawyer Ids and filter all results based on lawyerids
        if (searchResult) {
            lawyerIds.push(searchResult.lId)
        }
        yield all([
            put(companyCollegeDataRequested(null, action.payload.companyId, action.payload.lawyerId)),
            put(companyTitleDataRequested(null, action.payload.companyId, action.payload.lawyerId)),
            put(companyPaDataRequested(null, action.payload.companyId, action.payload.lawyerId))
        ])
        yield put(filterAllCompanyResultsByLawyerIds(lawyerIds));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getCompanyCollegeResults(action) {
    try {
        const searchResult = yield call(getCompanyColleges, action.parentCompanyId, action.companyId, action.lawyerId);
        yield put(companyCollegeDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getCompanyPaResults(action) {
    try {
        const searchResult = yield call(getCompanyPa, action.parentCompanyId, action.companyId, action.lawyerId);
        yield put(companyPaDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }

}

function* getCompanyTitleResults(action) {
    try {
        const searchResult = yield call(getCompanyTitles, action.parentCompanyId, action.companyId, action.lawyerId);
        yield put(companyTitleDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getRBSFilingsResult(action) {
    try {
        const searchResult = yield call(getRBSFilingsInfo, action.payload);
        yield put(RBSFilingsDataLoaded(searchResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* watchGetParentCompanyResult() {
    yield takeLatest(PARENTCOMPANY_DATA_REQUESTED, getParentCompanyResult);
}

function* watchGetCompanyResult() {
    yield takeLatest(COMPANY_DATA_REQUESTED, getCompanyResult);
}

function* watchGetCompanyLawyersResult() {
    yield takeLatest(COMPANYLAWYERS_DATA_REQUESTED, getCompanyLawyersResult);
}

function* watchGetParentCompanyLawyersResult() {
    yield takeLatest(PARENTCOMPANYLAWYERS_DATA_REQUESTED, getParentCompanyLawyersResult);
}

function* watchGetLawyerResult() {
    yield takeLatest(LAWYER_DATA_REQUESTED, getLawyerResult);
}

function* watchCompanyColleges() {
    yield takeLatest(COMPANY_COLLEGE_DATA_REQUESTED, getCompanyCollegeResults);
}

function* watchCompanyPa() {
    yield takeLatest(COMPANY_PA_DATA_REQUESTED, getCompanyPaResults);
}

function* watchCompanyTitle() {
    yield takeLatest(COMPANY_TITLE_DATA_REQUESTED, getCompanyTitleResults);
}

function* watchRBSFilings() {
    yield takeLatest(RBSFILINGS_DATA_REQUESTED, getRBSFilingsResult);
}

export default [
    fork(watchGetCompanyResult),
    fork(watchGetParentCompanyResult),
    fork(watchGetParentCompanyLawyersResult),
    fork(watchGetCompanyLawyersResult),
    fork(watchGetLawyerResult),
    fork(watchCompanyColleges),
    fork(watchCompanyPa),
    fork(watchCompanyTitle),
    fork(watchRBSFilings),
];