export const getLawyerCount = (currentYearsAdmitedtoBarResults, top, sorted = true) => {

    let Years = currentYearsAdmitedtoBarResults.map(item => ({ attbr: item.attbr, relevance: item.relevance }));

    let result = [];
    Years.reduce(function (res, value) {
        if (!res[value.attbr]) {
            res[value.attbr] = { year: value.attbr, relevance: 0 };
            result.push(res[value.attbr])
        }
        res[value.attbr].relevance += value.relevance;
        return res;
    }, {});

    if (sorted) result.sort((a, b) => b.relevance - a.relevance);

    if (top > 0)
        return result.slice(0, top);
    else
        return result;
}
