import React from 'react';
import clsx from 'clsx';

const EmailWarningBlock = ({ warningMessage }) => {
    return (
        <div className="wk-field-item wk-field-has-error" style={{ marginBottom: 0 }}>
            <div data-testid="warning_msg" className="wk-field-error wk-font-weight-bold">{warningMessage}</div>
        </div>
    );
};
export default EmailWarningBlock;