import {
    PRODUCTS_LOADED,
    RSS_FEED_LOADED,
    STATE_MULTIPLE_SELECTED,
    STATE_RESET,
    STATE_SINGLE_SELECTED,
    HOME_DATA_COUNSEL_COUNT_LOADED,
    HOME_DATA_TOP_CITIES_LOADED,
    HOME_DATA_TOP_COLLEGES_LOADED,
    HOME_DATA_TOP_PRACTICEAREAS_LOADED,
    HOME_DATA_TOP_YEARADMITTEDTOBAR_LOADED,
    TOTAL_COUNSEL_COUNT_LOADED,
    TOTAL_COUNSEL_EMAIL_COUNT_LOADED, USER_INFO_LOADED
} from "../actions/homeActions";

const initialState = {
    selectedStates: [],
    topCities: [],
    topColleges: [],
    counselCountByState: [],
    topPracticeAreas: [],
    topYearAdmittedToBars: [],
    totalCounselCount: undefined,
    totalCounselEmailCount: 0,
    userInfo: {},
    rssFeed: [],
    products: [],
};
export default function (state = initialState, action) {
    switch (action.type) {
        case STATE_SINGLE_SELECTED:
            return { ...state, selectedStates: [action.usState] }
        case STATE_MULTIPLE_SELECTED:
            return { ...state, selectedStates: [...action.usStates] };
        case STATE_RESET:
            return { ...state, selectedStates: [] };
        case HOME_DATA_TOP_CITIES_LOADED:
            return { ...state, topCities: [...action.data] };
        case HOME_DATA_TOP_COLLEGES_LOADED:
            return { ...state, topColleges: [...action.data] };
        case HOME_DATA_COUNSEL_COUNT_LOADED:
            return { ...state, counselCountByState: [...action.data] };
        case HOME_DATA_TOP_PRACTICEAREAS_LOADED:
            return { ...state, topPracticeAreas: [...action.data] };
        case HOME_DATA_TOP_YEARADMITTEDTOBAR_LOADED:
            return { ...state, topYearAdmittedToBars: [...action.data] };
        case TOTAL_COUNSEL_EMAIL_COUNT_LOADED:
            return { ...state, totalCounselEmailCount: action.data };
        case TOTAL_COUNSEL_COUNT_LOADED:
            return { ...state, totalCounselCount: action.data };
        case USER_INFO_LOADED:
            return { ...state, userInfo: action.data };
        case RSS_FEED_LOADED:
            return { ...state, rssFeed: action.data };
        case PRODUCTS_LOADED:
            return { ...state, products: action.data };
        default:
            return state;
    }
}