import { all } from "redux-saga/effects";
import searchSaga from './searchSaga';
import readSaga from './readSaga';
import readCheckedSaga from './readCheckedSaga';
import homeSaga from "./homeSaga";
export default function* rootSaga() {
    yield all([
        ...homeSaga,
        ...searchSaga,
        ...readSaga,
        ...readCheckedSaga
    ]);
}
