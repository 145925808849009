import React from 'react';
import _ from 'lodash';
import { highlightField } from 'utils/highlightField';
import { isMetaProperty } from 'typescript';
import { ReadCheckedParentCompany } from "pages/ReadChecked/ReadCheckedParentCompany";
import { ReadCheckedCompany } from "pages/ReadChecked/ReadCheckedCompany";
export const ReadCheckedResultItem = ({ toggleToolbar, resultItem, isPrinting }) => {
    const lawyersList = (companyResult, isPrinting) => {
        if (!isPrinting) return companyResult.lawyers;
        return companyResult.lawyers.filter(l => l.checked == true);

    }

    const companyInfoShown = (companyResult, isPrinting) => {

        return lawyersList(companyResult, isPrinting).length > 0;
    }
    return (
        <>
            {resultItem?.parentInfo?.lawyers?.length > 0 ? companyInfoShown(resultItem?.parentInfo, isPrinting) && <ReadCheckedParentCompany companyResult={resultItem?.parentInfo} toggleToolbar={toggleToolbar} isPrinting={isPrinting} /> : <></>}
            {
                resultItem?.companies?.map((company, index) => (companyInfoShown(company, isPrinting) && <ReadCheckedCompany key={"company-" + company.companyInfo.cId} companyResult={company} toggleToolbar={toggleToolbar} isPrinting={isPrinting} />))
            }
        </>
    );
};