export const PRACTICEAREA_DATA_REQUESTED = "PRACTICEAREA_DATA_REQUESTED";
export const PRACTICEAREA_DATA_LOADED = "PRACTICEAREA_DATA_LOADED";
export const PRACTICEAREA_CURRENT_MAIN_RESULTS_LOADED = "PRACTICEAREA_CURRENT_MAIN_RESULTS_LOADED";
export const SEARCH_DATA_REQUESTED = "SEARCH_DATA_REQUESTED";
export const SEARCH_DATA_LOADING = "SEARCH_DATA_LOADING";
export const SEARCH_DATA_LOADED = "SEARCH_DATA_LOADED";
export const SEARCH_CURRENT_MAIN_RESULTS_LOADED = "SEARCH_CURRENT_MAIN_RESULTS_LOADED";
export const YEARSADMITEDTOBAR_DATA_REQUESTED = "YEARSADMITEDTOBAR_DATA_REQUESTED";
export const YEARSADMITEDTOBAR_DATA_LOADED = "YEARSADMITEDTOBAR_DATA_LOADED";
export const YEARSADMITEDTOBAR_CURRENT_MAIN_RESULTS_LOADED = "YEARSADMITEDTOBAR_CURRENT_MAIN_RESULTS_LOADED";
export const COLLEGES_DATA_REQUESTED = "COLLEGES_DATA_REQUESTED";
export const COLLEGES_DATA_LOADED = "COLLEGES_DATA_LOADED";
export const COLLEGES_CURRENT_MAIN_RESULTS_LOADED = "COLLEGES_CURRENT_MAIN_RESULTS_LOADED";
export const FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS = "FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS";
export const FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS_ALL = "FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS_ALL";
export const RESET_ALL_SEARCH_DATA = "RESET_ALL_SEARCH_DATA";
export const FILTER_BY_MAIN_RESULTS_BY_YEAR = "FILTER_BY_MAIN_RESULTS_BY_YEAR";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const SELECT_SEARCH_ITEM = "SELECT_SEARCH_ITEM";
export const RESET_SELECT_SEARCH_ITEM = "RESET_SELECT_SEARCH_ITEM";
export const SET_SELECT_ALL = "SET_SELECT_ALL";
export const SEARCH_RESET_INITIAL_STATE = "SEARCH_RESET_INITIAL_STATE";
export const SET_BARCHART_PAGE = "SET_BARCHART_PAGE";
export const RESET_BARCHART_PAGE = "RESET_BARCHART_PAGE";
export const SEARCH_DATA_SORTBY = "SEARCH_DATA_SORTBY";
export const YEARSADMITEDTOBAR_DATA_BY_SEARCH_REQUEST = "YEARSADMITEDTOBAR_DATA_BY_SEARCH_REQUEST";
export const COLLEGES_DATA_BY_SEARCH_REQUEST = "COLLEGES_DATA_BY_SEARCH_REQUEST";
export const PRACTICEAREA_DATA_BY_SEARCH_REQUEST = "PRACTICEAREA_DATA_BY_SEARCH_REQUEST";

export const YEARSADMITEDTOBAR_DATA_BY_SEARCH_LOADED = "YEARSADMITEDTOBAR_DATA_BY_SEARCH_LOADED";
export const COLLEGES_DATA_BY_SEARCH_REQUEST_LOADED = "COLLEGES_DATA_BY_SEARCH_LOADED";
export const PRACTICEAREA_DATA_BY_SEARCH_LOADED = "PRACTICEAREA_DATA_BY_SEARCH_LOADED";

export const practiceAreaDataLazyLoading = payload => ({
    type: PRACTICEAREA_DATA_BY_SEARCH_REQUEST,
    payload
});
export const collegesDataLazyLoading = payload => ({
    type: COLLEGES_DATA_BY_SEARCH_REQUEST,
    payload
});
export const yearsAdmittedToBarDataLazyLoading = payload => ({
    type: YEARSADMITEDTOBAR_DATA_BY_SEARCH_REQUEST,
    payload
});
export const setBarChartPage = (payload) => ({
    type: SET_BARCHART_PAGE,
    payload
});
export const resetBarChartPage = (payload) => ({
    type: RESET_BARCHART_PAGE,
    payload
});
export const setSearchPage = (payload) => ({
    type: SET_SEARCH_PAGE,
    payload
});
export const resetAllSearchData = (sortBy) => ({
    type: RESET_ALL_SEARCH_DATA,
    sortBy
});
export const filterMainResultsByLawyerIds = lawyerIds => ({
    type: FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS,
    lawyerIds
})
export const filterMainResultsByLawyerIdsAll = (lawyerIds, sortBy) => ({
    type: FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS_ALL,
    lawyerIds,
    sortBy
})
export const filterMainResultsByYear = year => ({
    type: FILTER_BY_MAIN_RESULTS_BY_YEAR,
    year
})
export const searchCurrentMainResultsLoaded = payload => ({
    type: SEARCH_CURRENT_MAIN_RESULTS_LOADED,
    payload
});
export const searchDataLoaded = payload => ({
    type: SEARCH_DATA_LOADED,
    payload
});
export const searchDataRequested = (payload) => ({
    type: SEARCH_DATA_REQUESTED,
    payload
});
export const practiceAreaDataRequested = (querystring) => ({
    type: PRACTICEAREA_DATA_REQUESTED,
    querystring
});
export const practiceAreaDataLoaded = payload => ({
    type: PRACTICEAREA_DATA_LOADED,
    payload
});
export const practiceAreaDataBySearchLoaded = payload => ({
    type: PRACTICEAREA_DATA_BY_SEARCH_LOADED,
    payload
});
export const practiceAreaCurrentMainResultsLoaded = payload => ({
    type: PRACTICEAREA_CURRENT_MAIN_RESULTS_LOADED,
    payload
});
export const yearsAdmitedtoBarDataRequested = (querystring) => ({
    type: YEARSADMITEDTOBAR_DATA_REQUESTED,
    querystring
});
export const yearsAdmitedtoBarDataLoaded = payload => ({
    type: YEARSADMITEDTOBAR_DATA_LOADED,
    payload
});
export const yearsAdmitedtoBarDataBySearchLoaded = payload => ({
    type: YEARSADMITEDTOBAR_DATA_BY_SEARCH_LOADED,
    payload
});
export const yearsAdmitedtoBarCurrentMainResultsLoaded = payload => ({
    type: YEARSADMITEDTOBAR_CURRENT_MAIN_RESULTS_LOADED,
    payload
});
export const collegesDataRequested = payload => ({
    type: COLLEGES_DATA_REQUESTED,
    payload
});
export const collegesCurrentMainDataLoaded = payload => ({
    type: COLLEGES_CURRENT_MAIN_RESULTS_LOADED,
    payload
});
export const collegesDataLoaded = payload => ({
    type: COLLEGES_DATA_LOADED,
    payload
});
export const collegesDataBySearchLoaded = payload => ({
    type: COLLEGES_DATA_BY_SEARCH_REQUEST_LOADED,
    payload
});
export const selectSearchItem = (lId, checked) => ({
    type: SELECT_SEARCH_ITEM,
    lId,
    checked
});
export const resetSelectSearchItem = () => ({
    type: RESET_SELECT_SEARCH_ITEM
});
export const setSelectAll = selectAll => ({
    type: SET_SELECT_ALL,
    selectAll
});
export const resetSearchToInitialState = () => ({
    type: SEARCH_RESET_INITIAL_STATE
});
export const searchDataSortBy = (val) => ({
    type: SEARCH_DATA_SORTBY,
    val
});