

export function getStateQueryString(selstatecodes) {
    var stateqry = "";
    var i;

    if (typeof selstatecodes === "object") {
        for (i = 0; i < selstatecodes.length; i++) {
            stateqry = stateqry + selstatecodes[i] + "|";
        }
        return stateqry.substr(0, stateqry.length - 1);
    } else {
        return selstatecodes;
    }
}