export const STATE_MULTIPLE_SELECTED = "STATE_SELECTED";
export const STATE_RESET = "STATE_UN_SELECTED";
export const STATE_SINGLE_SELECTED = "STATE_SINGLE_SELECTED";
export const COUNSEL_COUNT_COUNT_REQUEST = "COUNSEL_COUNT_COUNT_REQUEST";
export const LOAD_HOME_DATA = "LOAD_HOME_DATA";
export const LOAD_HOME_DATA_BY_STATES = "LOAD_HOME_DATA_BY_STATES";
export const TOTAL_COUNSEL_COUNT_LOADED = "TOTAL_COUNSEL_COUNT_LOADED";
export const TOTAL_COUNSEL_EMAIL_COUNT_LOADED = "TOTAL_COUNSEL_EMAIL_COUNT_LOADED";
export const USER_INFO_LOADED = "USER_INFO_LOADED";
export const USER_INFO_REQUESTED = "USER_INFO_REQUESTED";
export const RSS_FEED_LOADED = "RSS_FEED_LOADED";
export const RSS_FEED_REQUESTED = "RSS_FEED_REQUESTED";
export const PRODUCTS_REQUESTED = "PRODUCTS_REQUESTED";
export const PRODUCTS_LOADED = "PRODUCTS_LOADED";
//Request Actions
export const HOME_DATA_TOP_CITIES_REQUEST = "HOME_DATA_TOP_CITIES_REQUEST";
export const HOME_DATA_TOP_COLLEGES_REQUEST = "HOME_DATA_TOP_COLLEGES_REQUEST";
export const HOME_DATA_COUNSEL_COUNT_REQUEST = "HOME_DATA_COUNSEL_COUNT_REQUEST";
export const HOME_DATA_TOP_PRACTICEAREAS_REQUEST = "HOME_DATA_TOP_PRACTICEAREAS_REQUEST";
export const HOME_DATA_TOP_YEARADMITTEDTOBAR_REQUEST = "HOME_DATA_TOP_YEARADMITTEDTOBAR_REQUEST";
//Success Actions
export const HOME_DATA_COUNSEL_COUNT_LOADED = "HOME_DATA_COUNSEL_COUNT_LOADED";
export const HOME_DATA_TOP_PRACTICEAREAS_LOADED = "HOME_DATA_TOP_PRACTICEAREAS_LOADED";
export const HOME_DATA_TOP_YEARADMITTEDTOBAR_LOADED = "HOME_DATA_TOP_YEARADMITTEDTOBAR_LOADED";
export const HOME_DATA_TOP_CITIES_LOADED = "HOME_DATA_TOP_CITIES_LOADED";
export const HOME_DATA_TOP_COLLEGES_LOADED = "HOME_DATA_TOP_COLLEGES_LOADED";


export const loadTopCities = (states) => ({
    type: HOME_DATA_TOP_CITIES_REQUEST,
    states
});
export const loadTopColleges = (states) => ({
    type: HOME_DATA_TOP_COLLEGES_REQUEST,
    states
});
export const loadCounselCountByState = (states) => ({
    type: HOME_DATA_COUNSEL_COUNT_REQUEST,
    states
});
export const loadTopPracticeAreas = (states) => ({
    type: HOME_DATA_TOP_PRACTICEAREAS_REQUEST,
    states
});
export const loadTopYearAdmittedToBars = (states) => ({
    type: HOME_DATA_TOP_YEARADMITTEDTOBAR_REQUEST,
    states
});

export const selectMultipleStates = usStates => ({
    type: STATE_MULTIPLE_SELECTED,
    usStates
});
export const selectSingleState = usState => ({
    type: STATE_SINGLE_SELECTED,
    usState
});
export const resetStates = () => ({
    type: STATE_RESET
});
export const topCitiesDataLoadedAction = data => ({
   type: HOME_DATA_TOP_CITIES_LOADED,
   data
});
export const topCollegesDataLoadedAction = data => ({
    type: HOME_DATA_TOP_COLLEGES_LOADED,
    data
});
export const counselCountByStateRequested = states => ({
    type: COUNSEL_COUNT_COUNT_REQUEST,
    states
});
export const counselCountByStateLoaded = data => ({
    type: HOME_DATA_COUNSEL_COUNT_LOADED,
    data
});
export const topPracticeAreasDataLoaded = data => ({
    type: HOME_DATA_TOP_PRACTICEAREAS_LOADED,
    data
});
export const topYearAdmittedToBarLoaded = data => ({
    type: HOME_DATA_TOP_YEARADMITTEDTOBAR_LOADED,
    data
});
export const totalCounselCountLoaded = data => ({
    type: TOTAL_COUNSEL_COUNT_LOADED,
    data
});
export const totalCounselEmailCountLoaded = data => ({
    type: TOTAL_COUNSEL_EMAIL_COUNT_LOADED,
    data
});
export const loadHomeData = (states) => ({
    type: LOAD_HOME_DATA,
    states
});
export const loadHomeDataByStates = states => ({
    type: LOAD_HOME_DATA_BY_STATES,
    states
});
export const userInfoRequested = () => ({
    type: USER_INFO_REQUESTED,
});
export const userInfoLoaded = data => ({
    type: USER_INFO_LOADED,
    data
});
export const rssFeedRequested = () => ({
    type: RSS_FEED_REQUESTED,
});
export const rssFeedLoaded = data => ({
    type: RSS_FEED_LOADED,
    data
});
export const productsRequested = () => ({
    type: PRODUCTS_REQUESTED
});
export const productsLoaded = data => ({
    type: PRODUCTS_LOADED,
    data
});