import React from "react";
import { Link } from 'react-router-dom';

const SearchResultListItem = (props) => {
    const onChange = (event, id) => {
        props.checkBoxOnChange(id, event.target.checked)
    }

    return (<>
        <div className="wk-layout-50-50 search-result-list-item">
            <div className="wk-layout-item">
                <div className="wk-layout-50-50">
                    <div className="wk-layout-item">
                    <span>
                       <input type="checkbox" onChange={(e) => onChange(e, props.item.lawyerId)}
                              checked={props.item.checked == true}/>
                    </span>
                        <Link
                            to={"/search/read?pid=" + props?.item?.fkParentCompany}><span>{props?.item?.company}</span></Link>
                    </div>
                    <div className="wk-layout-item">
                        <Link
                            to={"/search/read?pid=" + props?.item?.fkParentCompany + "&cid=" + props?.item?.companyId}><span>{props?.item?.subsidiary}</span></Link>
                    </div>
                </div>
            </div>
            <div className="wk-layout-item">
                <div className="wk-layout-50-50">
                    <div className="wk-layout-item">
                        <Link
                            to={"/search/read?pid=" + props?.item?.fkParentCompany + "&cid=" + props?.item?.companyId + "&lid=" + props?.item?.lawyerId}><span
                                className="search-attorney-info">{props?.item?.fName + " " + props?.item?.lName + " " + props?.item?.suffix}</span></Link>
                        <span className="search-attorney-info">{props?.item?.title}</span>                       
                    </div>
                    <div className="wk-layout-item">
                        <div className="wk-layout-50-50">
                            <div className="wk-layout-item search-address-info">{props?.item?.city}</div>
                            <div className="wk-layout-item search-address-info">{props?.item?.lookupStateProvince}</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>);
}
export default SearchResultListItem;