import {
    READCHECKED_COMPANY_DATA_LOADED,
    READCHECKED_LAWYER_DATA_LOADED,
    LAWYERS_COLLEGE_DATA_LOADED,
    LAWYERS_PA_DATA_LOADED,
    LAWYERS_TITLES_DATA_LOADED,
    READCHECKED_FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS,
    READCHECKED_RESET_ALL_READ_DATA,
    READCHECKED_SET_SELECT_ALL,
    READCHECKED_UPDATE_COMPANY_LAWYERS_DATA, READCHECKED_RESET_INITIAL_STATE
} from "state/actions/readCheckedActions";

const initialState = {
    isToolbarActive: false,
    companyResult: [],
    allLawyers: [],
    lawyerIds: [],
    currentLawyers: [],
    readSelectedallCollegesResults: [],
    readSelectedallPAResults: [],
    readSelectedallTitlesResults: [],
    readSelectedcurrentCollegesResults: [],
    readSelectedcurrentPAResults: [],
    readSelectedcurrentTitlesResults: []
}
export default function (state = initialState, action) {
    switch (action.type) {
        case READCHECKED_COMPANY_DATA_LOADED:
            return { ...state, companyResult: action.payload };
        case READCHECKED_LAWYER_DATA_LOADED:
            return { ...state, allLawyers: action.payload, currentLawyers: action.payload };
        case LAWYERS_COLLEGE_DATA_LOADED:
            return {
                ...state,
                readSelectedcurrentCollegesResults: action.payload,
                readSelectedallCollegesResults: action.payload
            }
        case LAWYERS_PA_DATA_LOADED:
            return {
                ...state,
                readSelectedcurrentPAResults: action.payload,
                readSelectedallPAResults: action.payload
            }
        case LAWYERS_TITLES_DATA_LOADED:
            return {
                ...state,
                readSelectedcurrentTitlesResults: action.payload,
                readSelectedallTitlesResults: action.payload
            }
        case READCHECKED_FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS:
            const readselectedfilteredCompanyLawyers = state.allLawyers?.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) > -1);
            const readSelectedfilteredColleges = state.readSelectedcurrentCollegesResults?.filter(item => action.lawyerIds.findIndex(lId => lId === item.lId) !== -1);
            const readSelectedfilteredPracticeAreas = state.readSelectedcurrentPAResults?.filter(item => action.lawyerIds.findIndex(lId => lId === item.lId) !== -1);
            const readSelectedfilteredTitles = state.readSelectedcurrentTitlesResults?.filter(item => action.lawyerIds.findIndex(lId => lId === item.lawyerId) !== -1);
            return {
                ...state,
                lawyerIds: action.lawyerIds,
                currentLawyers: readselectedfilteredCompanyLawyers,
                readSelectedcurrentCollegesResults: readSelectedfilteredColleges,
                readSelectedcurrentPAResults: readSelectedfilteredPracticeAreas,
                readSelectedcurrentTitlesResults: readSelectedfilteredTitles
            };
        case READCHECKED_RESET_ALL_READ_DATA:
            return {
                ...state,
                lawyerIds: state.allLawyers.map(item => item.lId),
                currentLawyers: state.allLawyers,
                readSelectedcurrentCollegesResults: state.readSelectedallCollegesResults,
                readSelectedcurrentPAResults: state.readSelectedallPAResults,
                readSelectedcurrentTitlesResults: state.readSelectedallTitlesResults,
            };
        case READCHECKED_SET_SELECT_ALL:
            return {
                ...state, currentLawyers: state.currentLawyers.map((item, index) => {
                    return {
                        ...item,
                        checked: action.readCheckedSelectAll
                    }
                })
            };
        case READCHECKED_UPDATE_COMPANY_LAWYERS_DATA:
            return {
                ...state,
                currentLawyers: action.payload
            };
        case READCHECKED_RESET_INITIAL_STATE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}
