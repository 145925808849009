import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import NoRecordFound from 'components/NoRecordFound';
import SearchResultListItem from './SearchResultListItem';
import PaginationV1 from './PaginationV1';
import { setSearchPage, selectSearchItem } from "state/actions/searchActions";
import { scrollToTop } from '../../utils/scrollToTop';

export const SearchResultDetail = ({ pageSize, isPrinting, sortBy}) => {        
    const currentPage = useSelector(state => state.search.searchPage);
    const dispatch = useDispatch();
    const currentMainResults = useSelector(state => state.search.currentMainResults);
    const [setPage, setSelectedPage] = useState(0);
    const [paginationAction, setPaginationAction] = useState('');
    let lastIndex = currentPage * pageSize;
    let firstIndex = lastIndex - pageSize;
    let filteredResults = currentMainResults.slice(firstIndex, lastIndex);
    if (isPrinting) {
        filteredResults = currentMainResults.filter(item => item.checked);
    }
    const paginate = (pagNumber, paginationAction) => {
        dispatch(setSearchPage(pagNumber));
        setSelectedPage(pagNumber);
        setPaginationAction(paginationAction);
        scrollToTop(true);
    };
    const checkBoxOnChange = async (lawyerId, checked) => {
        dispatch(selectSearchItem(lawyerId, checked));
    }
    return (
        <div className="search-result-list">
            {
                (currentMainResults && currentMainResults.length > 0) ?
                    <>
                        <div className="wk-layout-50-50 search-table-header sticky">
                            <div className="wk-layout-item">
                                <div className="wk-layout-50-50">
                                    <div className="wk-layout-item search-header-padded">
                                        <b>
                                            Company
                                        </b>
                                    </div>
                                    <div className="wk-layout-item">
                                        <b>Subsidiary</b>
                                    </div>
                                </div>
                            </div>
                            <div className="wk-layout-item">
                                <div className="wk-layout-50-50">
                                    <div className="wk-layout-item">
                                        <b>Name</b>
                                    </div>
                                    <div className="wk-layout-item">
                                        <div className="wk-layout-50-50">
                                            <div className="wk-layout-item search-header-center"><b>City</b></div>
                                            <div className="wk-layout-item search-header-center"><b>State</b></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div>
                            {filteredResults.map(fr => (
                                <SearchResultListItem key={fr.lawyerId} item={fr} checkBoxOnChange={checkBoxOnChange}/>

                            ))}
                            <PaginationV1 totalRecords={currentMainResults.length} pageSize={pageSize}
                                currentPage={currentPage} paginate={paginate} setPage={setPage} paginationAction={paginationAction}/>
                        </div>
                    </>
                    : <NoRecordFound />
            }
        </div>);
};


