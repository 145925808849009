import {get} from "./ApiService";

export const getSearchResults = (payload) => {
    let url = 'api/search?';
    return get(preparQueryString(url, payload)).then(response =>
        response
    );
};

function preparQueryString(url, payload) {
    if (payload.cn) {
        url = url.concat("cn=", encodeURIComponent(payload.cn));
    }
    if (payload.cc) {
        url = url.concat("&cc=", encodeURIComponent(payload.cc));
    }
    if (payload.cs) {
        url = url.concat("&cs=", encodeURIComponent(payload.cs));
    }
    if (payload.an) {
        url = url.concat("&an=", encodeURIComponent(payload.an));
    }
    if (payload.ap) {
        url = url.concat("&ap=", encodeURIComponent(payload.ap));
    }
    if (payload.auj) {
        url = url.concat("&auj=", encodeURIComponent(payload.auj));
    }
    if (payload.anj) {
        url = url.concat("&anj=", encodeURIComponent(payload.anj));
    }
    if (payload.at) {
        url = url.concat("&at=", encodeURIComponent(payload.at));
    }
    if (payload.am) {
        url = url.concat("&am=", encodeURIComponent(payload.am));
    }
    if (payload.al) {
        url = url.concat("&al=", encodeURIComponent(payload.al));
    }
    if (payload.ad) {
        url = url.concat("&ad=", encodeURIComponent(payload.ad));
    }
    if (payload.yf) {
        url = url.concat("&yf=", encodeURIComponent(payload.yf));
    }
    if (payload.yt) {
        url = url.concat("&yt=", encodeURIComponent(payload.yt));
    }
	if (payload.yb) {
        url = url.concat("&yb=", payload.yb);
    }
    if (payload.gc) {
        url = url.concat("&gc=", payload.gc);
    }
    if (payload.em) {
        url = url.concat("&em=", payload.em);
    }
    return url;
}
