import React, {Component} from 'react';
import clsx from "clsx";
import {Link} from 'react-router-dom';
import './NavMenu.css';
import Breadcrumb from "./Breadcrumb";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        };
    }

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <nav className="wk-navbar">
                <div className="wk-navbar-container">
                    <Breadcrumb/>
                    <ul className="wk-more-menu">
                        <li role="presentation"><a href="#"><span className="wk-icon-menu"/>Menu</a></li>
                    </ul>
                    <ul className="wk-nav">
                    </ul>
                    <div className="wk-product-name"><em>Corporate Counsel Profiler</em></div>
                </div>
            </nav>
        );
    }
}
