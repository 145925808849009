import { call, put, fork, takeLatest, all } from "redux-saga/effects";
import {
    LOAD_HOME_DATA,
    LOAD_HOME_DATA_BY_STATES,
    counselCountByStateLoaded,
    topCollegesDataLoadedAction,
    topPracticeAreasDataLoaded,
    topYearAdmittedToBarLoaded,
    totalCounselCountLoaded,
    totalCounselEmailCountLoaded,
    userInfoLoaded,
    rssFeedLoaded,
    USER_INFO_REQUESTED,
    RSS_FEED_REQUESTED,
    PRODUCTS_REQUESTED,
    productsLoaded,
    HOME_DATA_TOP_CITIES_REQUEST,
    HOME_DATA_TOP_COLLEGES_REQUEST,
    HOME_DATA_COUNSEL_COUNT_REQUEST,
    HOME_DATA_TOP_PRACTICEAREAS_REQUEST,
    HOME_DATA_TOP_YEARADMITTEDTOBAR_REQUEST
} from "../actions/homeActions";
import {
    getCounselCountByState,
    getProducts,
    getRssFeed,
    getTopCities,
    getTopColleges,
    getTopPracticeAreas,
    getTopYearAdmittedToBars, getTotalCounselCount, getTotalCounselEmailCount, getUserInfo
} from "services/HomeDataService";
import { topCitiesDataLoadedAction } from "../actions/homeActions";
import { spinnerChangedAction } from "../actions/spinnerActions";
import { logAndRedirectToErrorPage } from "components/ErrorComponent";
import { history } from "routerHistory";
import { AuthenticationError } from "../../common/AuthenticationError";

function* loadHomeDataTask(action) {
    try {
        yield put(spinnerChangedAction(true));
        const [
            topCitiesResponse,
            topCollegesResponse,
            counselByStateCountResponse,
            practiceAreasResponse,
            yearAdmittedToBarResponse,
            totalCounselCountResponse,
            totalCounselEmailCountResponse,
        ] = yield all([
            call(getTopCities, action.states),
            call(getTopColleges, action.states),
            call(getCounselCountByState, action.states),
            call(getTopPracticeAreas, action.states),
            call(getTopYearAdmittedToBars, action.states),
            call(getTotalCounselCount, action.states),
            call(getTotalCounselEmailCount, action.states),
        ]);
        yield put(topCitiesDataLoadedAction(topCitiesResponse.results));
        yield put(topCollegesDataLoadedAction(topCollegesResponse.results));
        yield put(counselCountByStateLoaded(counselByStateCountResponse.results));
        yield put(topPracticeAreasDataLoaded(practiceAreasResponse.results));
        yield put(topYearAdmittedToBarLoaded(yearAdmittedToBarResponse.results));
        yield put(totalCounselCountLoaded(totalCounselCountResponse[0].count));
        yield put(totalCounselEmailCountLoaded(totalCounselEmailCountResponse[0].count));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* loadHomeDataMandatoryComponentsTask(action) {
    try {
        yield put(spinnerChangedAction(true));
        const [            
            totalCounselCountResponse,
            totalCounselEmailCountResponse,
        ] = yield all([            
            call(getTotalCounselCount, action.states),
            call(getTotalCounselEmailCount, action.states)
        ]);        
        yield all([
            put(totalCounselCountLoaded(totalCounselCountResponse[0].count)),
            put(totalCounselEmailCountLoaded(totalCounselEmailCountResponse[0].count)),
            put(spinnerChangedAction(false))
        ]);
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* loadHomeDataByStateTask(action) {
    try {
        if (action.states) {
            yield put(spinnerChangedAction(true));
            const [
                topCitiesResponse,
                topCollegesResponse,
                counselByStateCountResponse,
                practiceAreasResponse,
                yearAdmittedToBarResponse,
                totalCounselCountResponse,
                totalCounselEmailCountResponse
            ] = yield all([call(getTopCities, action.states),
                call(getTopColleges, action.states),
                call(getCounselCountByState, action.states),
                call(getTopPracticeAreas, action.states),
                call(getTopYearAdmittedToBars, action.states),
                call(getTotalCounselCount, action.states),
                call(getTotalCounselEmailCount, action.states)
            ]);
            yield put(topCitiesDataLoadedAction(topCitiesResponse.results));
            yield put(topCollegesDataLoadedAction(topCollegesResponse.results));
            yield put(counselCountByStateLoaded(counselByStateCountResponse.results));
            yield put(topPracticeAreasDataLoaded(practiceAreasResponse.results));
            yield put(topYearAdmittedToBarLoaded(yearAdmittedToBarResponse.results));
            yield put(totalCounselCountLoaded(totalCounselCountResponse[0].count));
            yield put(totalCounselEmailCountLoaded(totalCounselEmailCountResponse[0].count));
            yield put(spinnerChangedAction(false));
        }
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* userInfoTask(){
    try {
        const response = yield call(getUserInfo);
        yield put(userInfoLoaded(response));
    } catch (e) {
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* rssFeedTask(){
    try {
        const response = yield call(getRssFeed);
        yield put(rssFeedLoaded(response));
    } catch (e) {
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* productsTask() {
    try {
        const response = yield call(getProducts);
        yield put(productsLoaded(response));
    } catch (e) {
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* loadTopCities(action) {
    try {        
        const topCitiesResponse = yield call(getTopCities, action.states);
        yield put(topCitiesDataLoadedAction(topCitiesResponse.results));
    } catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* loadTopColleges(action) {
    try {
        const topCollegesResponse = yield call(getTopColleges, action.states);
        yield put(topCollegesDataLoadedAction(topCollegesResponse.results));
    } catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* loadCounselCountByState(action) {
    try {
        const counselByStateCountResponse = yield call(getCounselCountByState, action.states);
        yield put(counselCountByStateLoaded(counselByStateCountResponse.results));
    } catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* loadTopPracticeAreas(action) {
    try {
        const practiceAreasResponse = yield call(getTopPracticeAreas, action.states);
        yield put(topPracticeAreasDataLoaded(practiceAreasResponse.results));
    } catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}
function* loadTopYearAdmittedToBars(action) {
    try {
        const yearAdmittedToBarResponse = yield call(getTopYearAdmittedToBars, action.states);
        yield put(topYearAdmittedToBarLoaded(yearAdmittedToBarResponse.results));
    } catch (e) {
        if (e instanceof AuthenticationError) {
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* watchLoadTopCities() {
    yield takeLatest(HOME_DATA_TOP_CITIES_REQUEST, loadTopCities);
}

function* watchLoadTopColleges() {
    yield takeLatest(HOME_DATA_TOP_COLLEGES_REQUEST, loadTopColleges);
}

function* watchLoadCounselCountByState() {
    yield takeLatest(HOME_DATA_COUNSEL_COUNT_REQUEST, loadCounselCountByState);
}

function* watchLoadTopPracticeAreas() {
    yield takeLatest(HOME_DATA_TOP_PRACTICEAREAS_REQUEST, loadTopPracticeAreas);
}

function* watchLoadTopYearAdmittedToBars() {
    yield takeLatest(HOME_DATA_TOP_YEARADMITTEDTOBAR_REQUEST, loadTopYearAdmittedToBars);
}

function* watchLoadHomeData() {
    yield takeLatest(LOAD_HOME_DATA, loadHomeDataMandatoryComponentsTask);
}

function* watchLoadHomeDataByStates() {
    yield takeLatest(LOAD_HOME_DATA_BY_STATES, loadHomeDataByStateTask);
}
function* watchUserInfo(){
    yield takeLatest(USER_INFO_REQUESTED, userInfoTask)
}
function* watchRssFeed(){
    yield takeLatest(RSS_FEED_REQUESTED, rssFeedTask)
}
function* watchProducts(){
    yield takeLatest(PRODUCTS_REQUESTED, productsTask)
}
export default [
    fork(watchLoadHomeData),
    fork(watchLoadHomeDataByStates),
    fork(watchRssFeed),
    fork(watchUserInfo),
    fork(watchProducts),
    fork(watchLoadTopCities),
    fork(watchLoadTopColleges),
    fork(watchLoadCounselCountByState),
    fork(watchLoadTopPracticeAreas),
    fork(watchLoadTopYearAdmittedToBars)
];