import React from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from "utils/testUtils";
import { useHistory } from "react-router";
import color from "../../utils/color";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useSelector } from "react-redux";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";

const PracticeAreaBi = ({ selectedStates, practiceAreas, searchWarning}) => {
    const history = useHistory();
    const practiceAreaBiData = useSelector(state => state.home.topPracticeAreas);
    const loadingSelector = createLoadingSelector(['HOME_DATA_TOP_PRACTICEAREAS']);
    const loading = useSelector(state => loadingSelector(state));
    const triggerSearch = legendItem => {
        try {
            let practiceArea = practiceAreas?.filter(item => item.desc === legendItem.text)[0];
            let queryString = `ap=${practiceArea.id}`
            if (selectedStates && selectedStates.length > 0) {
                queryString = queryString.concat("&cs=" + selectedStates.join("|"))
            }
            searchWarning(queryString);
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }
    return (
        <div>
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && <Piechart
                label="Top Practice Areas"
                data={practiceAreaBiData.map(item => item.data)}
                labels={practiceAreaBiData.map(item => item.label)}
                ref={registerTestObject(BI.HOME_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => triggerSearch(legendItem)}
                backgroundColors={[...color(practiceAreaBiData.length)]} />}
        </div>
    );
};
export default PracticeAreaBi;
