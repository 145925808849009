import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import color from "../../utils/color";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { createLoadingSelector } from "state/selectors/loadingSelector";


const TopStatesBi = ({ searchWarning }) => {
    const history = useHistory();
    const loadingSelector = createLoadingSelector(['HOME_DATA_COUNSEL_COUNT']);
    const loading = useSelector(state => loadingSelector(state));
    const topStatesBiData = useSelector(state => state.home.counselCountByState);
    const triggerSearch = legendItem => {
        try {
            let queryString = `&cs=${legendItem.text}`
            searchWarning(queryString);
        } catch (error) {
            logAndRedirectToErrorPage(error, history);
        }
    }
    return (
        <div>
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && <Piechart
                label="Top States"
                data={topStatesBiData.map(item => item.data)}
                labels={topStatesBiData.map(item => item.label)}
                ref={registerTestObject(BI.HOME_COMPANY_STATES_CHART)}
                legendCallBack={(event, legendItem) => triggerSearch(legendItem)}
                backgroundColors={[...color(topStatesBiData.length)]}/>}
        </div>
    );
};
export default TopStatesBi;
