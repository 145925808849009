import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';

const ManageAlertsItemTitle = ({ disabled, scrollbar, onCheckAllChange }) => {

    return (
        <div className="wk-row" style={{ fontWeight: 'bold' }}>
            <div className="wk-col-5">
                <div className="wk-layout-50-50">
                    <div>
                        <span className="manage-alerts-checkbox-margin">
                            <input data-testid="cbx_all" id="cbx_all" type="checkbox" onChange={(e) => onCheckAllChange(e)} disabled={disabled} />
                        </span>
                        <span>Alert Name</span>
                    </div>
                </div>
            </div>
            <div className="wk-col-7">
                <div className="wk-layout-25-25-25-25">
                    <div className="wk-layout-item" style={{ marginLeft: scrollbar ? -10 : 0 }}>
                        <span>Created On</span>
                    </div>
                    <div className="wk-layout-item">
                        <span>Modified On</span>
                    </div>                    
                    <div className={!scrollbar ? "manage-alerts-delete-icon-scrollbar" : "manage-alerts-delete-icon"}>
                        <span>Delete</span>
                    </div>
                    <div className="manage-alerts-edit-icon">
                        <span>Edit</span>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ManageAlertsItemTitle