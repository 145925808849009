export const companyStates = [
    { "id": "AL", "desc": "Alabama" },
    { "id": "AK", "desc": "Alaska" },
    { "id": "AZ", "desc": "Arizona" },
    { "id": "AR", "desc": "Arkansas" },
    { "id": "CA", "desc": "California" },
    { "id": "CO", "desc": "Colorado" },
    { "id": "CT", "desc": "Connecticut" },
    { "id": "DE", "desc": "Delaware" },
    { "id": "DC", "desc": "District of Columbia" },
    { "id": "FL", "desc": "Florida" },
    { "id": "GA", "desc": "Georgia" },
    { "id": "HI", "desc": "Hawaii" },
    { "id": "ID", "desc": "Idaho" },
    { "id": "IL", "desc": "Illinois" },
    { "id": "IN", "desc": "Indiana" },
    { "id": "IA", "desc": "Iowa" },
    { "id": "KS", "desc": "Kansas" },
    { "id": "KY", "desc": "Kentucky" },
    { "id": "LA", "desc": "Louisiana" },
    { "id": "ME", "desc": "Maine" },
    { "id": "MD", "desc": "Maryland" },
    { "id": "MA", "desc": "Massachusetts" },
    { "id": "MI", "desc": "Michigan" },
    { "id": "MN", "desc": "Minnesota" },
    { "id": "MS", "desc": "Mississippi" },
    { "id": "MO", "desc": "Missouri" },
    { "id": "MT", "desc": "Montana" },
    { "id": "NE", "desc": "Nebraska" },
    { "id": "NV", "desc": "Nevada" },
    { "id": "NH", "desc": "New Hampshire" },
    { "id": "NJ", "desc": "New Jersey" },
    { "id": "NM", "desc": "New Mexico" },
    { "id": "NY", "desc": "New York" },
    { "id": "NC", "desc": "North Carolina" },
    { "id": "ND", "desc": "North Dakota" },
    { "id": "OH", "desc": "Ohio" },
    { "id": "OK", "desc": "Oklahoma" },
    { "id": "OR", "desc": "Oregon" },
    { "id": "PA", "desc": "Pennsylvania" },
    { "id": "RI", "desc": "Rhode Island" },
    { "id": "SC", "desc": "South Carolina" },
    { "id": "SD", "desc": "South Dakota" },
    { "id": "TN", "desc": "Tennessee" },
    { "id": "TX", "desc": "Texas" },
    { "id": "UT", "desc": "Utah" },
    { "id": "VT", "desc": "Vermont" },
    { "id": "VA", "desc": "Virginia" },
    { "id": "WA", "desc": "Washington" },
    { "id": "WV", "desc": "West Virginia" },
    { "id": "WI", "desc": "Wisconsin" },
    { "id": "WY", "desc": "Wyoming" },
    { "id": "Abu%20Dhabi", "desc": "Abu Dhabi - AE" },
    { "id": "Sao%20Paulo", "desc": "Sao Paulo - BR" },
    { "id": "AB", "desc": "AB - CA" },
    { "id": "BC", "desc": "BC - CA" },
    { "id": "MB", "desc": "MB - CA" },
    { "id": "NB", "desc": "NB - CA" },
    { "id": "NF", "desc": "NF - CA" },
    { "id": "NS", "desc": "NS - CA" },
    { "id": "ON", "desc": "ON - CA" },
    { "id": "QC", "desc": "QC - CA" },
    { "id": "SK", "desc": "SK - CA" },
    { "id": "Doha", "desc": "Doha - QA" }]

export const practiceAreas = [
    { "id": "40", "desc": "1940 Act" },
    { "id": "AS", "desc": "Administration" },
    { "id": "AD", "desc": "Advertising" },
    { "id": "AN", "desc": "Antitrust" },
    { "id": "AM", "desc": "Association Management" },
    { "id": "BK", "desc": "Banking" },
    { "id": "BA", "desc": "Bankruptcy" },
    { "id": "CV", "desc": "Civil Rights" },
    { "id": "CL", "desc": "Commercial" },
    { "id": "CP", "desc": "Compliance" },
    { "id": "CS", "desc": "Construction" },
    { "id": "CO", "desc": "Consumer" },
    { "id": "CN", "desc": "Contracts" },
    { "id": "CY", "desc": "Copyright" },
    { "id": "CR", "desc": "Criminal" },
    { "id": "DE", "desc": "Derivatives" },
    { "id": "ED", "desc": "Education" },
    { "id": "EM", "desc": "Employee Benefits" },
    { "id": "EN", "desc": "Entertainment" },
    { "id": "EE", "desc": "Environment/Energy" },
    { "id": "EA", "desc": "ERISA" },
    { "id": "FD", "desc": "Food and Drug" },
    { "id": "FR", "desc": "Franchise" },
    { "id": "GE", "desc": "General/Corporate" },
    { "id": "GO", "desc": "Government Contracts" },
    { "id": "GR", "desc": "Government Relations" },
    { "id": "HM", "desc": "Health/Medical" },
    { "id": "IN", "desc": "Insurance" },
    { "id": "IP", "desc": "Intellectual Property/Computers" },
    { "id": "IT", "desc": "International" },
    { "id": "LE", "desc": "Labor & Employment" },
    { "id": "LC", "desc": "Licensing" },
    { "id": "LI", "desc": "Litigation" },
    { "id": "MD", "desc": "Manager/Director, Law Dept" },
    { "id": "MA", "desc": "Mergers & Acquisitions" },
    { "id": "OG", "desc": "Oil and Gas" },
    { "id": "OS", "desc": "OSHA" },
    { "id": "PT", "desc": "Patent" },
    { "id": "PL", "desc": "Personnel/Labor" },
    { "id": "PR", "desc": "Products Liability" },
    { "id": "PU", "desc": "Public Utility" },
    { "id": "RE", "desc": "Real Estate" },
    { "id": "RG", "desc": "Regulatory" },
    { "id": "SF", "desc": "Securities/Finance" },
    { "id": "SW", "desc": "Social Services/Welfare" },
    { "id": "TX", "desc": "Tax" },
    { "id": "TL", "desc": "Telecommunications" },
    { "id": "TR", "desc": "Trade Regulations" },
    { "id": "TM", "desc": "Trademark" },
    { "id": "TN", "desc": "Transportation" },
    { "id": "TE", "desc": "Trusts and Estates" },
    { "id": "WC", "desc": "Workers' Compensation" }]

export const usStateBars = [
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY"]

export const nonUSStateBars4CA = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NS",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT"]

export const nonUSStateBars4Others = [
    "Anguilla",
    "Argentina",
    "Australia",
    "Beirut",
    "BR",
    "Brazil",
    "Canada",
    "Chile",
    "China",
    "CI",
    "Colombia",
    "CY",
    "England",
    "England & Wales",
    "England and Wales",
    "FED",
    "Federal Dist Court France",
    "Germany",
    "Greece",
    "Hong Kong",
    "India",
    "Indonesia",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Law Society of Upper Cana",
    "Lebanon",
    "Malaysia",
    "MD (inactive)",
    "Mexico",
    "New South Wales",
    "New Zealand",
    "Nigeria",
    "NL",
    "Mexico",
    "OH Patent Bar",
    "Philippines",
    "PL",
    "PRL",
    "Rio de Janeiro",
    "RO",
    "RS",
    "Russia",
    "Saint Lucia",
    "Sao Paulo",
    "Scotland",
    "Singapore",
    "South Africa",
    "South Wales",
    "SP",
    "Spain",
    "Switzerland",
    "Taiwan",
    "UA",
    "UK",
    "United Kingdom",
    "USSC",
    "Venezuela",
    "VIC",
    "WQ",
    "Zurich"]
