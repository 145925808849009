import { redirectToErrorPage, logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { history } from "../routerHistory";
import { AuthenticationError } from "../common/AuthenticationError";

const controller = new AbortController();
const { signal } = controller;

export const get = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            signal
        });
        if (response.status === 403) {
            controller.abort();
            window.location.href = window.location.origin + "/Account/Login?returnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
            throw new AuthenticationError("User not authenticated!");
        }
        if (response.status && (response.status === 400 || response.status >= 500)) {
            logAndRedirectToErrorPage(response.json(), history);
        }
        return await response.json();
    } catch (e) {
        if (e instanceof AuthenticationError)
            throw e;
        redirectToErrorPage(history);
    }
}
export const post = async (url, body) => {
    try {
        const response = await fetch(url, {
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            signal,
            method: 'POST'

        });
        if (response.status === 403) {
            controller.abort();
            window.location.href = window.location.origin + "/Account/Login?returnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
            throw new AuthenticationError("User not authenticated!");
        }
        // if(response.redirected){
        //     window.location.href = response.url;
        // }
        if (response.status && (response.status === 400 || response.status >= 500)) {
            logAndRedirectToErrorPage(response.json(), history);
        }
        return response.json();
    } catch (e) {
        if (e instanceof AuthenticationError)
            throw e;
        redirectToErrorPage(history);
    }
}