import React from 'react';
import {BarChart} from "components/charts/BarChart";
import {registerTestObject, BI} from "utils/testUtils";
import {useHistory} from "react-router";
import color from "../../utils/color";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useSelector } from "react-redux";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";

const YearAdmittedtobarBI = ({ selectedStates, searchWarning}) => {
    const history = useHistory();
    const yearAdmittedToBarBiData = useSelector(state => state.home.topYearAdmittedToBars);
    const loadingSelector = createLoadingSelector(['HOME_DATA_TOP_YEARADMITTEDTOBAR']);
    const loading = useSelector(state => loadingSelector(state));
    const triggerSearch = legendItem => {
        try {
            let queryString = `yb=${legendItem.text}`
            if (selectedStates && selectedStates.length > 0) {
                queryString = queryString.concat("&cs=" + selectedStates.join("|"))
            }
            searchWarning(queryString);
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }
    return (
        <div>
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && <BarChart
                label="Top Year Admitted to Bar"
                data={yearAdmittedToBarBiData.map(item => item.data)}
                labels={yearAdmittedToBarBiData.map(item => item.label)}
                ref={registerTestObject(BI.HOME_YEARS_ADMITTED_TO_BAR_CHART)}
                legendCallBack={(event, legendItem) => triggerSearch(legendItem)}
                backgroundColors={[...color(yearAdmittedToBarBiData.length)]}/>}
        </div>
    );
};
export default YearAdmittedtobarBI;
