import React from 'react';
import { MANAGE_ALERTS_OPTIONS } from 'pages/utils/common';

const ManageAlertsOptions = ({ changeHandler }) => {

    return (
        <>
            <div className="wk-pagination-bar manage-alerts-float-right">
                <div className="wk-pagination-results">
                    <div className="wk-field-item">
                        <div className="wk-field-header">
                            <label className="wk-font-weight-bold">Sort By:</label>
                        </div>
                        <div className="wk-select-field">
                            <select data-testid="ManageAlert_Dropdown" className="wk-font-weight-bold" style={{ marginLeft: 5 }} defaultValue={MANAGE_ALERTS_OPTIONS.CreatedOn_Value} onChange={(e) => changeHandler(e.target.value)}>
                                <option value={MANAGE_ALERTS_OPTIONS.CreatedOn_Value}>{MANAGE_ALERTS_OPTIONS.CreatedOn_Label}</option>
                                <option value={MANAGE_ALERTS_OPTIONS.AlertName_Value}>{MANAGE_ALERTS_OPTIONS.AlertName_Label}</option>
                                <option value={MANAGE_ALERTS_OPTIONS.ModifiedOn_Value}>{MANAGE_ALERTS_OPTIONS.ModifiedOn_Label}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    );
}

export default ManageAlertsOptions;