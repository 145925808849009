import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import NoRecordFound from 'components/NoRecordFound';
import { updateCheckedCompanyLawyersData } from "state/actions/readCheckedActions";
import { highlightField } from 'utils/highlightField';
import { READ_LAWYER_LABELS } from '../utils/common';

export const ReadCheckedLawyers = ({ toggleToolbar, companyLawyers, isPrinting }) => {
    const dispatch = useDispatch();
    const currentLawyers = useSelector(state => state.readchecked.currentLawyers);
    const parseEducation = (colleges, degrees) => {
        return colleges?.split("|").map((clg, index) => {
            return (clg + parseDegree(degrees?.split("|")[index]))
        }).join("|");
    }
    const parseDegree = (degree) => {
        return ((degree && degree?.trim() != "0") ? (" (" + (degree?.replace(" 0", "").trim()) + ")") : "")
    }
    const handleCheckLawyerElement = (event) => {
        const lawyers = currentLawyers.map(lawyer => {
            if (lawyer.lId == event.target.value)
                return { ...lawyer, checked: event.target.checked }
            return lawyer;
        });
        dispatch(updateCheckedCompanyLawyersData(lawyers));
        handleReadToolbar(lawyers);
    }
    const handleReadToolbar = (lawyers) => {
        if (lawyers.some(item => item.checked == true)) {
            toggleToolbar(true);
        } else {
            toggleToolbar(false);
        }

    }
    return (
        <div id="ReadLawyers">
            {(companyLawyers && companyLawyers.length > 0) ? <div>
                {companyLawyers?.map((ccr, index) => (
                    <div key={ccr.lId} className="wk-row lawyer-list-item">
                        <div className="wk-col-12">
                            <div className="wk-col-4" id="LawyerPracticeareas">
                                <input type="checkbox" value={ccr.lId}
                                    onChange={(e) => handleCheckLawyerElement(e)}
                                    checked={ccr.checked} /><span><b>
                                        <span className={highlightField(ccr, "firstName")}>{ccr?.firstName}</span>&nbsp;
                                    <span className={highlightField(ccr, "lastName")}>{ccr?.lastName}</span>&nbsp;
                                    {ccr?.lawyerSuffix ? <span>{ccr?.lawyerSuffix}&nbsp;</span> : <></>}
                                </b>{ccr?.email ? <span style={{ display: "inline-block" }}
                                        className={highlightField(ccr, "email")}>{"(" + ccr?.email + ")"}</span> :
                                        <span></span>}</span>
                                {(ccr?.practiceAreas && ccr?.practiceAreas?.split("|").length > 0) ?
                                    <div><span className="lawyer-info"><b>{READ_LAWYER_LABELS.Practice_Areas}</b></span>
                                        <ul>{ccr?.practiceAreas?.split("|").map(pa => (
                                            <li key={ccr.lId + "-" + pa}>{pa}</li>))}
                                        </ul>
                                    </div> : ""}
                                <span
                                    className="lawyer-info">{ccr?.yearJoined ? <span><b>{READ_LAWYER_LABELS.Joined_Firm}</b><span
                                        className={highlightField(ccr, "yearJoined")}>{ccr?.yearJoined}</span></span> :
                                        <span></span>}</span>
                            </div>
                            <div className="wk-col-4">
                                <span
                                    className={highlightField(ccr, "position") + " lawyer-info"}>{ccr?.position}</span>
                                {(ccr?.barInfo && ccr?.barInfo.split("|").length > 0) ? <div>
                                    <span className="lawyer-info"><b>{READ_LAWYER_LABELS.US_State_Non_US_Bars}</b></span>
                                    <ul>{ccr?.barInfo.split("|").map(bi => (<li key={ccr.lId + "-" + bi}>{bi}</li>))}
                                    </ul>
                                </div> : ""}                                
                                {
                                    (ccr?.colleges || ccr?.degrees) ? <div><span
                                        className="lawyer-info"><b>{READ_LAWYER_LABELS.Education}</b></span>
                                        <ul>{parseEducation(ccr?.colleges, ccr?.degrees).split("|").map(ed => (
                                            ed? <li key={ccr.lId + "-" + ed}>{ed}</li> : ""))}
                                        </ul>
                                        </div> : ""
                                }

                            </div>
                            <div className="wk-col-4">
                                {
                                    (ccr?.phone) ? <span
                                        className={highlightField(ccr, "phone") + " lawyer-info"}><b>{READ_LAWYER_LABELS.Telephone_Number}</b>&nbsp;<span>{ccr?.phone}</span></span> : ""
                                }
                                {(ccr?.prevPos && ccr?.prevPos.split("|").length > 0) ? <div>
                                    <span className="lawyer-info"><b>{READ_LAWYER_LABELS.Previous_Positions}</b></span>
                                    <ul className={highlightField(ccr, "prevPos")}>{ccr?.prevPos.split("|").map(pp => (
                                        <li key={ccr.lId + "-" + pp}>{pp}</li>))}
                                    </ul>
                                </div> : ""}                                
                                {(ccr?.memberships && ccr?.memberships.split("|").length>0) ? <div>
                                    <span
                                    ><b>{READ_LAWYER_LABELS.Memberships_Affiliations}</b></span>
                                        <ul className={highlightField(ccr, "memberships")}>{ccr?.memberships.split("|").map(mem => (
                                            <li key={ccr.lId + "-" + mem}>{mem}</li>))}
                                        </ul>
                                        </div> : ""}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
                : !isPrinting && <NoRecordFound />
            }

        </div>
    );
};