import React, { useState } from 'react';
import { scrollToTop } from '../../utils/scrollToTop';
import './Footer.css';


const ScrollArrow = () => {

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 100) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 100) {
            setShowScroll(false)
        }
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <div className="wk-footer-copyright-back2Top" style={{ display: showScroll ? 'flex' : 'none' }} onClick={() => scrollToTop()}><span wk-icon="arrow-up" wk-icon-type="filled" title="Take me to the top"></span></div>
    );
}

export default ScrollArrow;