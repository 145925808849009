import React from 'react';
import { getStateCountryTitle } from 'utils/getStateCountryTitle.js';
import { getStateQueryString } from 'utils/getStateQueryString.js';
import { selectMultipleStates, resetStates } from "state/actions/homeActions";
import { useHistory } from "react-router";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useDispatch } from "react-redux";

const Statistics = ({ selectedStateCodes, totalCount, totalCountWithEmail, searchWarning})=>{
    const history = useHistory();
    const dispatch = useDispatch();
    const submitHandler = () => {
        try {
            let queryStr = 'cs=' + getStateQueryString(selectedStateCodes);
            searchWarning(queryStr);
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }

    const submitEmailHandler = () => {
        try {
            let queryStr = 'cs=' + getStateQueryString(selectedStateCodes) + '&em=y';
            searchWarning(queryStr);
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }

    const clearHandler = () => {
        try {
            dispatch(resetStates());
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }

    const stateClickedHandler = (id, ids) => {
        try {
            if (ids.length === 1) {
                clearHandler();
            } else {
                dispatch(selectMultipleStates(ids.filter(x => x !== id)));
            }
        } catch (error) { logAndRedirectToErrorPage(error, history); }
    }
    return (
        <div className="wk-search-filter-wrap">
            <div className="statistics">
                <h4>{selectedStateCodes.length > 0 ? "States" : "US"} Statistics</h4>
                <div className="statistics-row">
                    {
                        selectedStateCodes.map(selstate => (
                            <span
                                className="wk-label state-label"
                                id={selstate}
                                key={selstate}>
                            <span wk-icon="close" onClick={() => stateClickedHandler(selstate, selectedStateCodes)}/>
                                {getStateCountryTitle(selstate)}
                            </span>
                        ))
                    }
                    {
                        selectedStateCodes.length > 0 && (<a className="link" onClick={clearHandler}>Clear All</a>)
                    }
                </div>
                <div className="statistics-row">
                    {
                        selectedStateCodes.length > 0
                            ? (<a className="wk-link" onClick={submitHandler}>Corporate Counsel
                                Count: {totalCount}</a>)
                            : (<span>Corporate Counsel Count: {totalCount}</span>)
                    }
                </div>
                <div className="statistics-row">
                    {
                        selectedStateCodes.length > 0
                            ? (<a className="wk-link" onClick={submitEmailHandler}>Counsel with E-Mail (%): {Math.round((totalCountWithEmail / totalCount) * 100)}</a>)
                            : (<span>Counsel with E-Mail (%): {Math.round((totalCountWithEmail / totalCount) * 100)}</span>)
                    }
                </div>
                {
                    selectedStateCodes.length > 0 && (<a className="wk-button" onClick={submitHandler}>Search</a>)
                }
            </div>
        </div>
    )
}

export default Statistics;
