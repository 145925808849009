import React, { useState } from 'react';
import { Switch } from 'react-router';
import Layout from './components/layout/Layout';
import { ProtectedRoute } from "./utils/ProtectedRoute";
import { get } from 'services/ApiService';

import './custom.css';
import "./print.css";
import Home from "./pages/Home";
import { Search } from "./pages/Search";
import { Read } from "./pages/Read";
import { ReadChecked } from "./pages/ReadChecked";
import { ErrorComponent } from "./components/ErrorComponent";

const App = () => {
    const [AlertUpdated, setAlertUpdated] = useState(false);
    const saveAlert = (checkedItems, recipients) => {
        let filterChecked = checkedItems.filter(item => item.checked === true);
        let alertParentCompaniesIds = filterChecked.map(item => {
            return item.fkParentCompany
        }).join();

        setAlertUpdated(false);
        get('/api/alert?alertParentCompaniesList=' + alertParentCompaniesIds + '&additionalRecipients=' + recipients)
            .then(data => {
                setAlertUpdated(true);
            });
    }

    return (
        <Layout AlertUpdated={AlertUpdated}>
            <Switch>
                <ProtectedRoute path="/" name="Home" component={Home} exact={true}/>
                <ProtectedRoute path="/search/readchecked" name="ReadChecked"
                                component={() => <ReadChecked saveAlert={saveAlert}/>} exact={true}/>
                <ProtectedRoute path="/search/read" name="Read" component={() => <Read saveAlert={saveAlert}/>}
                                exact={true}/>
                <ProtectedRoute path="/read" name="Read" component={() => <Read saveAlert={saveAlert}/>}
                                exact={true}/>
                <ProtectedRoute path="/search" name="Search" component={() => <Search saveAlert={saveAlert}/>}
                                exact={true}/>
                <ProtectedRoute path="/error" name="ErrorComponent" component={ErrorComponent}/>
            </Switch>
        </Layout>
    );

}

export default App;