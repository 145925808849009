import React from 'react';
import clsx from 'clsx';
import {connect} from "react-redux";

const Spinner = ({isLoading}) => {
    // const { isLoading } = useSelector(state=>state.spinner.isLoading);
    // console.log(isLoading);
    return (
        <div tabIndex="-1" role="dialog"
             className={clsx("wk-modal wk-modal-small wk-modal-with-overlay", {"wk-modal-opened": isLoading})}>
            <div id="spinner_sort" className="wk-spinner-center">
                <span wk-icon="spinner" type="filled" className="wk-spin wk-spin-layout"/>
            </div>
        </div>
    );

};
const mapStateToProps = state => ({
    isLoading: state.spinner.isLoading
})
export default connect(mapStateToProps,null)(Spinner);