import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import UserID from 'components/layout/UserID';
import EmailAdditionalRecipients from 'common/EmailAdditionalRecipients/EmailAdditionalRecipients';
import { useSelector } from 'react-redux';

const CreateParentAlertOverlay = ({ isVisible, selections, saveAlert, parentCompanyAlertsPresent, maxWarningCompanies }) => {

    const [checkedItems, setCheckedItems] = useState(selections); 
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [isAdditionalRecipientsDisabled, setAdditionalRecipientsDisabled] = useState(false);
    const [presentCompaniesWarning, setPresentCompaniesWarning] = useState(false);
    const [presentCompaniesMessage, setPresentCompaniesMessage] = useState('');
    const [maxCompaniesWarning, setMaxCompaniesWarning] = useState(false);
    const [maxCompaniesMessage, setMaxCompaniesMessage] = useState('');
    const [recipients, setRecipients] = useState('');
    const userInfo = useSelector(state => state.home.userInfo);

    useEffect(() => {
        if (parentCompanyAlertsPresent.length > 0) {
            let presentSelections = [];
            let presentSelectionsCompanies = [];
            parentCompanyAlertsPresent.map(i => presentSelections.push(i.parentCompanyId));
            let presentSelectionsFromSelections = selections.filter(i => presentSelections.includes(i.fkParentCompany));
            presentSelectionsFromSelections.map(i => presentSelectionsCompanies.push(i.company));
            selections = selections.filter(s => presentSelections.every(ps => ps !== s.fkParentCompany));
            setPresentCompaniesWarning(true);
            setPresentCompaniesMessage('You have already created Alert(s) for the following: ' + presentSelectionsCompanies.join('; '));
        }
        setMaxCompaniesWarning(maxWarningCompanies);
        setMaxCompaniesMessage(maxWarningCompanies ? 'You can only create 100 Alerts at a time. Only your first 100 selections appear above.' : '');
        if (selections.length == 0) { setAdditionalRecipientsDisabled(true) }
        setCheckedItems(selections);
        setDialogVisible(isVisible);
    }, [selections, parentCompanyAlertsPresent]);

    const isAnyCompanyChecked = checkedItems.filter(item => item.checked).length > 0;

    const handleChange = (e) => {
        setCheckedItems(
            checkedItems.map(item => {
                if (item.fkParentCompany == e.target.value)
                    return { ...item, checked: e.target.checked }
                return item;
            })
        )
    };

    const closeOverlay = () => {
        setRecipients('');
        setMaxCompaniesWarning(false);
        setMaxCompaniesMessage('');
        setPresentCompaniesWarning(false);
        setPresentCompaniesMessage('');
        setAdditionalRecipientsDisabled(false);
        setDialogVisible(false);
        document.getElementsByClassName("scroll-checkbox-5")[0].scrollTo(0, 0);
    }

    const syncAdditionalRecipients = (emaillist) => {
        setRecipients(emaillist)
    }
   
    return (
        <div data-testid="CreateParentAlertOverlay" tabIndex="-1" role="dialog" id="centered-form"
            className={clsx("wk-modal wk-modal-large wk-modal-vertical-middle wk-modal-with-overlay", { "wk-modal-opened": isDialogVisible })}>
            <section className="wk-modal-content">
                <header className="wk-modal-header">
                    <div className="wk-modal-title">Create Alert(s)</div>
                    <button data-testid="CreateParentAlert_Close" id="btnWarningDialog" onClick={closeOverlay} aria-label="close dialog" className="wk-button-icon wk-modal-close"><span wk-icon="close"></span></button>
                </header>
                <div className="wk-modal-body">
                    <div className={clsx("wk-field-item", { 'wk-field-has-error': presentCompaniesWarning })}>
                        <div className="wk-font-label">Company Alert Name(s)</div>
                        <div data-testid="PresentCompanies_Warning" className="wk-field-error">{presentCompaniesMessage}</div>
                    </div>
                    <div className={clsx("wk-field-item", { 'wk-field-has-error': maxCompaniesWarning })}>
                        <div className="scroll-checkbox-5" aria-label="companies" role="list">
                        { 
                            checkedItems.map(selection => (
                                <div key={selection.fkParentCompany} className="wk-checkbox-field" role="listitem">
                                    <label>
                                        <input data-testid={'cbx_' + selection.fkParentCompany} type="checkbox" value={selection.fkParentCompany} checked={selection.checked} onChange={(e) => handleChange(e)} /><span></span><span>{selection.company}</span>
                                    </label>
                                </div>                            
                            ))
                        }
                        </div>
                        <div data-testid="CreateParentAlert_Warning" className="wk-field-error"><p>{maxCompaniesMessage}</p></div>
                        <p>You can deselect any from the above list of choices by removing any checkmark.</p>
                        <p>Your selected Company Alert(s) will be sent weekly to your email account at {userInfo.Email}.</p>
                        <EmailAdditionalRecipients additionalRecipients={recipients} syncAdditionalRecipients={syncAdditionalRecipients} isAdditionalRecipientsDisabled={isAdditionalRecipientsDisabled} />
                        <p>If you agree with your selections above, please click Save. If you want to add any more selections you can click Cancel and adjust your selections.</p>
                    </div>                    
                </div>
                <footer className="wk-modal-footer">
                    <div className="wk-button-bar">
                        <div className="wk-button-group-right">
                            <button data-testid="CreateParentAlert_Cancel" className="wk-button-secondary" onClick={closeOverlay}>Cancel</button>
                            <button data-testid="CreateParentAlert_Save" onClick={() => { setDialogVisible(false); saveAlert(checkedItems, recipients); }} disabled={!isAnyCompanyChecked} >Save</button>
                        </div>
                    </div>
                </footer>
            </section>
            
        </div>
    );
}

export default CreateParentAlertOverlay;