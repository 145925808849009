import { call, put, fork, takeLatest } from "redux-saga/effects";
import {
    READCHECKED_COMPANY_DATA_REQUESTED,
    READCHECKED_LAWYER_DATA_REQUESTED,
    readcheckedCompanyDataLoaded,
    readcheckedLawyerDataLoaded,
    LAWYERS_COLLEGE_DATA_REQUESTED,
    LAWYERS_PA_DATA_REQUESTED,
    LAWYERS_TITLES_DATA_REQUESTED,
    lawyersCollegeDataRequested,
    lawyersPADataRequested,
    lawyersTitlesDataRequested,
    lawyersCollegeDataLoaded,
    lawyersPADataLoaded,
    lawyersTitlesDataLoaded,
} from 'state/actions/readCheckedActions';
import {
    getCompanyData,
    getLawyerData,
    postLawyerData,
    getCollegesByLawyerId,
    getPAsByLawyerId,
    getTitlesByLawyerId
} from 'services/readCheckedDataService';
import { spinnerChangedAction } from "../actions/spinnerActions";
import { logAndRedirectToErrorPage } from "../../components/ErrorComponent";
import { history } from "../../routerHistory";
import { AuthenticationError } from "../../common/AuthenticationError";

function* getReadCheckedCompanyResult(action) {
    try {
        const companyResult = yield call(getCompanyData, action.payload);
        yield put(readcheckedCompanyDataLoaded(companyResult));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getReadCheckedLawyerResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const readCheckedResult = yield call(postLawyerData, action.payload);
        yield put(readcheckedLawyerDataLoaded(readCheckedResult.lawyers));
        yield put(readcheckedCompanyDataLoaded(readCheckedResult.companies));
        let lawyerIds = [];
        readCheckedResult.lawyers.map(x => {
            lawyerIds.push(x.lId)
        });
        yield put(lawyersCollegeDataRequested(lawyerIds.join(',')));
        yield put(lawyersPADataRequested(lawyerIds.join(',')));
        yield put(lawyersTitlesDataRequested(lawyerIds.join(',')));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* watchReadCheckedGetCompanyResult() {
    yield takeLatest(READCHECKED_COMPANY_DATA_REQUESTED, getReadCheckedCompanyResult);
}

function* watchReadCheckedGetLawyerResult() {
    yield takeLatest(READCHECKED_LAWYER_DATA_REQUESTED, getReadCheckedLawyerResult);
}

function* getLawyersCollegeResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const readLawyersCollegeResult = yield call(getCollegesByLawyerId, action.payload);
        yield put(lawyersCollegeDataLoaded(readLawyersCollegeResult));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getLawyersPAResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const readLawyersPAResult = yield call(getPAsByLawyerId, action.payload);
        yield put(lawyersPADataLoaded(readLawyersPAResult));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* getLawyersTitlesResult(action) {
    try {
        yield put(spinnerChangedAction(true));
        const readLawyersTitlesResult = yield call(getTitlesByLawyerId, action.payload);
        yield put(lawyersTitlesDataLoaded(readLawyersTitlesResult));
        yield put(spinnerChangedAction(false));
    } catch (e) {
        yield put(spinnerChangedAction(false));
        if(e instanceof AuthenticationError){
            return;
        }
        logAndRedirectToErrorPage(e, history);
    }
}

function* watchGetLawyersCollegeResult() {
    yield takeLatest(LAWYERS_COLLEGE_DATA_REQUESTED, getLawyersCollegeResult);
}

function* watchGetLawyersPAResult() {
    yield takeLatest(LAWYERS_PA_DATA_REQUESTED, getLawyersPAResult);
}

function* watchGetLawyersTitlesResult() {
    yield takeLatest(LAWYERS_TITLES_DATA_REQUESTED, getLawyersTitlesResult);
}

export default [
    fork(watchReadCheckedGetCompanyResult),
    fork(watchReadCheckedGetLawyerResult),
    fork(watchGetLawyersCollegeResult),
    fork(watchGetLawyersPAResult),
    fork(watchGetLawyersTitlesResult),
];