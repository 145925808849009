import {
    SEARCH_DATA_LOADING,
    SEARCH_DATA_LOADED,
    SEARCH_CURRENT_MAIN_RESULTS_LOADED,
    PRACTICEAREA_DATA_LOADED,
    PRACTICEAREA_CURRENT_MAIN_RESULTS_LOADED,
    YEARSADMITEDTOBAR_DATA_LOADED,
    YEARSADMITEDTOBAR_CURRENT_MAIN_RESULTS_LOADED,
    COLLEGES_DATA_LOADED,
    COLLEGES_CURRENT_MAIN_RESULTS_LOADED,
    FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS,
    FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS_ALL,
    RESET_ALL_SEARCH_DATA,
    FILTER_BY_MAIN_RESULTS_BY_YEAR,
    SET_SEARCH_PAGE,
    SELECT_SEARCH_ITEM,
    RESET_SELECT_SEARCH_ITEM,
    SET_SELECT_ALL,
    SEARCH_RESET_INITIAL_STATE,
    SET_BARCHART_PAGE,
    RESET_BARCHART_PAGE,
    SEARCH_DATA_SORTBY,
    PRACTICEAREA_DATA_BY_SEARCH_LOADED,
    COLLEGES_DATA_BY_SEARCH_REQUEST_LOADED,
    YEARSADMITEDTOBAR_DATA_BY_SEARCH_LOADED
} from "../actions/searchActions";
import { lawyerCount } from 'pages/utils/lawyerCount';
import { getCurrentMainResultsSortBy } from 'pages/utils/searchResultSort';

const initialState = {
    searchPage: 1,
    allMainResults: [],
    currentMainResults: [],
    allPracticeAreasResults: [],
    currentPracticeAreasResults: [],
    allYearsAdmitedtoBarResults: [],
    currentYearsAdmitedtoBarResults: [],
    allYearsCollegesResults: [],
    currentYearsCollegesResults: [],
    cityBiData: [],
    statesBiData: [],
    barChartPage: 1,
}
export default function (state = initialState, action) {
    switch (action.type) {
        case SEARCH_DATA_LOADED:
            const cityBiData = lawyerCount(action.payload, "city", 0);
            const statesBiData = lawyerCount(action.payload, "lookupStateProvince", 0);
            return { ...state, allMainResults: action.payload, cityBiData, statesBiData };
        case RESET_ALL_SEARCH_DATA:
            const allResultSet = getCurrentMainResultsSortBy(state.allMainResults, action.sortBy);
            return {
                ...state,
                searchPage: 1,
                currentMainResults: allResultSet,
                currentPracticeAreasResults: [...state.allPracticeAreasResults],
                currentYearsAdmitedtoBarResults: [...state.allYearsAdmitedtoBarResults],
                currentYearsCollegesResults: [...state.allYearsCollegesResults],
                cityBiData: lawyerCount(state.allMainResults, "city", 0),
                statesBiData: lawyerCount(state.allMainResults, "lookupStateProvince", 0)
            };
        case SEARCH_CURRENT_MAIN_RESULTS_LOADED:
            return {
                ...state,
                searchPage: 1,
                currentMainResults: action.payload
            };
        case SEARCH_DATA_LOADING:
            return { ...state, isLoading: action.loading };
        case PRACTICEAREA_DATA_BY_SEARCH_LOADED:
        case PRACTICEAREA_DATA_LOADED:
            return { ...state, allPracticeAreasResults: action.payload };
        case PRACTICEAREA_CURRENT_MAIN_RESULTS_LOADED:
            return { ...state, currentPracticeAreasResults: action.payload };
        case YEARSADMITEDTOBAR_DATA_BY_SEARCH_LOADED:
        case YEARSADMITEDTOBAR_DATA_LOADED:
            return { ...state, allYearsAdmitedtoBarResults: action.payload };
        case YEARSADMITEDTOBAR_CURRENT_MAIN_RESULTS_LOADED:
            return { ...state, currentYearsAdmitedtoBarResults: action.payload };
        case COLLEGES_DATA_BY_SEARCH_REQUEST_LOADED:
        case COLLEGES_DATA_LOADED:
            return { ...state, allYearsCollegesResults: action.payload };
        case COLLEGES_CURRENT_MAIN_RESULTS_LOADED:
            return { ...state, currentYearsCollegesResults: action.payload };
        case FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS:
            const filteredMainResults = state.allMainResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) > -1)
            const filteredColleges = state.currentYearsCollegesResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            const filteredPracticeAreas = state.currentPracticeAreasResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            const filteredYearAdmittedToBar = state.currentYearsAdmitedtoBarResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lid) !== -1);
            return {
                ...state,
                searchPage: 1,
                currentMainResults: filteredMainResults,
                currentYearsCollegesResults: filteredColleges,
                currentYearsAdmitedtoBarResults: filteredYearAdmittedToBar,
                currentPracticeAreasResults: filteredPracticeAreas,
                cityBiData: lawyerCount(filteredMainResults, "city", 0),
                statesBiData: lawyerCount(filteredMainResults, "lookupStateProvince", 0)
            };
        case FILTER_BY_MAIN_RESULTS_BY_LAWYER_IDS_ALL:
            let filteredMainResultsAll = state.allMainResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) > -1)
            const filteredCollegesAll = state.allYearsCollegesResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            const filteredPracticeAreasAll = state.allPracticeAreasResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            const filteredYearAdmittedToBarAll = state.allYearsAdmitedtoBarResults.filter(item => action.lawyerIds.findIndex(lawyerId => lawyerId === item.lid) !== -1);
            filteredMainResultsAll = getCurrentMainResultsSortBy(filteredMainResultsAll, action.sortBy);
            return {
                ...state,
                searchPage: 1,
                currentMainResults: filteredMainResultsAll,
                currentYearsCollegesResults: filteredCollegesAll,
                currentYearsAdmitedtoBarResults: filteredYearAdmittedToBarAll,
                currentPracticeAreasResults: filteredPracticeAreasAll,
                cityBiData: lawyerCount(filteredMainResultsAll, "city", 5),
                statesBiData: lawyerCount(filteredMainResultsAll, "lookupStateProvince", 5)
            };
        case FILTER_BY_MAIN_RESULTS_BY_YEAR:
            const filteredYearAdmittedToBarByYear = state.allYearsAdmitedtoBarResults.filter(item => action.year === item.attbr);
            const lawyerIds = filteredYearAdmittedToBarByYear.map(item => item.lid);
            const filteredMainResultsByYear = state.allMainResults.filter(item => lawyerIds.findIndex(lawyerId => lawyerId === item.lawyerId) > -1)
            const filteredCollegesByYear = state.allYearsCollegesResults.filter(item => lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            const filteredPracticeAreasByYear = state.allPracticeAreasResults.filter(item => lawyerIds.findIndex(lawyerId => lawyerId === item.lId) !== -1);
            return {
                ...state,
                currentMainResults: filteredMainResultsByYear,
                currentYearsCollegesResults: filteredCollegesByYear,
                currentYearsAdmitedtoBarResults: filteredYearAdmittedToBarByYear,
                currentPracticeAreasResults: filteredPracticeAreasByYear,
                cityBiData: lawyerCount(filteredMainResultsByYear, "city", 5),
                statesBiData: lawyerCount(filteredMainResultsByYear, "lookupStateProvince", 5)
            };
        case SET_SEARCH_PAGE:
            return { ...state, searchPage: action.payload };
        case SELECT_SEARCH_ITEM:
            const currentResults = state.currentMainResults;
            return {
                ...state, currentMainResults: currentResults.map((item, index) => {
                    if (item.lawyerId !== action.lId) {
                        return item
                    }
                    return {
                        ...item,
                        checked: action.checked
                    }
                })
            };
        case RESET_SELECT_SEARCH_ITEM:
            return {
                ...state, currentMainResults: state.currentMainResults.map((item, index) => {
                    if (item.checked) {
                        return {
                            ...item,
                            checked: false
                        }
                    }
                    return item;
                })
            };
        case SET_SELECT_ALL:
            return {
                ...state, currentMainResults: state.currentMainResults.map((item, index) => {
                    return {
                        ...item,
                        checked: action.selectAll
                    }
                })
            };
        case SEARCH_RESET_INITIAL_STATE:
            return {
                ...initialState
            };
        case SET_BARCHART_PAGE:
            return { ...state, barChartPage: action.payload };
        case RESET_BARCHART_PAGE:
            return {
                ...state, barChartPage: 1,
            };
        case SEARCH_DATA_SORTBY:
            let currentMainResultsSortBy = getCurrentMainResultsSortBy(state.currentMainResults, action.val);
            return {
                ...state,
                searchPage: 1,
                currentMainResults: currentMainResultsSortBy             
            };
        default:
            return state;
    }
}
