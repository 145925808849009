import React from 'react';
import clsx from 'clsx';

const EmailAdditionalRecipientsConfirm = ({ isVisible, closeDialog, YesClicked }) => {
    return (
        <div data-testid="EmailAdditionalRecipientsConfirm" tabIndex="-1" role="dialog" id="confirm-centered-small-content" className={clsx("wk-modal wk-modal-small wk-modal-vertical-middle wk-modal-with-overlay wk-modal-small-content", { "wk-modal-opened": isVisible })}>
            <section className="wk-modal-content wk-modal-content-layout wk-modal-delete-confirm" style={{ width: 450}}>
                <header className="wk-modal-header">
                    <h4 className="wk-modal-title">Confirm Deletion</h4>
                    <button id="btnManageAlertsConfirmOverlay" aria-label="close dialog" className="wk-button-icon wk-modal-close wk-overlay-button" onClick={closeDialog}><span wk-icon="close"></span></button>
                </header>
                <div className="wk-modal-body">
                    <p><span className="ui-icon ui-icon-alert"> </span> Are you sure?</p>
                </div>
                <footer className="wk-modal-footer wk-modal-delete-confirm-footer">
                    <div className="wk-button-bar wk-modal-bar">
                        <div className="wk-button-group-right">
                            <button data-testid="EmailAdditionalRecipientsConfirm_No" className="wk-button-secondary" onClick={closeDialog} type="button">No</button>
                            <button data-testid="EmailAdditionalRecipientsConfirm_Yes" type="submit" onClick={YesClicked}>Yes</button>
                        </div>
                    </div >
                </footer >
            </section >
        </div >
    );
}
export default EmailAdditionalRecipientsConfirm