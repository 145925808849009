import { get, post } from "./ApiService";
import { prepareQueryString } from "utils/prepareQueryString";

export const getCollegesBySearch = async payload => {
    let url = 'api/colleges/byBISRPSearch?';
    return await get(prepareQueryString(url, payload));
};
export const getCollegesByLawyerId = async lawyerIds => {
    let url = 'api/colleges/byLawyerId';
    return await post(url, {lawyerIds});
};