export function scrollToTop(withoutScrollEffect) {
    if (withoutScrollEffect) {
        window.scroll({
            top: 0,
            left: 0            
        });
    } else {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

}