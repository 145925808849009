import React from 'react';
import clsx from 'clsx';

const WaringDialog = ({ isVisible, header, message, closeDialog }) => {

    return (
        <div id="overlay" tabIndex="-1" role="dialog" className={clsx("wk-modal wk-modal-small wk-modal-vertical-middle wk-modal-with-overlay", { "wk-modal-opened": isVisible })}>
            <section className="wk-modal-content">
                <header className="wk-modal-header">
                    <div className="wk-modal-title">{header}</div>
                    <button id="btnWarningDialog" onClick={closeDialog} aria-label="close dialog" className="wk-button-icon wk-modal-close"><span wk-icon="close"></span></button>
                </header>
                <div className="wk-modal-body">
                    <p>{message}</p>
                </div>
                <footer className="wk-modal-footer">
                    <div className="wk-button-bar">
                        <div className="wk-button-group-right">
                            <button onClick={closeDialog}>Close</button>
                        </div>
                    </div>
                </footer>
            </section>
        </div>
    );
};

export default WaringDialog;
