export const COMPANY_DATA_REQUESTED = "COMPANY_DATA_REQUESTED";
export const COMPANY_DATA_LOADED = "COMPANY_DATA_LOADED";
export const COMPANYLAWYERS_DATA_REQUESTED = "COMPANYLAWYERS_DATA_REQUESTED";
export const COMPANYLAWYERS_DATA_LOADED = "COMPANYLAWYERS_DATA_LOADED";
export const FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS = "FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS";
export const LAWYER_DATA_REQUESTED = "LAWYER_DATA_REQUESTED";
export const LAWYER_DATA_LOADED = "LAWYER_DATA_LOADED";
export const UPDATE_COMPANYLAWYERS_DATA = "UPDATE_COMPANYLAWYERS_DATA";
export const COMPANY_COLLEGE_DATA_REQUESTED = "COMPANY_COLLEGE_DATA_REQUESTED";
export const COMPANY_COLLEGE_DATA_LOADED = "COMPANY_COLLEGE_DATA_LOADED";
export const COMPANY_PA_DATA_REQUESTED = "COMPANY_PA_DATA_REQUESTED";
export const COMPANY_PA_DATA_LOADED = "COMPANY_PA_DATA_LOADED";
export const COMPANY_TITLE_DATA_REQUESTED = "COMPANY_TITLE_DATA_REQUESTED";
export const COMPANY_TITLE_DATA_LOADED = "COMPANY_TITLE_DATA_LOADED";
export const PARENTCOMPANY_DATA_REQUESTED = "PARENTCOMPANY_DATA_REQUESTED";
export const PARENTCOMPANY_DATA_LOADED = "PARENTCOMPANY_DATA_LOADED";
export const PARENTCOMPANYLAWYERS_DATA_REQUESTED = "PARENTCOMPANYLAWYERS_DATA_REQUESTED";
export const PARENTCOMPANYLAWYERS_DATA_LOADED = "PARENTCOMPANYLAWYERS_DATA_LOADED";
export const RBSFILINGS_DATA_REQUESTED = "RBSFILINGS_DATA_REQUESTED";
export const RBSFILINGS_DATA_LOADED = "RBSFILINGS_DATA_LOADED";
export const RESET_ALL_READ_DATA = "RESET_ALL_READ_DATA";
export const READ_RESET_INITIAL_STATE = "READ_RESET_INITIAL_STATE";

export const parentCompanyDataRequested = (payload) => ({
    type: PARENTCOMPANY_DATA_REQUESTED,
    payload
});
export const parentCompanyDataLoaded = (payload) => ({
    type: PARENTCOMPANY_DATA_LOADED,
    payload
});
export const updateCompanyLawyersData = (payload) => ({
    type: UPDATE_COMPANYLAWYERS_DATA,
    payload
});
export const companyDataRequested = (payload) => ({
    type: COMPANY_DATA_REQUESTED,
    payload
});
export const companyDataLoaded = (payload) => ({
    type: COMPANY_DATA_LOADED,
    payload
});
export const companyLawyersDataRequested = (payload) => ({
    type: COMPANYLAWYERS_DATA_REQUESTED,
    payload
});
export const companyLawyersDataLoaded = (payload) => ({
    type: COMPANYLAWYERS_DATA_LOADED,
    payload
});
export const parentCompanyLawyersDataRequested = (payload) => ({
    type: PARENTCOMPANYLAWYERS_DATA_REQUESTED,
    payload
});
export const parentCompanyLawyersDataLoaded = (payload) => ({
    type: PARENTCOMPANYLAWYERS_DATA_LOADED,
    payload
});
export const filterAllCompanyResultsByLawyerIds = (lawyerIds) => ({
    type: FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS,
    lawyerIds
});
export const lawyerDataRequested = (payload) => ({
    type: LAWYER_DATA_REQUESTED,
    payload
});
export const lawyerDataLoaded = (payload) => ({
    type: LAWYER_DATA_LOADED,
    payload
});
export const companyCollegeDataRequested = (parentCompanyId, companyId, lawyerId) => ({
    type: COMPANY_COLLEGE_DATA_REQUESTED,
    companyId,
    lawyerId,
    parentCompanyId
});
export const companyCollegeDataLoaded = (payload) => ({
    type: COMPANY_COLLEGE_DATA_LOADED,
    payload
});
export const companyPaDataRequested = (parentCompanyId, companyId, lawyerId) => ({
    type: COMPANY_PA_DATA_REQUESTED,
    parentCompanyId,
    companyId,
    lawyerId
});
export const companyPaDataLoaded = (payload) => ({
    type: COMPANY_PA_DATA_LOADED,
    payload
});
export const companyTitleDataRequested = (parentCompanyId, companyId, lawyerId) => ({
    type: COMPANY_TITLE_DATA_REQUESTED,
    parentCompanyId,
    companyId,
    lawyerId
});
export const companyTitleDataLoaded = (payload) => ({
    type: COMPANY_TITLE_DATA_LOADED,
    payload
});
export const RBSFilingsDataRequested = (payload) => ({
    type: RBSFILINGS_DATA_REQUESTED,
    payload
});
export const RBSFilingsDataLoaded = (payload) => ({
    type: RBSFILINGS_DATA_LOADED,
    payload
});
export const resetAllReadData = () => ({
    type: RESET_ALL_READ_DATA
});
export const resetReadToInitialState = () => ({
    type: READ_RESET_INITIAL_STATE
})