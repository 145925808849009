export const RADIUS_DATA = { radiusLarge: 40, radiusMedium: 30, radiusSmall: 20 };
export const RADIUS_DATA_INDEX = { radiusLargeIndex: 0, radiusMediumIndex: 5, radiusSmallIndex: 10 };
export const LABEL_HEIGHT = { labelLargeHeight: 80, labelMediumHeight: 60, labelSmallHeight: 40 };
export const BI_TITLE = ["ALL STATES", "ALL CITIES", "ALL YEARS ADMITTED to BAR", "ALL PRACTICE AREAS", "ALL COLLEGES"];
export const BI_LABEL = ["State", "City", "Year Admitted to Bar", "Practice Area", "College"];
export const BI_READ_VIEW_CHECKED_TITLE = ["ALL COLLEGES", "ALL PRACTICE AREAS", "ALL TITLES"];
export const BI_READ_VIEW_CHECKED_LABEL = ["College", "Practice Area", "Title"];

export const REPORTS_TYPE = ["SEC Filings:", "Annual Reports (10-K, 20-F)", "Quarterly Reports (10-Q)", "Periodic Reports (8-K, 6-K)"]
export const READ_LAWYER_LABELS = { Practice_Areas: "Practice Area(s): ", Joined_Firm: "Joined Firm: ", US_State_Non_US_Bars: "US State and/or Non-US Bar(s): ", Education: "Education: ", Previous_Positions: "Previous Positions: ", Memberships_Affiliations: "Memberships/Affiliations: ", Telephone_Number: "Telephone Number: " }

export const MANAGE_ALERTS_OPTIONS = { CreatedOn_Value: "1", AlertName_Value: "2", ModifiedOn_Value: "3", ModifiedOn_Label: "Modified On", CreatedOn_Label: "Created On", AlertName_Label: "Alert Name" }
export const SEARCH_RESULT_OPTIONS = { Company_Name_Value: "1", Subsidiary_Name_Value: "2", Attorneys_last_name_Value: "3", Company_City_Value: "4", Company_State_Value: "5", Company_Name_Label: "Company Name", Subsidiary_Name_Label: "Subsidiary Name", Attorneys_last_name_Label: "Attorney’s Last Name", Company_City_Label: "Company City", Company_State_Label: "Company State" }
export const PAGINATION_ACTION = { PrevPage: "1", nextPage: "2", selectPage: "3"}
export const EMAIL_ADDITIONAL_ECIPENTS_MODE = { Default: "1", Add: "2", Edit: "3"}

export default { RADIUS_DATA, RADIUS_DATA_INDEX, LABEL_HEIGHT, BI_TITLE, BI_LABEL, BI_READ_VIEW_CHECKED_TITLE, BI_READ_VIEW_CHECKED_LABEL, REPORTS_TYPE, READ_LAWYER_LABELS };

export const chartPageSize = 500;