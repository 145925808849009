export const READCHECKED_COMPANY_DATA_REQUESTED = "READCHECKED_COMPANY_DATA_REQUESTED";
export const READCHECKED_COMPANY_DATA_LOADED = "READCHECKED_COMPANY_DATA_LOADED";
export const READCHECKED_LAWYER_DATA_REQUESTED = "READCHECKED_LAWYER_DATA_REQUESTED";
export const READCHECKED_LAWYER_DATA_LOADED = "READCHECKED_LAWYER_DATA_LOADED";
export const READCHECKED_FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS = "READCHECKED_FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS";
export const LAWYERS_COLLEGE_DATA_REQUESTED = "LAWYERS_COLLEGE_DATA_REQUESTED";
export const LAWYERS_COLLEGE_DATA_LOADED = "LAWYERS_COLLEGE_DATA_LOADED";
export const LAWYERS_PA_DATA_REQUESTED = "LAWYERS_PA_DATA_REQUESTED";
export const LAWYERS_PA_DATA_LOADED = "LAWYERS_PA_DATA_LOADED";
export const LAWYERS_TITLES_DATA_REQUESTED = "LAWYERS_TITLES_DATA_REQUESTED";
export const LAWYERS_TITLES_DATA_LOADED = "LAWYERS_TITLES_DATA_LOADED";
export const READCHECKED_RESET_ALL_READ_DATA = "READCHECKED_RESET_ALL_READ_DATA";
export const READCHECKED_SET_SELECT_ALL = "READCHECKED_SET_SELECT_ALL";
export const READCHECKED_UPDATE_COMPANY_LAWYERS_DATA = "READCHECKED_UPDATE_COMPANY_LAWYERS_DATA";
export const READCHECKED_RESET_INITIAL_STATE = "READCHECKED_RESET_INITIAL_STATE";

export const readcheckedCompanyDataRequested = (payload) => ({
    type: READCHECKED_COMPANY_DATA_REQUESTED,
    payload
});
export const readcheckedCompanyDataLoaded = (payload) => ({
    type: READCHECKED_COMPANY_DATA_LOADED,
    payload
});
export const readcheckedLawyerDataRequested = (payload) => ({
    type: READCHECKED_LAWYER_DATA_REQUESTED,
    payload
});
export const readcheckedLawyerDataLoaded = (payload) => ({
    type: READCHECKED_LAWYER_DATA_LOADED,
    payload
});
export const lawyersCollegeDataRequested = (payload) => ({
    type: LAWYERS_COLLEGE_DATA_REQUESTED,
    payload
});
export const lawyersCollegeDataLoaded = (payload) => ({
    type: LAWYERS_COLLEGE_DATA_LOADED,
    payload
});
export const lawyersPADataRequested = (payload) => ({
    type: LAWYERS_PA_DATA_REQUESTED,
    payload
});
export const lawyersPADataLoaded = (payload) => ({
    type: LAWYERS_PA_DATA_LOADED,
    payload
});
export const lawyersTitlesDataRequested = (payload) => ({
    type: LAWYERS_TITLES_DATA_REQUESTED,
    payload
});
export const lawyersTitlesDataLoaded = (payload) => ({
    type: LAWYERS_TITLES_DATA_LOADED,
    payload
});
export const readcheckedfilterAllCompanyResultsByLawyerIds = (lawyerIds) => ({
    type: READCHECKED_FILTER_ALL_COMPANY_RESULTS_BY_LAWYER_IDS,
    lawyerIds
});
export const readcheckedresetAllReadData = () => ({
    type: READCHECKED_RESET_ALL_READ_DATA
});
export const setReadCheckedSelectAll = readCheckedSelectAll => ({
    type: READCHECKED_SET_SELECT_ALL,
    readCheckedSelectAll
});
export const updateCheckedCompanyLawyersData = (payload) => ({
    type: READCHECKED_UPDATE_COMPANY_LAWYERS_DATA,
    payload
});
export const resetReadCheckedToInitialState = () => ({
    type: READCHECKED_RESET_INITIAL_STATE
})
