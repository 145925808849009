import React, { useEffect, useState } from 'react';
import dayjs from "dayjs";

const ManageAlertsItem = ({ item, onChange, deleteManageAlerts, index, editAlert }) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [showEditTooltip, setShowEditTooltip] = useState(false);
    const onMouseOverHandler = () => {
        setShowTooltip(true);
    };

    const onMouseOutHandler = () => {
        setShowTooltip(false);
    };
    const onEditMouseOverHandler = () => {
        setShowEditTooltip(true);
    };

    const onEditMouseOutHandler = () => {
        setShowEditTooltip(false);
    };

    return (
        <div className="wk-row manage-alerts-list-item">
            <div className="wk-col-5">
                <div className="wk-layout-50-50">
                    <div>
                        <span className="manage-alerts-checkbox-margin">
                            <input data-testid={'cbx_' + item.alertId} id={'cbx_' + item.alertId} type="checkbox" onChange={(e) => onChange(e, item.alertId)} />
                        </span>
                        <a className="wk-link manage-alerts-link"><span data-testid={'company_' + index}>{item.company}</span></a>
                    </div>
                </div>
            </div>
            <div className="wk-col-7">
                <div className="wk-layout-25-25-25-25">
                    <div className="wk-layout-item">
                        <span>{dayjs(item.createdon).format("MMM D, YYYY")}</span>
                    </div>
                    <div className="wk-layout-item">
                        <span>{dayjs(item.modifiedon).format("MMM D, YYYY")}</span>
                    </div>                   
                    <div className="manage-alerts-delete-icon">
                        <span data-testid={item.alertId } id={item.alertId} className="manage-alerts-close-icon" wk-icon="close" wk-icon-type="filled" onClick={deleteManageAlerts} onMouseEnter={onMouseOverHandler} onMouseLeave={onMouseOutHandler}></span>
                    </div>
                    <div className="manage-alerts-delete-tooltip">
                        <div className="wk-tooltip manage-alerts-tooltip-right favorites-folder-tooltip" style={{ marginTop: -2, display: showTooltip ? "inline-block" : "none" }}>
                            <div className="wk-tooltip-body favorites-tooltip-message" >delete</div>
                        </div>
                    </div>
                    <div className="manage-alerts-edit-icon">
                        <span data-testid={"edit-alert-" + item.alertId}  className="manage-alerts-pencil-icon" wk-icon="pencil" wk-icon-type="filled" onClick={()=>editAlert(item)} onMouseEnter={onEditMouseOverHandler} onMouseLeave={onEditMouseOutHandler}></span>
                    </div>
                    <div data-testid={"edit-alert-tooltip-" + item.alertId} className="manage-alerts-edit-tooltip">
                        <div className="wk-tooltip manage-alerts-tooltip-right favorites-folder-tooltip" style={{ marginTop: -2, display: showEditTooltip ? "inline-block" : "none" }}>
                            <div className="wk-tooltip-body favorites-tooltip-message" >edit</div>
                        </div>
                    </div>
                   

                </div>
            </div>
        </div>
    );
}

export default ManageAlertsItem




