export default function* color(end) {
    const colorSet = [
        '#e5202e',
        '#007ac3',
        '#85bc20',
        '#474747',
        '#009881',
        '#ea8f00',
        '#940c72',
        '#241866',
        '#ec5862',
        '#409bd2',
        '#a4cd58',
        '#757575',
        '#efab40',
        '#5b528c',
        '#af4995',
        '#40b2a1',
        '#f29097',
        '#80bde1',
        '#c2de90',
        '#a3a3a3',
        '#f5c780',
        '#928cb3',
        '#ca86b9',
        '#80ccc0',
        '#f6b1b6',
        '#a6d1ea',
        '#d4e8b1',
        '#bfbfbf',
        '#f8d8a6',
        '#b3aeca',
        '#daaace',
        '#a6dbd3'
    ]
    for (let i = 0; i < end; i++) {
        yield colorSet[i % colorSet.length];
    }
}
