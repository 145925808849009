import { get } from "./ApiService";

export const getTopCities = async states => {
    let url = `/api/Statistics/GetTopCities?top=5&states=${states}`;
    return await get(url);
}
export const getTopColleges = async states => {
    let url = `/api/statistics/colleges?top=5&states=${states}`;
    return await get(url);
};
export const getCounselCountByState = async states => {
    let url = `/api/Statistics/GetStatesCount?top=5&stateCodes=${states}`;
    return await get(url);
};
export const getTopPracticeAreas = async states => {
    let url = `/api/statistics/practiceareas?top=5&states=${states}`;
    return await get(url);
};
export const getTopYearAdmittedToBars = async states => {
    let url = `/api/statistics/yearAdmittedtobar?top=5&states=${states}`;
    return await get(url);
};
export const getFormCompanyStates = async () => {
    let url = `/api/SearchForm/searchCompanyStates/`;
    return await get(url);
};
export const getFormPracticeAreas = async () => {
    let url = `/api/SearchForm/SearchPracticeAreas/`;
    return await get(url);
};
export const getFormUsStateBars = async () => {
    let url = `/api/SearchForm/SearchUSStateBar/`;
    return await get(url);
};
export const getFormNonUsStateBars = async () => {
    let url = `/api/SearchForm/SearchNonUSStateBar/`;
    return await get(url);
};
export const getTotalCounselCount = async selectedStates => {
    const states = selectedStates.length > 0 ? selectedStates : 'US';
    let url = `/api/Statistics/GetTotalCount?stateCodes=${states}`;
    return await get(url);
};
export const getTotalCounselEmailCount = async selectedStates => {
    const states = selectedStates.length > 0 ? selectedStates : 'US';
    let url = `/api/Statistics/GetTotalCountWithEmail?stateCodes=${states}`;
    return await get(url);
};
export const getUserInfo = async () => {
    let url = `/account/userinfo`;
    return await get(url);
};
export const getRssFeed = async () => {
    let url = `api/rss/rssfeed`;
    return await get(url);
};
export const getProducts = async () => {
    let url = `/account/userproducts`;
    return await get(url);
};