import React, { Component, useState, useEffect } from 'react';
import { NavMenu } from './NavMenu';
import Footer from "./Footer";
import  Header  from "./Header";
import Ticker from '../Ticker';
import ScrollArrow from './ScrollArrow';
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorComponent, logError } from 'components/ErrorComponent';
const Layout = ({ AlertUpdated, children}) => {
    const [LayoutAlertUpdated, setLayoutAlertUpdated] = useState(false);

    useEffect(() => {
        setLayoutAlertUpdated(false);
        if (AlertUpdated) {
            setLayoutAlertUpdated(AlertUpdated);
        }     
    }, [AlertUpdated]);

        return (
            <div>
                <Header AlertUpdated={LayoutAlertUpdated} />
                <NavMenu />
                <Ticker />
                <br />
                <ErrorBoundary FallbackComponent={ErrorComponent} onError={logError}>
                    <div className="wk-row">
                        {children}
                    </div>
                </ErrorBoundary>
                <Footer />
                <ScrollArrow />
            </div>
        );
}

export default Layout;
