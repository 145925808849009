import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useSelector, useDispatch } from "react-redux";
import color from "../../utils/color";
import { HorizontalBarchart } from "components/charts/HorizontalBarchart";
import { lawyerCount } from '../utils/lawyerCount';
import { getLawyerCount } from '../utils/lawyerYATBCount';
import { getLawyerPACount } from '../utils/lawyerPACount';
import { getLawyerCollegeCount } from '../utils/lawyerCollegeCount';
import _, { result } from "lodash";
import PaginationBarChartV1 from './PaginationBarChartV1';
import { setBarChartPage, selectSearchItem } from "state/actions/searchActions";
import { scrollToTop } from '../../utils/scrollToTop';
import { chartPageSize } from '../utils/common';

const SRPAllBIs = ({ currentResult, selectedBIIndex, filterByLawyerIds, isPieChart, currentBarChartPage }) => {
    const [stats, setStats] = useState([]);
    const [rstAll, setrstAll] = useState([]); 
    const [BIIndexs, setBIIndexs] = useState([]);
    const [setPage, setSelectedPage] = useState(0);
    const [paginationAction, setPaginationAction] = useState('');

    const dispatch = useDispatch();
    const paginate = (pagNumber) => {
        dispatch(setBarChartPage(pagNumber));
        setSelectedPage(pagNumber);
        setPaginationAction(paginationAction);
    };

    const pageSize = chartPageSize;

    useEffect(() => {
        if (currentResult) {
            let resultAll = [];
            let resultPage = [];
            let resultNotSorted = [];
            let indexs = [];

            switch (selectedBIIndex) {
                case 0:
                    resultAll = lawyerCount(currentResult, "lookupStateProvince", 0);
                     /* keep the code for Bubble Chart
                    resultNotSorted = lawyerCount(currentResult, "lookupStateProvince", 0, false);
                    resultAll.forEach((item, index) => {
                        indexs.push(resultNotSorted.findIndex(i => i.data == item.data));
                     });*/
                    break;
                case 1:
                    resultAll = lawyerCount(currentResult, "city", 0);
                     /* keep the code for Bubble Chart
                    resultNotSorted = lawyerCount(currentResult, "city", 0, false);
                    resultAll.forEach((item, index) => {
                        indexs.push(resultNotSorted.findIndex(i => i.data == item.data));
                    });*/
                    break;
                case 2:
                    resultAll = getLawyerCount(currentResult, 0);
                     /* keep the code for Bubble Chart
                    resultNotSorted = getLawyerCount(currentResult, 0, false);
                    resultAll.forEach((item, index) => {
                        indexs.push(resultNotSorted.findIndex(i => i.year == item.year));
                    });*/
                    break;
                case 3:
                    resultAll = getLawyerPACount(currentResult, 0);
                     /* keep the code for Bubble Chart
                    resultNotSorted = getLawyerPACount(currentResult, 0, false);
                    resultAll.forEach((item, index) => {
                        indexs.push(resultNotSorted.findIndex(i => i.desc == item.desc));
                    });*/
                   
                    break;
                case 4:
                    resultAll = getLawyerCollegeCount(_.cloneDeep(currentResult), 0);
                    /* keep the code for Bubble Chart
                    resultNotSorted = getLawyerCollegeCount(_.cloneDeep(currentResult), 0, false);
                    resultAll.forEach((item, index) => {
                        indexs.push(resultNotSorted.findIndex(i => i.college == item.college));
                    });*/
                  
                    break;
                default:
                    resultAll = []; resultPage = [];
            }

            if (!isPieChart) {
                let lastIndex = currentBarChartPage * pageSize;
                let firstIndex = lastIndex - pageSize;
                resultPage = resultAll.slice(firstIndex, lastIndex);
                setStats(resultPage);
            }
            else {
                setStats(resultAll);
            }            
            setrstAll(resultAll);
            setBIIndexs(indexs);
        }
        let ele = document.getElementById("BIOverlayBody");
        if (ele) ele.scrollTo(0, 0);

    }, [currentResult, currentBarChartPage]);

    return (
      
        <div
            id="psfAllBIs"  
        >
            {!isPieChart &&
                <div className="search-header sticky" >
                <PaginationBarChartV1 totalRecords={rstAll.length} pageSize={pageSize}
                    currentPage={currentBarChartPage} paginate={paginate} setPage={setPage} paginationAction={paginationAction}/>
                </div>
            }

            {isPieChart && selectedBIIndex == 0 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="States"
                data={stats.map(item => item.value)}
                labels={stats.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_OVERLAY_COMPANY_STATES_CHART)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 0 && <HorizontalBarchart
                selectedBIIndex={selectedBIIndex}
                label="States"
                data={stats.map(item => item.value)}
                labels={stats.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_OVERLAY_COMPANY_STATES_CHART)}
                backgroundColors={[...color(stats.length)]} />}
            {isPieChart && selectedBIIndex == 1 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Cities"
                data={stats.map(item => item.value)}
                labels={stats.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_OVERLAY_COMPANY_CITIES_CHART)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 1 && <HorizontalBarchart
                label="Cities"
                data={stats.map(item => item.value)}
                labels={stats.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_OVERLAY_COMPANY_CITIES_CHART)}
                backgroundColors={[...color(stats.length)]} />}
            {isPieChart && selectedBIIndex == 2 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Year Admitted to Bar"
                data={stats.map(item => item.relevance)}
                labels={stats.map(item => item.year)}
                ref={registerTestObject(BI.PSF_OVERLAY_YEARS_ADMITTED_TO_BAR_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 2 && <HorizontalBarchart
                label="Year Admitted to Bar"
                data={stats.map(item => item.relevance)}
                labels={stats.map(item => item.year)}
                ref={registerTestObject(BI.PSF_OVERLAY_YEARS_ADMITTED_TO_BAR_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {isPieChart && selectedBIIndex == 3 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.desc)}
                ref={registerTestObject(BI.PSF_OVERLAY_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 3 && <HorizontalBarchart
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.desc)}
                ref={registerTestObject(BI.PSF_OVERLAY_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {isPieChart && selectedBIIndex == 4 && <Piechart
                selectedBIIndex={selectedBIIndex}
                indexs={BIIndexs}
                label="Colleges"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.college)}
                ref={registerTestObject(BI.PSF_OVERLAY_COLLEGES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
            {!isPieChart && selectedBIIndex == 4 &&
                <HorizontalBarchart
                label="Colleges"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.college)}
                ref={registerTestObject(BI.PSF_OVERLAY_COLLEGES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]} />}
        </div>
    );
};
export default SRPAllBIs;
