

export function getStateName(state) {

    var statename = "";

    switch (state) {
        case 'ME':
            statename = 'Maine';
            break;
        case 'MA':
            statename = 'Massachusetts';
            break;
        case 'NH':
            statename = 'New Hampshire';
            break;
        case 'PR':
            statename = 'Puerto Rico';
            break;
        case 'RI':
            statename = 'Rhode Island';
            break;
        case 'CT':
            statename = 'Connecticut';
            break;
        case 'NY':
            statename = 'New York';
            break;
        case 'VT':
            statename = 'Vermont';
            break;
        case 'DE':
            statename = 'Delaware';
            break;
        case 'NJ':
            statename = 'New Jersey';
            break;
        case 'PA':
            statename = 'Pennsylvania';
            break;
        case 'VI':
            statename = 'Virgin Islands';
            break;
        case 'MD':
            statename = 'Maryland';
            break;
        case 'NC':
            statename = 'North Carolina';
            break;
        case 'SC':
            statename = 'South Carolina';
            break;
        case 'VA':
            statename = 'Virginia';
            break;
        case 'WV':
            statename = 'West Virginia';
            break;
        case 'LA':
            statename = 'Louisiana';
            break;
        case 'MS':
            statename = 'Mississippi';
            break;
        case 'TX':
            statename = 'Texas';
            break;
        case 'KY':
            statename = 'Kentucky';
            break;
        case 'MI':
            statename = 'Michigan';
            break;
        case 'OH':
            statename = 'Ohio';
            break;
        case 'TN':
            statename = 'Tennessee';
            break;
        case 'IL':
            statename = 'Illinois';
            break;
        case 'IN':
            statename = 'Indiana';
            break;
        case 'WI':
            statename = 'Wisconsin';
            break;
        case 'AR':
            statename = 'Arkansas';
            break;
        case 'IA':
            statename = 'Iowa';
            break;
        case 'MN':
            statename = 'Minnesota';
            break;
        case 'MO':
            statename = 'Missouri';
            break;
        case 'NE':
            statename = 'Nebraska';
            break;
        case 'ND':
            statename = 'North Dakota';
            break;
        case 'SD':
            statename = 'South Dakota';
            break;
        case 'AK':
            statename = 'Alaska';
            break;
        case 'AZ':
            statename = 'Arizona';
            break;
        case 'CA':
            statename = 'California';
            break;
        case 'GU':
            statename = 'Guam';
            break;
        case 'HI':
            statename = 'Hawaii';
            break;
        case 'ID':
            statename = 'Idaho';
            break;
        case 'MT':
            statename = 'Montana';
            break;
        case 'NV':
            statename = 'Nevada';
            break;
        case 'MP':
            statename = 'Northern Mariana Islands';
            break;
        case 'OR':
            statename = 'Oregon';
            break;
        case 'WA':
            statename = 'Washington';
            break;
        case 'CO':
            statename = 'Colorado';
            break;
        case 'KS':
            statename = 'Kansas';
            break;
        case 'NM':
            statename = 'New Mexico';
            break;
        case 'OK':
            statename = 'Oklahoma';
            break;
        case 'UT':
            statename = 'Utah';
            break;
        case 'WY':
            statename = 'Wyoming';
            break;
        case 'AL':
            statename = 'Alabama';
            break;
        case 'FL':
            statename = 'Florida';
            break;
        case 'GA':
            statename = 'Georgia';
            break;
        case 'DC':
            statename = 'District of Columbia';
            break;
        case 'US':
                statename = 'United States of America';
                break;
        default:
            statename = "United States of America";
    }

    return statename;
}