import { combineReducers } from "redux";
import homeReducer from "./homeReducer";
import searchReducer from "./searchReducer";
import readReducer from "./readReducer";
import readCheckedReducer from "./readCheckedReducer";
import spinnerReducer from "./spinnerReducer";
import { loadingReducer } from "./loadingReducer";
import locationReducer from "./locationReducer";

export default combineReducers({
    home: homeReducer,
    search: searchReducer,
    read: readReducer,
    readchecked: readCheckedReducer,
    spinner: spinnerReducer,
    loading: loadingReducer,
    location: locationReducer,
});
