import React, {Component} from "react";
import PropTypes from 'prop-types';
import Chart from "chart.js";
import { wrapLabel } from "./wrapLabel";

class BarChartComponent extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        this.chart = new Chart(myChartRef, {
            type: "bar",
            data: {
                //Bring in data
                labels: this.props.labels,
                datasets: [
                    {
                        label: this.props.label,
                        data: this.props.data,
                        backgroundColor: this.props.backgroundColors,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                aspectRatio: 1,
                //Customize chart options
                legend: {
                    display: false
                },
                hover: {
                    onHover: function(e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            callback: function (label) {
                                return wrapLabel(label, 15);
                            }
                        }
                    }]
                }
            },
        });
    };

    componentDidMount() {
        this.buildChart();
    }

    componentDidUpdate() {
        this.chart.data.labels = this.props.labels;
        this.chart.data.datasets = [
            {
                label: this.props.label,
                data: this.props.data,
                backgroundColor: this.props.backgroundColors,
            },
        ];
        this.chart.update();
    }

    chartClick = (evt) => {
        let activePoints = this.chart.getElementsAtEvent(evt);
        if (activePoints[0]) {
            let chartData = activePoints[0]['_chart'].config.data;
            let idx = activePoints[0]['_index'];
            let label = chartData.labels[idx];
            let value = chartData.datasets[0].data[idx];
            this.props.legendCallBack(evt, {text: label})
        }
    };

    render() {
        return (
            <canvas ref={this.chartRef} onClick={this.chartClick}/>
        );
    }
}

BarChartComponent.propTypes = {
    label: PropTypes.string.isRequired,
    labels: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    backgroundColors: PropTypes.array.isRequired,
}
export const BarChart = BarChartComponent;
