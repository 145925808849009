import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import ManageAlertsItem from './ManageAlertItem';
import ManageAlertsOptions from './ManageAlertsOptions';
import ManageAlertsItemTitle from './ManageAlertsItemTitle';
import ManageAlertsConfirm from './ManagerAlertsConfirm';
import { get,post } from "../../../services/ApiService";
import { MANAGE_ALERTS_OPTIONS } from 'pages/utils/common';
import EditParentAlertOverlay from 'pages/Common/EditParentAlertOverlay';

const ManageAlertsOverlay = ({ isVisible, closeDialog, managealertList }) => {

    const [deleteenabled, setDeleteEnabled] = useState(false);
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [isDeletedByIcon, setDeletedByIcon] = useState(false);
    const [selectedAlertId, setSelectedAlertId] = useState('');
    const [managealertListLocal, setManagealertListLocal] = useState([]);
    const [isParentAlertVisible, setParentAlertVisible] = useState(false);
    const [selectedEditAlert, setSelectedEditAlert] = useState({});
    const [selectedOptionValue, setSelectedOptionValue] = useState(MANAGE_ALERTS_OPTIONS.CreatedOn_Value);
    
    useEffect(() => {
        setManagealertListLocal(managealertList);
        optionChange(MANAGE_ALERTS_OPTIONS.CreatedOn_Value, managealertList);
       
    }, []);

    const showConfirmDialog = () => {
        setConfirmDialogVisible(true);
    }

    const closeConfirmDialog = () => {
        setDeletedByIcon(false);
        setConfirmDialogVisible(false);
    }

    const deleteAlertsFromConfirmDialog = () => {
        let deletedEnabled = false;
        let element = document.getElementById('cbx_all');
        let checkedList = managealertListLocal.filter((item) => item.checked);
        let uncheckList = managealertListLocal.filter((item) => !item.checked);
        let alertList = [];
        if (isDeletedByIcon) {
            deletedEnabled = checkedList.filter((item) => item.alertId != selectedAlertId).length > 0;
        }
        checkedList.map((item) => alertList.push(item.alertId));
        let aids = isDeletedByIcon ? selectedAlertId : alertList.join(',');
     
          get('./api/ManageAlerts/deleteManageAlerts/?aids=' + aids)
              .then(data => {
                
                  if (isDeletedByIcon)
                      setManagealertListLocal(managealertListLocal.filter((item) => item.alertId != selectedAlertId));
                  else
                      setManagealertListLocal(uncheckList);
                
                  setDeletedByIcon(false);
                  setConfirmDialogVisible(false);
                  setDeleteEnabled(deletedEnabled);
                  if (element.checked && !deletedEnabled) element.checked = false;
              });    
    }

    const deleteManageAlerts = (e) => {
        setDeletedByIcon(true);
        setSelectedAlertId(e.target.id);
        setConfirmDialogVisible(true);
    }
    const editAlert = (item) => {        
        setSelectedEditAlert(item);
        setParentAlertVisible(true);
    }

    const saveAlert = (recipients, alertId, parentCompanyId, originalAdditionalRecipients) => {        
        post('./api/ManageAlerts/saveAlert', { recipients: recipients, alertId: alertId, parentCompanyId, originalAdditionalRecipients})
            .then(data => { 
            });    
        setTimeout(() => {
            get('./api/ManageAlerts/searchManageAlerts/?')
                .then(data => {
                    optionChange(selectedOptionValue, data)
                });
        }, 500);
    }

    const closeParentAlertOverlay = (e) => {
        setParentAlertVisible(false);
    }

    const onCheckAllChange = (e) => {
        let alertList = [];
        setDeleteEnabled(e.target.checked);
        managealertListLocal.map((item) => {
            item.checked = e.target.checked;
            document.getElementById('cbx_' + item.alertId).checked = e.target.checked;
            alertList.push(item);
        });
        setManagealertListLocal(alertList);
        setDeletedByIcon(false);
    }

    const changeHandler = (val) => {
        optionChange(val, managealertListLocal);
    }

    const optionChange = (val, managealertListLocal) => {
        let sorted_List = [];
        if (val == MANAGE_ALERTS_OPTIONS.CreatedOn_Value) {
            let CreatedOnDistinct = Array.from(new Set(managealertListLocal.map(s => s.createdon)))
                .map(c => { return { createdon: c } });
            CreatedOnDistinct.sort((a, b) => {
                return new Date(a.createdon).getTime() -
                    new Date(b.createdon).getTime()
            }).reverse();
            CreatedOnDistinct.map(c => {
                let createdOnSortList = managealertListLocal.filter(f => f.createdon === c.createdon);
                createdOnSortList = sortAlerts(createdOnSortList);
                createdOnSortList.map((item) => {
                    sorted_List.push(item);
                });
            });
        }
        else if (val == MANAGE_ALERTS_OPTIONS.ModifiedOn_Value) {
        
            let ModifiedOnDistinct = Array.from(new Set(managealertListLocal.map(s => s.modifiedon)))
                .map(c => { return { modifiedon: c } });
            ModifiedOnDistinct.sort((a, b) => {
                return new Date(a.modifiedon).getTime() -
                    new Date(b.modifiedon).getTime()
            }).reverse();

            ModifiedOnDistinct.map(c => {
                let modifiedOnSortList = managealertListLocal.filter(f => f.modifiedon === c.modifiedon);
                modifiedOnSortList = sortAlerts(modifiedOnSortList);
                modifiedOnSortList.map((item) => {
                    sorted_List.push(item);
                });
            });
        }
        else {
            sorted_List = managealertListLocal.reverse();
            sorted_List = sortAlerts(sorted_List);
        }
        setManagealertListLocal(sorted_List);
        setSelectedOptionValue(val);
    }

    const sortAlerts = (sortedList) => {
        return sortedList.sort((a, b) => {
            a.company = a.company ? a.company : '';
            b.company = b.company ? b.company : '';
            if (a.company.toLowerCase() < b.company.toLowerCase()) { return -1; }
            if (a.company.toLowerCase() > b.company.toLowerCase()) { return 1; }
            return 0;
        });
    }

    const onChange = (e, alertId) => {
        let alertList = [];
        let deleteEnabled = '';
        managealertListLocal.map((item) => {
            if (item.alertId == alertId) item.checked = e.target.checked;
            if (item.checked) deleteEnabled += 'true';
            alertList.push(item);
        });
        setDeletedByIcon(false);
        setManagealertListLocal(alertList);
        setDeleteEnabled(deleteEnabled.length ? true : false);
    }

    return (
        <>
        <div tabIndex="-1" role="dialog" id="ManageAlertsOverlay"
            className={clsx("wk-modal wk-modal-large wk-modal-vertical-middle wk-modal-with-overlay", { "wk-modal-opened": isVisible })}>
                <section id="ManageAlertsOverlaySection" className="wk-modal-content wk-modal-content-layout" style={{ width: 800 }}>
                <header id="ManageAlertsOverlayHeader" className="wk-modal-header">
                    <div className="wk-modal-title">Manage Alerts</div>
                    <button id="btnManageAlertsOverlay" onClick={closeDialog} aria-label="close dialog"
                            className="wk-button-icon wk-modal-close">
                        <span wk-icon="close" />
                    </button>
                </header>
                    <div id="ManageAlertsOverlayBody" className="wk-modal-body">
                        <ManageAlertsOptions changeHandler={(val) => changeHandler(val)} />                 
                        <div className="manage-alerts-overlay-margin">
                            <hr />
                            <ManageAlertsItemTitle disabled={!managealertListLocal.length} scrollbar={managealertListLocal.length > 5 } onCheckAllChange={(e) => onCheckAllChange(e)} />
                            <div className="manage-alerts-items">
                                {managealertListLocal.map((item, index) => (
                                    <ManageAlertsItem key={'a_' + item.alertId} item={item} onChange={(e, alertId) => onChange(e, alertId)} deleteManageAlerts={deleteManageAlerts} index={index} editAlert={editAlert} />
                                ))}
                            </div>     
                        </div>                                       
                </div>
                <footer className="wk-modal-footer">
                    <div className="wk-button-bar">
                        <div className="wk-button-group-right">
                             <button onClick={closeDialog}>Close</button>   
                        </div>
                        <div className="wk-button-group-left">
                                {!deleteenabled && <button data-testid="ManageAlertsOvelay_Delete_Disabled" className="wk-button-danger" disabled>Delete</button>}
                                {deleteenabled && <button data-testid="ManageAlertsOvelay_Delete" className="wk-button-danger" onClick={showConfirmDialog}>Delete</button>}
                            
                        </div>
                    </div>
                </footer>
            </section>
        </div>
            {isConfirmDialogVisible && <ManageAlertsConfirm isVisible={isConfirmDialogVisible} isSingleDeleted={isDeletedByIcon} closeDialog={closeConfirmDialog} deleteClicked={deleteAlertsFromConfirmDialog} />}
            {isParentAlertVisible && <EditParentAlertOverlay saveAlert={saveAlert} isVisible={isParentAlertVisible} closeOverlay={closeParentAlertOverlay} selectedAlert={selectedEditAlert} />}
        </>
    );
}

export default ManageAlertsOverlay;