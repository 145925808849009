import React, { useRef, useEffect, useState } from 'react';
import { EMAIL_ADDITIONAL_ECIPENTS_MODE } from 'pages/utils/common';
import EmailWarningBlock from 'common/EmailAdditionalRecipients/EmailWarningBlock';
import EmailAdditionalRecipientsConfirm from 'common/EmailAdditionalRecipients/EmailAdditionalRecipientsConfirm';


const EmailAdditionalRecipients = ({ isVisible, additionalRecipients, syncAdditionalRecipients, isAdditionalRecipientsDisabled }) => {

    const add_title = "Add Additional Recipients";
    const add_New_Label = "New additional recipients (maximum 25)";
    const edit_Label = "Edit existing recipient";
    const email_invalid = "Your entry appears to not be valid. Please check and save again.";
    const email_duplicated = "You have entered a duplicate email address. Please delete the duplicate and try to save again.";
    const email_maximum_warning = "You tried to add emails in excess of the 25 maximum. Please review your list.";
    const add_New_Button_Label = "Add";
    const edit_Button_Label = "Save";
    const MAX_LIMIT = 25;
    const [inputLabel, setInputLabel] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [btnLabel, setBtnLabel] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
    const [isWarningVisible, setWarningVisible] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [mode, setMode] = useState(EMAIL_ADDITIONAL_ECIPENTS_MODE.Default);
    const [EmailRecipients, setEmailRecipients] = useState([]);

    useEffect(() => {
        if (additionalRecipients)
            setEmailRecipients(additionalRecipients.split(','));
        else
            setEmailRecipients([]);
        setMode(EMAIL_ADDITIONAL_ECIPENTS_MODE.Default);

    }, [isVisible, additionalRecipients]);

    const closeConfirmDialog = () => {
        setConfirmDialogVisible(false);
    }

    const Mousehover = (e, id) => {
        showHideTooltip(e, id, "visible");
    }

    const MouseOut = (e, id) => {
        showHideTooltip(e, id, "hidden");
    }

    const showHideTooltip = (e, id, visibility) => {
        let ele = document.getElementById(id);
        ele.style.visibility = visibility;
    }

    const showInputField = () => {
        setMode(EMAIL_ADDITIONAL_ECIPENTS_MODE.Add);
        setInputLabel(add_New_Label);
        setBtnLabel(add_New_Button_Label);
        setSelectedIndex(-1);
        setHasError(false);
        setWarningVisible(false);
        setTimeout(() => {
            let ele = document.getElementById('txtEmail');
            if (ele) ele.value = '';
        }, 100);
    }

    const showEditMode = (item, index) => {
        setMode(EMAIL_ADDITIONAL_ECIPENTS_MODE.Edit);
        setInputLabel(edit_Label);
        setBtnLabel(edit_Button_Label);
        setSelectedIndex(index);
        setHasError(false);
        setWarningVisible(false);
        setTimeout(() => {
            let ele = document.getElementById('txtEmail');
            if (ele) ele.value = item;
        }, 100);
    }

    const deleteItem = (index) => {
        setConfirmDialogVisible(true);
        setSelectedIndex(index)
    }

    const DeleteConfirmed = () => {
        let newList = EmailRecipients.filter((item, index) => index != selectedIndex);
        let ele = document.getElementById('txtEmail');
        if (ele) ele.value = '';
        setConfirmDialogVisible(false);
        setHasError(false);
        setWarningVisible(false);
        setEmailRecipients(newList);
        syncAdditionalRecipients(newList.join(','));
        setMode(EMAIL_ADDITIONAL_ECIPENTS_MODE.Default);
    }

    const saveEmail = () => {
        let val = document.getElementById('txtEmail').value;
        let emailList = val.split(',');
        let emailList_clone = emailList;

        if (emailList.filter((item) => !isEmailValid(item)).length) {
            setWarningVisible(true);
            setWarningMessage(email_invalid)
        }
        else {
            let isDuplicated = false;
            emailList.map((item) => {
                let new_len = emailList_clone.filter((i) => i.toLowerCase() == item.toLowerCase()).length;
                let add_edit_len = EmailRecipients.filter((i) => i.toLowerCase() == item.toLowerCase()).length;
                if (new_len > 1 || add_edit_len >= 1) {
                    isDuplicated = true;
                }
            })

            if (mode == EMAIL_ADDITIONAL_ECIPENTS_MODE.Edit) {
                isDuplicated = EmailRecipients[selectedIndex].toLowerCase() == emailList[0].toLowerCase() ? false : isDuplicated;
            }

            if (isDuplicated) {
                setWarningVisible(true);
                setWarningMessage(email_duplicated);
            }
            else {
                let total = emailList.length + EmailRecipients.length;
                if (total > 25 && mode == EMAIL_ADDITIONAL_ECIPENTS_MODE.Add) {
                    setHasError(true);
                }
                else {
                    let EmailListFinal = [];
                    if (mode == EMAIL_ADDITIONAL_ECIPENTS_MODE.Edit) {
                        EmailRecipients.forEach((item, index) => {
                            if (index == selectedIndex) {
                                EmailListFinal.push(emailList[0]);
                            }
                            else {
                                EmailListFinal.push(item);
                            }
                        })
                    }
                    else {
                        EmailRecipients.forEach((item) => {
                            EmailListFinal.push(item);
                        })
                        emailList.forEach((item) => {
                            EmailListFinal.push(item);
                        })
                    }
                    setMode(EMAIL_ADDITIONAL_ECIPENTS_MODE.Default);
                    setEmailRecipients(EmailListFinal);
                    setWarningVisible(false);
                    setHasError(false);
                    syncAdditionalRecipients(EmailListFinal.join(","));
                    document.getElementById('txtEmail').value = '';
                }
            }
        }
    }

    const isEmailValid = (email) => {
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        return pattern.test(email);
    }

    return (
        <>
            {(EmailRecipients.length < MAX_LIMIT && !isAdditionalRecipientsDisabled) && <span><div><a data-testid="addTitle" className="wk-link" onClick={() => showInputField()}>{add_title}</a></div></span>}
            {(EmailRecipients.length >= MAX_LIMIT || isAdditionalRecipientsDisabled) && <span><div><a data-testid="addTitle_disabled" disabled>{add_title}</a></div></span>}
            { mode != EMAIL_ADDITIONAL_ECIPENTS_MODE.Default && <div><div>
                <span className="wk-font-weight-bold">{inputLabel}</span>
                {isWarningVisible && <EmailWarningBlock warningMessage={warningMessage} />}
            </div>
                <div>
                    <div className="email-recipients-input-body">
                        <input data-testid="txtEmail" id="txtEmail" style={{ width: 550 }} type="text" placeholder="Enter additional recipients and separate them using commas" ></input> <button data-testid="btn_Add_Save" className="email-recipients-button" onClick={saveEmail}>{btnLabel}</button>
                    </div>
                </div></div>}
            {hasError && <EmailWarningBlock warningMessage={email_maximum_warning} />}
            <div className="email-recipients-items-body">
                {EmailRecipients.map((item, index) => (
                    <div className="wk-layout-50-50 email-recipients-item" key={'_e_a_r' + index}>
                        <span data-testid={"recipient_" + index} className="email-recipients-font-small">{item}</span>
                        <span data-testid={"edit_" + index} className="email-recipients-edit-icon" wk-icon="pencil" onMouseEnter={(e) => Mousehover(e, "emailTootip4Edit" + index)} onMouseLeave={(e) => MouseOut(e, "emailTootip4Edit" + index)} onClick={() => showEditMode(item, index)}></span>
                        <div id={"emailTootip4Edit" + index} className="wk-tooltip manage-alerts-tooltip-right email-recipients-tooptip-edit">
                            <div className="wk-tooltip-body" >Edit</div>
                        </div>
                        <span data-testid={"delete_" + index} style={{ marginLeft: -3 }} className="manage-alerts-close-icon" wk-icon="close" onMouseEnter={(e) => Mousehover(e, "emailTootip4Delete" + index)} onMouseLeave={(e) => MouseOut(e, "emailTootip4Delete" + index)} onClick={() => deleteItem(index)}></span>
                        <div id={"emailTootip4Delete" + index} className="wk-tooltip manage-alerts-tooltip-right email-recipients-tooptip-delete">
                            <div className="wk-tooltip-body">Delete</div>
                        </div>
                    </div>
                ))}
            </div>
            { isConfirmDialogVisible && <EmailAdditionalRecipientsConfirm isVisible={isConfirmDialogVisible} closeDialog={closeConfirmDialog} YesClicked={DeleteConfirmed} />}
        </>
    );
}

export default EmailAdditionalRecipients;