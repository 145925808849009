import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import { filterAllCompanyResultsByLawyerIds } from "state/actions/readActions";
import color from "../../utils/color";

const getLawyerCollegeCount = (currentCollegesResult, top) => {
    let colleges = currentCollegesResult.map(a => a.attbr);
    let occurrences = {};
    for (let i = 0, j = colleges.length; i < j; i++) {
        occurrences[colleges[i]] = (occurrences[colleges[i]] || 0) + 1;
    }
    let result = Object.entries(occurrences).map(([attbr, count]) => ({ college: attbr, count }));
    result.sort((a, b) => b.count - a.count);
    return result.slice(0, top);
}
const CollegeBi = ({ isOverlay = false, closeDialog, currentCollegesResults }) => {
    const [stats, setStats] = useState([]);
    const dispatch = useDispatch();
    const store = useStore();
    useEffect(() => {
        async function getStats() {
            if (currentCollegesResults) {
                let top = 5;
                if (isOverlay) {
                    top = currentCollegesResults.length;
                }
                let result = getLawyerCollegeCount(currentCollegesResults, top);
                setStats(result);
            }
        }

        getStats();
    }, [currentCollegesResults]);
    const filterByLawyerIds = legendItem => {
        const result = store.getState().read.currentCollegesResults;
        if (result) {
            const lawyerIds = result.filter(item => item.attbr === legendItem.text).map(item => item.lawyerId);
            dispatch(filterAllCompanyResultsByLawyerIds(lawyerIds));
            if(isOverlay){
                closeDialog();
            }
        }
    }

    return (
        <div
            id="readColleges"
        >
            <Piechart
                label="Colleges"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.college)}
                ref={registerTestObject(isOverlay ? BI.READ_OVERLAY_COLLEGES_CHART : BI.READ_COLLEGES_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]}/>
        </div>
    );
};
export default CollegeBi;
