import { LOCATION_CHANGED } from "../actions/locationActions";

const initialState = {
    previousLocation: "/"
}
export default function locationReducer(state = initialState, action) {
    switch (action.type) {
        case LOCATION_CHANGED:
            state.previousLocation = action.pathname;
            return { ...state };
        default:
            return state;
    }
}
