import _ from 'lodash';

export const lawyerCount = (currentMainResults, filter, top, sorted = true) => {

    let resultArr = [];

    if (currentMainResults != null) {


        for (let i = 0; i < currentMainResults.length; i++) {

            let sc = null;
            if (filter === "lookupStateProvince") {
                sc = currentMainResults[i].lookupStateProvince;
            }
            else if (filter === "city") {
                sc = currentMainResults[i].city;
            }
            else {
                sc = currentMainResults[i].lookupStateProvince;
            }

            let flag = 0;
            //checking whether the lp is already present in our new array
            for (let j = 0; j < resultArr.length; j++) {
                if (resultArr[j].data === sc) {
                    resultArr[j].value += 1;
                    flag = 1;
                    break;
                }
            }

            if (flag === 0) {
                resultArr.push({
                    data: sc,
                    value: 1
                });
            }
        }
    }
     
    let resultArrSorted = resultArr;
    if (sorted) resultArrSorted = _.orderBy(resultArr, ['value'], ['desc']);

    if (top > 0) {
        let resultArrSortedfirstX = _.take(resultArrSorted, top);
        return resultArrSortedfirstX;
    }
    else
    {
        return resultArrSorted
    }
 
}