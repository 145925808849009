import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import { filterAllCompanyResultsByLawyerIds } from "state/actions/readActions";
import color from "../../utils/color";

const getLawyerPACount = (currentPracticeAreasResults, top) => {
    let practiceAreas = currentPracticeAreasResults.map(a => a.pa);
    let occurrences = {};
    for (let i = 0, j = practiceAreas.length; i < j; i++) {
        occurrences[practiceAreas[i]] = (occurrences[practiceAreas[i]] || 0) + 1;
    }
    let result = Object.entries(occurrences).map(([pa, count]) => ({ pa, count }));
    result.sort((a, b) => b.count - a.count);
    return result.slice(0, top);
}
const PracticeAreaBi = ({ isOverlay = false, closeDialog, currentPaResults }) => {
    const [stats, setStats] = useState([]);
    const dispatch = useDispatch();
    const store = useStore();

    useEffect(() => {
        async function getStats() {
            if (currentPaResults) {
                let top = 5;
                if (isOverlay) {
                    top = currentPaResults.length;
                }
                let result = getLawyerPACount(currentPaResults, top);
                setStats(result);
            }
        }

        getStats();
    }, [currentPaResults]);
    const filterByLawyerIds = legendItem => {
        const result = store.getState().read.currentPaResults;
        if (result) {
            const lawyerIds = result.filter(item => item.pa === legendItem.text).map(item => item.lawyerId);
            dispatch(filterAllCompanyResultsByLawyerIds(lawyerIds));
            if (isOverlay) {
                closeDialog();
            }
        }
    }

    return (
        <div
            id="readPracticeAreas"
        >
            <Piechart
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.pa)}
                ref={registerTestObject(isOverlay ? BI.READ_OVERLAY_PRACTICE_AREAS_CHART : BI.READ_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]}/>
        </div>
    );
};
export default PracticeAreaBi;
