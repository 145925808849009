import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import PAGINATION_ACTION from "pages/utils/common";

const PaginationBarChartV1 = ({ totalRecords, pageSize, paginate, setPage, paginationAction }) => {  
    const currentPage = useSelector(state => state.search.barChartPage);
    const totalPages = Math.ceil(totalRecords / pageSize);
    const PagesLimit = totalPages < 11 ? totalPages : 11;
    const handlePageNumbers = (startingPage, endingPage) => {
        const pages = [];
        for (let i = startingPage; i <= endingPage; i++) {
            pages.push(i);
        }
        return pages;
    }
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [pageNumbers, setPageNumbers] = useState(handlePageNumbers(1, (totalPages <= PagesLimit ? totalPages : PagesLimit)));
    useEffect(() => {
        if (currentPage == 1) {
            setPageNumbers(handlePageNumbers(1, (totalPages <= PagesLimit ? totalPages : PagesLimit)));
            selectedPage(1);
        }
        else if (paginationAction == PAGINATION_ACTION.selectPage) {
            if (setPage <= 6) {
                setSelectedIndex(setPage - 1);
            }
            else if (setPage - 5 > 1 || setPage + 5 < totalPages) {
                setSelectedIndex(5);
            }
            updatePageNumbers(setPage);
        }
    }, [totalRecords, currentPage])
    const updatePageNumbers = (selectedPage) => {
        const startingPage = selectedPage <= 6 ? 1 : (selectedPage - 5 >= 1 ? (selectedPage - 5) : 1);
        const endingPage = selectedPage <= 6 ? PagesLimit : (selectedPage + 5 <= totalPages ? (selectedPage + 5) : totalPages);
        // process page numbers
        setPageNumbers(handlePageNumbers(startingPage, endingPage));
    }
    const nextPage = (page) => {
        let newIndex = selectedIndex;
        if (page <= 6 || page > totalPages - 5) {
            newIndex = selectedIndex + 1;
        }
        else if (page + 5 <= totalPages) {
            newIndex = 5;
        } else {
            newIndex = selectedIndex + 1;
        }
        if ((page - 5 >= 1) && (page + 5 <= totalPages)) {
            // process page numbers
            updatePageNumbers(page);
        }

        //paginate to selected page
        paginate(page, PAGINATION_ACTION.nextPage);
        setSelectedIndex(newIndex);
    }
    const prevPage = (page) => {
        let newIndex = selectedIndex;
        if (page <= 5 || page > totalPages - 5) {
            newIndex = selectedIndex - 1;
        }
        else if (page - 5 >= 1) {
            newIndex = 5;

        } else {
            newIndex = selectedIndex - 1;
        }
        if ((page - 5 >= 1) && (page + 5 <= totalPages)) {
            // process page numbers
            updatePageNumbers(page);
        }
        //paginate to selected page
        paginate(page, PAGINATION_ACTION.PrevPage);
        setSelectedIndex(newIndex);
    }
    const selectedPage = (page) => {
        paginate(page, PAGINATION_ACTION.selectPage);
    }
    return (totalPages > 1 ?
        <div className="wk-row search-pagaination-info">
            <ul className="wk-pagination">
                <li className={currentPage <= 1 ? 'wk-disabled' : ''}><a onClick={() => { if (currentPage > 1) { prevPage(currentPage - 1) } }} tabIndex="0" className="wk-button"><span wk-icon="arrow-left"></span></a></li>
                {

                    pageNumbers.map((pn) => (
                        <li className={(currentPage == pn ? 'wk-active' : '')} key={pn}><a onClick={() => selectedPage(pn)} tabIndex="0">{pn}</a></li>
                    ))}
                <li className={currentPage >= totalPages ? 'wk-disabled' : ''}><a onClick={() => { if (currentPage < totalPages) { nextPage(currentPage + 1) } }} tabIndex="0" className="wk-button"><span wk-icon="arrow-right"></span></a></li>
            </ul>
        </div> : <div></div>
    );
}
export default PaginationBarChartV1;