import { get, post } from "./ApiService";
import { prepareQueryString } from "utils/prepareQueryString";

export const getPracticeAreasBySearch = (payload) => {
    let url = 'api/practicearea/byBISRPSearch?';
    return get(prepareQueryString(url, payload)).then(response =>
        response
    );
};
export const getPracticeAreasByLawyerId = async (lawyerIds) => {
    let url = 'api/practicearea/byLawyerId';
    return await post(url, {lawyerIds});
};