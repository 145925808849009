import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import root from "../sagas";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
    process.env.NODE_ENV === "production"
        ? compose()
        : composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// Initialization of session
sagaMiddleware.run(root);

export default store;