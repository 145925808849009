import * as React from 'react';
import './styles.css';

const TickerList = (props) => {
    return <div className="ticker">
        <div className="ticker-list">
            {props.children}
        </div>
    </div>
}

export default TickerList;