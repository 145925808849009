import React, { useEffect, useState } from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import { getLawyerPACount } from 'pages/utils/lawyerPACount';
import { filterMainResultsByLawyerIds } from "state/actions/searchActions";
import color from "../../utils/color";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";
import NoRecordFound from "../../components/NoRecordFound";

const PracticeAreasResultsBI = ({ isOverlay = false }) => {
    const currentPracticeAreasResults = useSelector(state => state.search.currentPracticeAreasResults);
    const dispatch = useDispatch();
    const store = useStore();
    const [stats, setStats] = useState([]);
    const loadingSelector = createLoadingSelector(['PRACTICEAREA_DATA_BY_SEARCH']);
    const loading = useSelector(state => loadingSelector(state));
    useEffect(() => {
        async function getStats() {
            if (currentPracticeAreasResults) {
                let top = 5;
                if(isOverlay)
                    top = 0;
                let result = getLawyerPACount(currentPracticeAreasResults, top, true);
                setStats(result);
            }
        }
        getStats();
    }, [currentPracticeAreasResults]);
    const filterByLawyerIds = legendItem => {
        const result = store.getState().search.currentPracticeAreasResults;
        if (result) {
            const lawyerIds = result.filter(item => item.desc === legendItem.text).map(item => parseInt(item.lId));
            dispatch(filterMainResultsByLawyerIds(lawyerIds));
        }
    }

    return (
        <div id="psfColleges">
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && stats.length > 0 && <Piechart
                label="Practice Areas"
                data={stats.map(item => item.count)}
                labels={stats.map(item => item.desc)}
                ref={registerTestObject(BI.PSF_PRACTICE_AREAS_CHART)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                backgroundColors={[...color(stats.length)]}/>
            }
            {!loading && !stats.length && <NoRecordFound/>}
        </div>
    );
};
export default PracticeAreasResultsBI;
