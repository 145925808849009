import React, { Component } from "react";
import PropTypes from "prop-types";
import Chart from "chart.js";
import { LABEL_HEIGHT } from "../../pages/utils/common";
import { wrapLabel } from "./wrapLabel";

class HorizontalBarchartComponent extends Component {
    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
    }

    buildChart = () => {
        const myChartRef = this.chartRef.current.getContext("2d");
        this.chart = new Chart(myChartRef, {
            type: "horizontalBar",
            data: {
                //Bring in data
                labels: this.props.labels,
                datasets: [
                    {
                        label: this.props.label,
                        data: this.props.data,
                        backgroundColor: this.props.backgroundColors,
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                elements: {
                    arc: {
                        borderWidth: 0
                    }
                },
                hover: {
                    onHover: function (e) {
                        var point = this.getElementAtEvent(e);
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                },
                beginAtZero: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function (value) { if (value % 1 === 0) { return value; } }
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            callback: function (label) {
                                return wrapLabel(label, 45);
                            }
                        }
                    }]
                }
            },
        });
    };
    chartClick = (evt) => {
        let activePoints = this.chart.getElementsAtEvent(evt);
        if (activePoints[0]) {
            let chartData = activePoints[0]['_chart'].config.data;
            let idx = activePoints[0]['_index'];
            let label = chartData.labels[idx];
            this.props.legendCallBack(evt, { text: label })

        }
    };

    componentDidUpdate() {
        if (this.chart != null) {
            this.chart.destroy();
        }
        if (this.props.labels.length) {
            let factor = this.props.labels.length == 1 ? LABEL_HEIGHT.labelLargeHeight : this.props.labels.length <= 5 ? LABEL_HEIGHT.labelMediumHeight : LABEL_HEIGHT.labelSmallHeight;
            document.getElementById('myChart').style.height = this.props.labels.length * factor + 'px';
            this.buildChart();
            this.chart.update();
        }
    }

    render() {
        return (
            <>
                <div className="wk-oberlay-body">
                    <canvas id="myChart" ref={this.chartRef} onClick={this.chartClick} />
                </div>
                <div className="legend">
                    {this.props.labels.map((item, i) => (
                        <div
                            className="legend-item"
                            key={item + i}
                            onClick={(evt) => this.props.legendCallBack(evt, { text: item })}
                        >
                            <div className="color"
                                style={{ backgroundColor: this.props.backgroundColors[i] }} />
                            <span>{item}</span>
                        </div>
                    ))}
                </div>

            </>
        );
    }
}

HorizontalBarchartComponent.propTypes = {
    label: PropTypes.string.isRequired,
    labels: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    backgroundColors: PropTypes.array.isRequired,
}

export const HorizontalBarchart = HorizontalBarchartComponent;
