import React from "react";
import { geoCentroid } from "d3-geo";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    Annotation
} from "react-simple-maps";
import { selectMultipleStates, resetStates, selectSingleState } from "state/actions/homeActions";
import './MapChart.css';
import allStates from "./allstates.json";
import WarningDialog from "pages/Common/WarningDialog";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

//these are for the states which are outside the map and whose state codes are extended using an arrow
const offsets = {
    VT: [50, -8],
    NH: [34, 2],
    MA: [30, -1],
    RI: [28, 2],
    CT: [35, 10],
    NJ: [34, 1],
    DE: [33, 0],
    MD: [47, 10],
    DC: [49, 21]
};


class MapChart extends React.Component {
    constructor(props) {
        super(props);
        //state of the Map Component
        this.state = {
            multipleSelectMode: false,
            clickedstate: "",
            hasClickedSearch: false,
            selectedStates: [],
            isVisible:false
        }
    }

    //handler to set state on clicking search in the multiple states selected box
    selectStateSearchHandler = () => {
        try {
            this.setState({ hasClickedSearch: true });
            this.props.selectStateAction(this.state.selectedStates);
        } catch (error) { logAndRedirectToErrorPage(error); }
    }


    //clear the selected states in the multiple states selected box
    clearHandler = (stateId) => {
        try {
            this.setState({ hasClickedSearch: false, clickedstate: stateId, selectedStates: [stateId], multipleSelectMode: false });
            if (this.props.selectedStates.length > 0) {
                this.props.resetStatesAction();
            }
        } catch (error) { logAndRedirectToErrorPage(error); }
    }

    closeDialog = () => {
        try {
            this.setState({ isVisible: false })
        } catch (error) { logAndRedirectToErrorPage(error); }
    }

    mapClickHandler = (e, stateId) => {
        try {
            //if shift key is used to select multiple US states
            if (e.shiftKey) {

                if (this.props.selectedStates.includes(stateId)) {
                    return;
                }

                if (this.props.selectedStates.length === 3) {
                    this.setState({ isVisible: true })
                }
                //updating the Component state by setting the newly selected US state name and state code
                else {
                    this.setState({
                        multipleSelectMode: true,
                        hasClickedSearch: false,
                        selectedStates: [...this.props.selectedStates, stateId]
                    });

                    //Map Improvements
                    this.props.selectStateAction([...this.props.selectedStates, stateId]);
                }
            }
            //selecting only one US state at a time
            else {
                this.clearHandler(stateId);
                this.props.selectSingleStateAction(stateId);
            }
        } catch (error) { logAndRedirectToErrorPage(error); }
    }

    render() {

        let selStateCodes = this.props.selectedStates.length === 0 ? "US" : this.props.selectedStates;

        return (
            <>
                {/*map division*/}
                <div className="Map">

                    <div className="MapHeader" style={{ width: "100%" }}>
                        <center><span wk-context="info" className="wk-label">(Shift + Click) to select 1 to 3 States Statistics</span></center>
                    </div>

                    {/*this generates the selected states box in case of multiple selections*/}

                    <ComposableMap projection="geoAlbersUsa">
                        <Geographies geography={geoUrl}>
                            {({ geographies }) => (
                                <>
                                    {geographies.map(geo => {
                                        const cur = allStates.find(s => s.val === geo.id);
                                        const centroid = geoCentroid(geo);
                                        return (
                                            <g
                                                className={"state " + geo.rsmKey}
                                                onClick={e => this.mapClickHandler(e, cur.id)}
                                                key={geo.rsmKey + "-name"}
                                            >
                                                <Geography
                                                    key={geo.rsmKey}
                                                    className={cur.id}
                                                    //setting the stroke color based on whether the US state is selected or not
                                                    stroke={selStateCodes.includes(cur.id) ? "#888" : "#bbb"}
                                                    geography={geo}
                                                    //setting the fill color based on whether the US state is selected or not
                                                    fill={selStateCodes.includes(cur.id) ? "#bbb" : "#eee"}
                                                //onClick handler
                                                />
                                                {cur &&
                                                    centroid[0] > -160 &&
                                                    centroid[0] < -67 &&
                                                    (Object.keys(offsets).indexOf(cur.id) === -1
                                                        ? (<StateAbbreviation cur={cur} centroid={centroid} />)
                                                        : (<StateAnnotation cur={cur} centroid={centroid} />)
                                                    )}
                                            </g>
                                        );
                                    }
                                    )}
                                </>
                            )}
                        </Geographies>
                    </ComposableMap>
                    <WarningDialog isVisible={this.state.isVisible} header={"Corporate Counsel Profiler"} message={" You can only select max 3 states!"} closeDialog={this.closeDialog} />
                </div>
            </>
        );
    }
}

const StateAbbreviation = ({ cur, centroid }) => (
    <Marker coordinates={centroid}>
        <text y="2" fontSize={14} textAnchor="middle">
            {cur.id}
        </text>
    </Marker>
);

const StateAnnotation = ({ cur, centroid }) => (
    <Annotation
        subject={centroid}
        dx={offsets[cur.id][0]}
        dy={offsets[cur.id][1]}
    >
        <text x={4} fontSize={14} alignmentBaseline="middle">
            {cur.id}
        </text>
    </Annotation>
);

const mapStateToProps = state => ({
    selectedStates: state.home.selectedStates
});
const mapDispatchToProps = dispatch => ({
    selectStateAction: state => dispatch(selectMultipleStates(state)),
    resetStatesAction: () => dispatch(resetStates()),
    selectSingleStateAction: state => dispatch(selectSingleState(state))
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapChart));



