import React, { useEffect } from 'react';
import NewsTicker from "./NewsTicker";
import TickerList from "./TickerList";
import { useDispatch, useSelector } from "react-redux";
import { rssFeedRequested } from "../../state/actions/homeActions";

const Ticker = () => {
    const feed = useSelector(state => state.home.rssFeed);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(rssFeedRequested());
    }, [])
    return (
        <div id="ticker">
            <TickerList>
                {feed?.map((i, index) => (
                    <NewsTicker key={index} id={index} title={i.Title.Text} url={i.Links[0].Uri}/>))}
            </TickerList>
        </div>
    );
};

export default Ticker;