import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NoRecordFound from "components/NoRecordFound";
import { ReadCheckedResultItem } from "pages/ReadChecked/ReadCheckedResultItem";
import { setReadCheckedSelectAll as setReadCheckedSelectAllAction } from "state/actions/readCheckedActions";
export const ReadCheckedResult = ({ toggleToolbar, isPrinting }) => {
    const readcheckedResult = [];
    const companyResult = useSelector(state => state.readchecked.companyResult);
    const currentLawyers = useSelector(state => state.readchecked.currentLawyers);
    const dispatch = useDispatch();
    const parentCompanies = companyResult.filter((item) => { return item.uberId == item.cId }).sort((a, b) => {
        if (a.currentName < b.currentName) { return -1; }
        if (a.currentName > b.currentName) { return 1; }
        return 0;
    });
    const [readCheckedSelectAll, setReadCheckedSelectAll] = useState(false);
    useEffect(() => {
        if (currentLawyers.length === currentLawyers.filter(item => item.checked === true).length) {
            setReadCheckedSelectAll(true);
        } else {
            setReadCheckedSelectAll(false);
        }
        handleReadToolbar(currentLawyers);
    }, [currentLawyers]);
    const onChangeSelectAll = e => {
        setReadCheckedSelectAll(e.target.checked);
        dispatch(setReadCheckedSelectAllAction(e.target.checked));
    };
    const handleReadToolbar = (lawyers) => {
        if (lawyers.some(item => item.checked == true)) {
            toggleToolbar(true);
        } else {
            toggleToolbar(false);
        }
    }
    const getCompanyLawyers = (cId, lawyers) => {
        return lawyers.filter((item) => { return item.cId == cId }).sort((a, b) => {
            if (a.lastName < b.lastName) { return -1; }
            if (a.lastName > b.lastName) { return 1; }
            return 0;
        });
    };
    const getCompanySubsidiaries = (uberId, companies) => {
        return companies.filter((item) => { return item.uberId == uberId && item.cId != item.uberId }).sort((a, b) => {
            if (a.currentName < b.currentName) { return -1; }
            if (a.currentName > b.currentName) { return 1; }
            return 0;
        });
    };
    const getCompanies = (pCompany) => {
        let companiesResult = [];
        let companies = getCompanySubsidiaries(pCompany.uberId, companyResult);
        companies.forEach((item) => {
            companiesResult.push({
                companyInfo: item,
                parentCompanyInfo: pCompany,
                lawyers: getCompanyLawyers(item.cId, currentLawyers)
            });
        });
        return companiesResult;
    };
    const getParentInfo = (pCompany) => {
        return {
            companyInfo: pCompany,
            lawyers: getCompanyLawyers(pCompany.cId, currentLawyers)
        };
    };

    const hrShown = (resultItem) => {
        if (!isPrinting) return true;
        let parentLawyerLength = resultItem?.parentInfo?.lawyers?.length > 0 ? resultItem?.parentInfo?.lawyers?.filter(l => l.checked == true).length : 0;
        let companyLawyerLength = resultItem?.companies?.lawyers?.length > 0 ? resultItem?.companies?.lawyers?.filter(l => l.checked == true).length : 0;
        return parentLawyerLength > 0 || companyLawyerLength > 0;
    }
    parentCompanies.forEach((item, index) => {
        // get all lawyers for the parent company        
        readcheckedResult.push({
            parentInfo: getParentInfo(item),
            companies: getCompanies(item)
        });

    });
    return (
        <>
            {(readcheckedResult && readcheckedResult.length > 0) ? <div>
                <span id="readSelectAll">
                    <input type="checkbox"
                        onChange={onChangeSelectAll}
                        checked={readCheckedSelectAll} />
                    <b>Check/Uncheck All</b>
                </span>
                {readcheckedResult?.map((resultItem, index) => (
                    <div key={"parent-" + index}>
                        <ReadCheckedResultItem resultItem={resultItem} toggleToolbar={toggleToolbar} isPrinting={isPrinting} />
                        {readcheckedResult[index + 1] && (resultItem?.parentInfo?.lawyers?.length > 0 || resultItem?.companies?.lawyers?.length > 0) ? hrShown(resultItem) && <hr /> : <></>}
                    </div>
                ))}
            </div>
                : !isPrinting && <NoRecordFound />
            }
        </>
    );
};