import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { userInfoRequested } from "../../state/actions/homeActions";

function UserID() {
    const userInfo = useSelector(state => state.home.userInfo);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(userInfoRequested());        
    }, []);
    useEffect(() => {
        if (userInfo.Username) {                
            window.pendo.initialize({
                visitor: {
                    id: userInfo.Sub,   // Required if user is logged in
                    domain: userInfo.Domain
                    //email: userInfo.Email,       // Recommended if using Pendo Feedback, or NPS Email                    
                    //firstName: userInfo.FirstName,
                    //lastName: userInfo.LastName
                    // Recommended if using Pendo Feedback
                    // role:         // Optional
                    // You can add any additional visitor level key-values here,
                    // as long as it's not one of the above reserved names.
                },

                account: {
                    id: userInfo.OrganizationCode // Required if using Pendo Feedback
                    // name:         // Optional
                    // is_paying:    // Recommended if using Pendo Feedback
                    // monthly_value:// Recommended if using Pendo Feedback
                    // planLevel:    // Optional
                    // planPrice:    // Optional
                    // creationDate: // Optional

                    // You can add any additional account level key-values here,
                    // as long as it's not one of the above reserved names.
                }
            });
        }
    }, [userInfo]);
    return (
        <>{userInfo.Username}</>
    );
}

export default UserID;