/**
 * Expose ref into window to make it accessible from console
 * @param key - key of ref
 * @returns {function(*): void}
 */
export function registerTestObject(key) {
    if (!window.testObjects) window.testObjects = {};
    return function(ref) {
        window.testObjects[key] = ref;
    }
}

export const BI = {
    HOME_COMPANY_STATES_CHART: 'BI Charts > Company States',
    HOME_COLLEGES_CHART: 'BI Charts > Colleges',
    HOME_PRACTICE_AREAS_CHART: 'BI Charts > Practice Areas',
    HOME_YEARS_ADMITTED_TO_BAR_CHART: 'BI Charts > Year Admitted to Bar',
    HOME_COMPANY_CITIES_CHART: 'BI Charts > Company Cities',

    PSF_COLLEGES_CHART: 'PSF Charts > Colleges',
    PSF_PRACTICE_AREAS_CHART: 'PSF Charts > Practice Areas',
    PSF_YEARS_ADMITTED_TO_BAR_CHART: 'PSF Charts > Year Admitted to Bar',
    PSF_COMPANY_CITIES_CHART: 'PSF Charts > Company Cities',
    PSF_COMPANY_STATES_CHART: 'PSF Charts > Company States',

    PSF_OVERLAY_COLLEGES_CHART: 'PSF Chart Overlay > Colleges',
    PSF_OVERLAY_PRACTICE_AREAS_CHART: 'PSF Chart Overlay > Practice Areas',
    PSF_OVERLAY_YEARS_ADMITTED_TO_BAR_CHART: 'PSF Chart Overlay > Year Admitted to Bar',
    PSF_OVERLAY_COMPANY_CITIES_CHART: 'PSF Chart Overlay > Company Cities',
    PSF_OVERLAY_COMPANY_STATES_CHART: 'PSF Chart Overlay > Company States',

    READ_COLLEGES_CHART: 'Read Page Charts > Colleges',
    READ_PRACTICE_AREAS_CHART: 'Read Page Charts > Practice Areas',
    READ_TITLES_CHART: 'Read Page Charts > Titles',

    READ_OVERLAY_COLLEGES_CHART: 'Read Page Chart Overlay > Colleges',
    READ_OVERLAY_PRACTICE_AREAS_CHART: 'Read Page Chart Overlay > Practice Areas',
    READ_OVERLAY_TITLES_CHART: 'Read Page Chart Overlay > Titles',

    READ_CHECKED_COLLEGES_CHART: 'Read Checked Page Charts > Colleges',
    READ_CHECKED_PRACTICE_AREAS_CHART: 'Read Checked Page Charts > Practice Areas',
    READ_CHECKED_TITLES_CHART: 'Read Checked Page Charts > Titles',

    READ_CHECKED_OVERLAY_COLLEGES_CHART: 'Read Checked Page Chart Overlay > Colleges',
    READ_CHECKED_OVERLAY_PRACTICE_AREAS_CHART: 'Read Checked Page Chart Overlay > Practice Areas',
    READ_CHECKED_OVERLAY_TITLES_CHART: 'Read Checked Page Chart Overlay > Titles'
}
