export const getLawyerPACount = (currentPracticeAreasResults, top, sorted = true) => {

    let practiceAreas = currentPracticeAreasResults.map(a => a.desc);

    let occurrences = {};
    for (let i = 0, j = practiceAreas.length; i < j; i++) {
        occurrences[practiceAreas[i]] = (occurrences[practiceAreas[i]] || 0) + 1;
    }

    let result = Object.entries(occurrences).map(([desc, count]) => ({ desc, count }));

   if (sorted) result.sort((a, b) => b.count - a.count);

    if (top > 0)
        return result.slice(0, top);
    else
        return result;
}