import React from 'react';
import { SEARCH_RESULT_OPTIONS } from 'pages/utils/common';

const SearchResultOptions = ({ changeHandler }) => {

    return (
        <>
            <div className="wk-pagination-bar">
                <div className="wk-pagination-results">
                    <div className="wk-field-item">
                        <div className="wk-field-header">
                            <label className="wk-font-weight-bold">Sort By:</label>
                        </div>
                        <div className="wk-select-field">
                            <select data-testid="ManageAlert_Dropdown" className="wk-font-weight-bold" defaultValue={SEARCH_RESULT_OPTIONS.Company_Name_Value} onChange={(e) => changeHandler(e.target.value)}>
                                <option value={SEARCH_RESULT_OPTIONS.Company_Name_Value}>{SEARCH_RESULT_OPTIONS.Company_Name_Label}</option>
                                <option value={SEARCH_RESULT_OPTIONS.Subsidiary_Name_Value}>{SEARCH_RESULT_OPTIONS.Subsidiary_Name_Label}</option>
                                <option value={SEARCH_RESULT_OPTIONS.Attorneys_last_name_Value}>{SEARCH_RESULT_OPTIONS.Attorneys_last_name_Label}</option>
                                <option value={SEARCH_RESULT_OPTIONS.Company_City_Value}>{SEARCH_RESULT_OPTIONS.Company_City_Label}</option>
                                <option value={SEARCH_RESULT_OPTIONS.Company_State_Value}>{SEARCH_RESULT_OPTIONS.Company_State_Label}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    );
}

export default SearchResultOptions;