import React from 'react';
import { useSelector } from "react-redux";
import _ from 'lodash';
import { highlightField } from 'utils/highlightField';
import { showField } from 'utils/showField';
import { RBSFilings } from "../Common/RBSFilings";

export const ReadCompany = () => {
    const companyResult = useSelector(state => state.read.companyResult); 
   
    const handleCityState = (city, state)=>{
        if (city && state) {
            return (<><div className={highlightField(companyResult, "city")}>{city}</div><div>,&nbsp;</div><div className={highlightField(companyResult, "state")}>{state}</div></>);
        }
        return (<><div className={highlightField(companyResult, "city")}>{city}</div><div className={highlightField(companyResult, "state")}>{state}</div></>);
    };
    return (
        <div id="ReadCompany" className="wk-row">
            <h3><span className={highlightField(companyResult, "currentName")}>{companyResult?.currentName}</span></h3>
            { companyResult?.formerName ? <h5 style={{ textAlign: 'center', fontStyle: 'italic' }}>Formerly {companyResult?.formerName}</h5> : ""}
            <div className="wk-col-12">
                <div className="wk-col-8">
                    <span><div className={highlightField(companyResult, "st1")}>{companyResult?.st1}</div></span>
                    <span><div className={highlightField(companyResult, "st2")}>{companyResult?.st2}</div></span>
                    <span>{handleCityState(companyResult?.city, companyResult?.state)} {companyResult?.zip ? <span>&nbsp;<div className={highlightField(companyResult, "zip")}>{companyResult?.zip}</div></span> : <></>}</span>                  
                    <span><div className={highlightField(companyResult, "country")}>{companyResult?.country}</div></span>
                    {showField(companyResult, 'phone', companyResult?.phone) && <span>
                        <div className={highlightField(companyResult, "phone")}>
                            Phone:&nbsp;{companyResult?.phone}  {companyResult?.phone && companyResult?.extension && ` x ${companyResult?.extension}`}
                        </div>
                    </span>}
                    {showField(companyResult, 'fax', companyResult?.fax) && <span><div className={highlightField(companyResult, "fax")}>Fax:&nbsp;{companyResult?.fax}</div></span>}
                </div>                  
                <div className="wk-col-4">
                    <span>{companyResult?.desc}</span>
                    <span>{companyResult?.exchange ? (companyResult?.exchange + ":") : ""} {companyResult?.ticker}</span>
                    {companyResult?.ticker && <RBSFilings ticker={ companyResult?.ticker } />}
                </div>    
            </div>
        </div>
        );
};
