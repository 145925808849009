import {get} from "./ApiService";
import querystring from "query-string";

export const getParentCompanyData = (id) => {
    let url = 'api/company/' + id + "/true";
    return get(url).then(response =>
        response
    );
};
export const getCompanyData = (id) => {
    let url = 'api/company/' + id ;
    return get(url).then(response =>
        response
    );
};
export const getCompanyLawyersData = (id) => {
    let url = 'api/companylawyer/' + id;
    return get(url).then(response =>
        response
    );
};
export const getParentCompanyLawyersData = (id) => {
    let url = 'api/companylawyer/byparentcompanyid/' + id;
    return get(url).then(response =>
        response
    );
};
export const getLawyerData = (id) => {
    let url = 'api/lawyer/' + id;
    return get(url).then(response =>
        response
    );
};
export const getCompanyColleges = (parentCompanyId, companyId, lawyerId) => {
    let url = `/api/colleges/byLawyerIdAndCompanyId?`;
    let queryString = querystring.stringify({companyId, parentCompanyId, lawyerId});
    url = url.concat(queryString);
    return get(url).then(response=> response);
}
export const getCompanyPa = (parentCompanyId, companyId, lawyerId) => {
    let url = `/api/practiceArea/byLawyerIdAndCompanyId?`;
    let queryString = querystring.stringify({companyId, parentCompanyId, lawyerId});
    url = url.concat(queryString);
    return get(url).then(response=> response);
}
export const getCompanyTitles = (parentCompanyId, companyId, lawyerId) => {
    let url = `/api/company/companyPositions?`;
    let queryString = querystring.stringify({companyId, parentCompanyId, lawyerId});
    url = url.concat(queryString);
    return get(url).then(response=> response);
}