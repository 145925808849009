import React, { useState } from 'react';
import clsx from 'clsx';
import { sendFeedback } from '../../services/sendFeedbackService';
import WaringDialog from "../../pages/Common/WarningDialog";

const Feedback = ({ isVisible, closeFeedback }) => {
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');
    const [warningVisible, setWarningVisible] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningHeader, setWarningHeader] = useState('');
    const [fieldTopicError, setFieldTopicError] = useState(false);
    const [fieldMessageError, setFieldMessageError] = useState(false);
    const [topicErrorNote, setTopicErrorNote] = useState('');
    const [messageErrorNote, setMessageErrorNote] = useState('');

    const closeDialog = (e) => {
        setWarningVisible(false);
        setMessage('');
        setTopic('');
        closeFeedback();
    }

    const handleCloseFeedback = () => {
        setMessage('');
        setTopic('');
        setFieldTopicError(false);
        setFieldMessageError(false);
        closeFeedback();
    }

    const handleTopicChange = (event) => {
        setFieldTopicError(false);
        setTopic(event.target.value);
    };

    const handleMessageChange = (event) => {
        setFieldMessageError(false);
        setMessage(event.target.value);
    };

    const handleSubmit = async () => {
        if (topic == "" || message == "") {
            if (topic == "") {
                setFieldTopicError(true);
                setTopicErrorNote('Topic required');
            } else {
                setFieldMessageError(true);
                setMessageErrorNote('Message required')
            }
        } else {
            const response = await sendFeedback({ topic, message });
            if (response == 'success') {
                setWarningVisible(true);
                setWarningMessage("Your feedback has been sent. Thank you.");
                setWarningHeader("Feedback Sent");
            } else {
                setWarningVisible(true);
                setWarningMessage("There was a problem sending your feedback. Please try again later.");
                setWarningHeader("Feedback Send Problem");
            }
        }
    };

    return (
        <div tabIndex="-1" role="dialog" id="Feedback"
            className={clsx("wk-modal wk-modal-small wk-modal-with-overlay", { "wk-modal-opened": isVisible })}>
            <section className="wk-modal-content wk-modal-content-layout">
                <header className="wk-modal-header">
                    <div className="wk-modal-title">Feedback</div>
                    <button id="btnYearsAdmitedtoBarSRPBIOverlay" onClick={handleCloseFeedback} aria-label="close dialog"
                        className="wk-button-icon wk-modal-close">
                        <span wk-icon="close" />
                    </button>
                </header>
                <div className="wk-modal-body">
                    <div className={clsx("wk-field-item", { 'wk-field-has-error': fieldTopicError})}>
                        <div className="wk-field-header">
                            <label>Topic</label>
                        </div>
                        <div className="wk-field-body">
                            <input type="text" placeholder="Enter topic (e.g. problem with content)" value={topic} onChange={handleTopicChange} />
                        </div>
                        <div className="wk-field-error">{topicErrorNote}</div>
                    </div>
                    <div className={clsx("wk-field-item", { 'wk-field-has-error': fieldMessageError })}>
                        <div className="wk-field-header">
                            <label>Message</label>
                        </div>
                        <textarea placeholder="" className="wk-textarea-no-resize wk-textarea-medium" value={message} onChange={handleMessageChange}></textarea>
                        <div className="wk-field-error">{messageErrorNote}</div>
                    </div>
                </div>
                <footer className="wk-modal-footer">
                    <div className="wk-button-bar">
                        <div className="wk-button-group-left">
                            <button onClick={handleCloseFeedback}>Cancel</button>
                        </div>
                        <div className="wk-button-group-right">
                            <button onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                </footer>
            </section>
            <WaringDialog isVisible={warningVisible} header={warningHeader} message={warningMessage} closeDialog={closeDialog} />
        </div>
    );
}

export default Feedback;