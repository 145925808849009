import React, { useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import SRPAllBIs from "./SRPAllBIs";
import { BI_TITLE } from "pages/utils/common";
import { useSelector } from "react-redux";

const SRPBIOverlay = ({ currentResult, selectedBIIndex, isVisible, closeDialog, filterData, chartType }) => {

    const currentBarChartPage = useSelector(state => state.search.barChartPage);

    useEffect(() => {
        reSizeBI();
        window.addEventListener('resize', (event) => {
            reSizeBI();
        });
    });

    const reSizeBI = () => {
        let headerEle = document.getElementById("BIOverlayHeader");
        if (headerEle != null) {
            let section = document.getElementById("BIOverlaySection");
            let header = headerEle.clientHeight;
            let margin = window.getComputedStyle(section)['marginTop'].replace('px', '');
            let bodyHeight = window.innerHeight - (margin + header != 0 ? header : 49);
            bodyHeight = margin == 0 ? bodyHeight : bodyHeight * 0.9;
            document.getElementById("BIOverlayBody").style.height = bodyHeight + 'px';
        }
    }

    let isPieChart = true;
    if (chartType != "PieChart") {
        isPieChart = false;

    }

    return (
        <div tabIndex="-1" role="dialog" id="SRPBIOverlay"
            className={clsx("wk-modal wk-modal-large wk-modal-with-overlay", { "wk-modal-opened": isVisible })}>
            <section id="BIOverlaySection" className="wk-modal-content wk-modal-content-layout">
                <header id="BIOverlayHeader" className="wk-modal-header">
                    <div className="wk-modal-title">{BI_TITLE[selectedBIIndex]}</div>
                    <button id="btnSRPBIOverlay" onClick={closeDialog} aria-label="close dialog"
                        className="wk-button-icon wk-modal-close">
                        <span wk-icon="close" />
                    </button>
                </header>

                {!isPieChart &&
                    <div id="BIOverlayBody" className="wk-modal-body" style={{ overflow: "auto", paddingTop: 0 }} >
                        <SRPAllBIs currentResult={currentResult} selectedBIIndex={selectedBIIndex} filterByLawyerIds={filterData} isPieChart={isPieChart}
                            currentBarChartPage={currentBarChartPage}
                        />
                    </div>
                }

                {isPieChart &&
                    <div id="BIOverlayBody" className="wk-modal-body" style={{ overflow: "auto" }}>
                        <SRPAllBIs currentResult={currentResult} selectedBIIndex={selectedBIIndex} filterByLawyerIds={filterData} isPieChart={isPieChart}
                            currentBarChartPage={currentBarChartPage}
                        />
                    </div>
                }

            </section>
        </div>
    );
}

export default SRPBIOverlay;