import React from 'react';
import _ from "lodash";
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import { filterMainResultsByLawyerIds } from "state/actions/searchActions";
import color from "utils/color";

const StatesResultsBi = ({ isOverlay = false }) => {
    const stats = useSelector(state => state.search.statesBiData)
    let data = stats;
    if(!isOverlay){
        data = _.take(stats, 5);
    }
    const store = useStore();
    const dispatch = useDispatch();
    const filterByLawyerIds = legendItem => {
        const result = store.getState().search.currentMainResults;
        if (result) {
            const lawyerIds = result.filter(item => item.lookupStateProvince === legendItem.text).map(item => parseInt(item.lawyerId));
            dispatch(filterMainResultsByLawyerIds(lawyerIds));
        }
    }
    return (
        <div
            id="psfStates"
        >
            <Piechart
                label="States"
                data={data.map(item => item.value)}
                labels={data.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_COMPANY_STATES_CHART)}
                backgroundColors={[...color(data.length)]}/>
        </div>
    );
};
export default StatesResultsBi;
