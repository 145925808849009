import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import queryString from "querystring";
import { useDispatch, useSelector, useStore } from "react-redux";
import { ReadHeader } from "./ReadHeader";
import { ReadCompany } from "./ReadCompany";
import { ReadLawyers } from "./ReadLawyers";
import { scrollToTop } from 'utils/scrollToTop';
import {
    companyDataRequested,
    companyLawyersDataRequested,
    lawyerDataRequested,
    parentCompanyDataRequested,
    parentCompanyLawyersDataRequested,
    RBSFilingsDataRequested,
    resetReadToInitialState
} from 'state/actions/readActions';
import CollegeBi from "./CollegesBi";
import PracticeAreaBi from "./PracticeAreaBi";
import TitlesBi from "./TitlesBi";
import { ReadParentCompany } from "pages/Read/ReadParentCompany";
import ReadBIOverlay from "./ReadBIOverlay";
import { filterAllCompanyResultsByLawyerIds } from "state/actions/readActions";
import NoRecordFound from "components/NoRecordFound";
import CreateParentAlertOverlay from "pages/Common/CreateParentAlertOverlay";
import { post } from 'services/ApiService';
import { onLocationChanged } from "state/actions/locationActions";

export const Read = ({ saveAlert }) => {
    const location = useLocation();
    const { pid, cid, lid } = queryString.parse(location.search.substring(1));
    const currentCollegesResults = useSelector(state => state.read.currentCollegesResults);
    const currentTitlesResults = useSelector(state => state.read.currentTitlesResults);
    const currentPaResults = useSelector(state => state.read.currentPaResults);
    const [selectedBIIndex, setSelectedBIIndex] = useState(-1);
    const [isPieChartVisible, setPieChartVisible] = useState(false);
    const [isHorizontalBarVisible, setHorizontalBarVisible] = useState(false);
    const [isParentAlertVisible, setParentAlertVisible] = useState(false);
    const [selections, setSelections] = useState([]);
    const [currentResult, setCurrentResult] = useState([]);
    const [isPrinting, setIsPrinting] = useState(false);
    const [isToolbarActive, setIsToolbarActive] = useState(false);
    const [parentCompanyAlertsPresent, setParentCompanyAlertsPresent] = useState([]);
    const store = useStore();
    
    const dispatch = useDispatch();
    const readParentCompany = (pid && cid && (pid != cid)) ? <ReadParentCompany /> : <div />;
    useEffect(() => {
        if (lid) {
            dispatch(companyDataRequested(cid));
            dispatch(lawyerDataRequested({
                lawyerId: lid,
                companyId: cid
            }))
        } else if (cid) {
            dispatch(companyDataRequested(cid));
            dispatch(companyLawyersDataRequested(cid));
        } else {
            dispatch(companyDataRequested(pid));
            dispatch(parentCompanyLawyersDataRequested(pid));
        }
        if (pid && cid && (pid != cid)) {
            dispatch(parentCompanyDataRequested(pid));
        }
        dispatch(RBSFilingsDataRequested());
        dispatch(onLocationChanged("/read"));
        //scroll to top of page
        scrollToTop();
        const printMq = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');
        const mqEvent = mqList => {
            if (!mqList.matches)
                setIsPrinting(mqList.matches)
        };
        document.onkeydown = onKeyPress;
        printMq.addListener(mqEvent);
        if (navigator.userAgent.indexOf("Firefox") != -1) {
            window.onafterprint = () => {
                setIsPrinting(false)
            }
        }
        return () => {
            document.onkeydown = null;
            window.onafterprint = null;
            printMq.removeListener(mqEvent);
            dispatch(resetReadToInitialState());
        }
    }, [])
    const toggleToolbar = (isActive) => {
        setIsToolbarActive(isActive);
    }

    const print = async e => {
        await setIsPrinting(true);
        window.print();
        const mainCurrentResults = store.getState().read.currentCompanyLawyers;
        await post('./api/logs', { shortInfo: "Print", logInfo: mainCurrentResults.filter(item => item.isChecked).map(c=>c.lId).toString() })
    }
    const onKeyPress = e => {
        if (e.ctrlKey && e.keyCode === 80 && !isPrinting) {
            e.preventDefault();
            print();
        }
    }

    const closeDialog = (e) => {
        setPieChartVisible(false);
        setHorizontalBarVisible(false);
    }

    const filterData = legendItem => {
        let lawyerIds;
        switch (selectedBIIndex) {
            case 0:
                lawyerIds = currentCollegesResults.filter(item => item.attbr === legendItem.text).map(item => item.lawyerId);
                break;
            case 1:
                lawyerIds = currentPaResults.filter(item => item.pa === legendItem.text).map(item => item.lawyerId);
                break;
            case 2:
                lawyerIds = currentTitlesResults.filter(item => item.title === legendItem.text).map(item => item.lawyerId);
                break;
            default:
                setCurrentResult([]);
        }
        dispatch(filterAllCompanyResultsByLawyerIds(lawyerIds));

        setPieChartVisible(false);
        setHorizontalBarVisible(false);
        setSelectedBIIndex(-1);
    }

    const showReadBIOverlay4Pie = (index) => {
        setPieChartVisible(true);
        setSelectedBIIndex(index);

        switch (index) {
            case 0:
                setCurrentResult(currentCollegesResults);
                break;
            case 1:
                setCurrentResult(currentPaResults);
                break;
            case 2:
                setCurrentResult(currentTitlesResults);
                break;
            default:
                setCurrentResult([]);
        }

    }

    const showReadBIOverlay4Horizontal = (index) => {
        setHorizontalBarVisible(true);
        setSelectedBIIndex(index);

        switch (index) {
            case 0:
                setCurrentResult(currentCollegesResults);
                break;
            case 1:
                setCurrentResult(currentPaResults);
                break;
            case 2:
                setCurrentResult(currentTitlesResults);
                break;
            default:
                setCurrentResult([]);
        }
    }

    const showParentAlertOverlay = (data, parentSelection) => {
        setSelections(parentSelection);
        setParentCompanyAlertsPresent(data);
        setParentAlertVisible(true);
    }

    const closeParentAlertOverlay = (e) => {
        setParentAlertVisible(false);
    }
    return (
        <>
            <div className="wk-row">
                <div className="wk-col-6">
                </div>
                <div className="wk-col-6">
                    <ReadHeader isToolbarActive={isToolbarActive} print={print} showParentAlertOverlay={showParentAlertOverlay} />
                </div>
            </div>
            <div id="ReadWell" className="wk-row">
                <div className="wk-row">
                    <div className="wk-col-12">
                        <ReadCompany />
                    </div>
                </div>
                <div className="wk-row">
                    <div className="wk-col-12">
                        {readParentCompany}
                    </div>
                </div>
                <div className="wk-row">
                    <div className="wk-col-12" id="corporateCounselSection">
                        <h5 style={{ textAlign: 'center' }}>Corporate Counsel</h5>
                        <div id="ReadBISection" className="wk-row">
                            <div className="wk-col-4">
                                <h6 align="center">Colleges</h6>
                                {currentCollegesResults?.length > 0 && (
                                    <>
                                        <CollegeBi
                                            currentCollegesResults={currentCollegesResults} />
                                        <p className="wk-search-bi-showall"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Pie(0)}>Show More - Pie Chart</a></p>
                                        <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Horizontal(0)}>Show More - Bar Chart</a></p>
                                    </>)}
                                {currentCollegesResults?.length === 0 && <NoRecordFound />}
                            </div>
                            <div className="wk-col-4">
                                <h6 align="center">Practice Areas</h6>
                                {currentPaResults?.length > 0 && (
                                    <>
                                        <PracticeAreaBi currentPaResults={currentPaResults} />
                                        <p className="wk-search-bi-showall"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Pie(1)}>Show More - Pie Chart</a></p>
                                        <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Horizontal(1)}>Show More - Bar Chart</a></p>

                                    </>)}
                                {currentPaResults?.length === 0 && <NoRecordFound />}

                            </div>
                            <div className="wk-col-4">
                                <h6 align="center">Titles</h6>
                                {currentTitlesResults?.length > 0 && (
                                    <>
                                        <TitlesBi currentTitlesResults={currentTitlesResults} />
                                        <p className="wk-search-bi-showall"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Pie(2)}>Show More - Pie Chart</a></p>
                                        <p className="wk-search-bi-showall-hb"><a className="wk-link"
                                            onClick={() => showReadBIOverlay4Horizontal(2)}>Show More - Bar Chart</a></p>

                                    </>)}
                                {currentTitlesResults?.length === 0 && <NoRecordFound />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wk-row">
                    <div className="wk-col-12">
                        <ReadLawyers toggleToolbar={toggleToolbar} isPrinting={isPrinting} />
                    </div>
                </div>

                {isPieChartVisible && <ReadBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isPieChartVisible} closeDialog={closeDialog} filterData={filterData} chartType="PieChart" />}
                {isHorizontalBarVisible && <ReadBIOverlay currentResult={currentResult} selectedBIIndex={selectedBIIndex} isVisible={isHorizontalBarVisible} closeDialog={closeDialog} filterData={filterData} chartType="HorizontalBarChart" />}
                {isParentAlertVisible && <CreateParentAlertOverlay saveAlert={saveAlert} isVisible={isParentAlertVisible} closeOverlay={closeParentAlertOverlay} selections={selections} parentCompanyAlertsPresent={parentCompanyAlertsPresent} maxWarningCompanies={false} />}
            </div>
        </>
    );
};


