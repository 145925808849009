import React from 'react';
import { ReadCheckedLawyers } from "pages/ReadChecked/ReadCheckedLawyers";
import _ from 'lodash';
import { highlightField } from 'utils/highlightField';
import { showField } from 'utils/showField';
import { RBSFilings } from "../Common/RBSFilings";
export const ReadCheckedCompany = ({ toggleToolbar, companyResult, isPrinting }) => {
    const handleCityState = (city, state) => {
        if (city && state) {
            return (<><div className={highlightField(companyResult?.companyInfo, "city")}>{city}</div><div>,&nbsp;</div><div className={highlightField(companyResult?.companyInfo, "state")}>{state}</div></>);
        }
        return (<><div className={highlightField(companyResult?.companyInfo, "city")}>{city}</div><div className={highlightField(companyResult?.companyInfo, "state")}>{state}</div></>);
    };
    const handleParentCityState = (city, state) => {
        if (city && state) {
            return (<><div className={highlightField(companyResult?.parentCompanyInfo, "city")}>{city}</div><div>,&nbsp;</div><div className={highlightField(companyResult?.parentCompanyInfo, "state")}>{state}</div></>);
        }
        return (<><div className={highlightField(companyResult?.parentCompanyInfo, "city")}>{city}</div><div className={highlightField(companyResult?.parentCompanyInfo, "state")}>{state}</div></>);
    };
    const lawyersList = (companyResult, isPrinting) => {
        if (!isPrinting) return companyResult.lawyers;
        return companyResult.lawyers.filter(l => l.checked == true);

    }

    const companyInfoShown = (companyResult, isPrinting) => {

        return lawyersList(companyResult, isPrinting).length > 0;
    }
    return (
        <>
            <br />
            {companyInfoShown(companyResult, isPrinting) && <div className="wk-row read-checked-company">
                <h3><span className={highlightField(companyResult?.companyInfo, "currentName")}>{companyResult?.companyInfo?.currentName}</span></h3>
                <div className="wk-col-12">
                    <div className="wk-col-8">
                        <span><div className={highlightField(companyResult?.companyInfo, "st1")}>{companyResult?.companyInfo?.st1}</div></span>
                        <span><div className={highlightField(companyResult?.companyInfo, "st2")}>{companyResult?.companyInfo?.st2}</div></span>
                        <span>{handleCityState(companyResult?.companyInfo?.city, companyResult?.companyInfo?.state)} {companyResult?.companyInfo?.zip ? <span>&nbsp;<div className={highlightField(companyResult?.companyInfo, "zip")}>{companyResult?.companyInfo?.zip}</div></span>:<></>}</span>
                        <span><div className={highlightField(companyResult?.companyInfo, "country")}>{companyResult?.companyInfo?.country}</div></span>
                        {showField(companyResult?.companyInfo, "phone", companyResult?.companyInfo?.phone) && <span>
                            <div className={highlightField(companyResult?.companyInfo, "phone")}>
                                Phone:&nbsp;{companyResult?.companyInfo?.phone} {companyResult?.companyInfo?.phone && companyResult?.companyInfo?.extension && ` x ${companyResult?.companyInfo?.extension}`}
                            </div>
                        </span>}
                        {showField(companyResult?.companyInfo, "fax", companyResult?.companyInfo?.fax) && <span><div className={highlightField(companyResult?.companyInfo, "fax")}>Fax:&nbsp;{companyResult?.companyInfo?.fax}</div></span>}
                    </div>
                    <div className="wk-col-4">
                        <span>{companyResult?.companyInfo?.desc}</span>
                        <span>{companyResult?.companyInfo?.exchange ? (companyResult?.companyInfo?.exchange + ":") : ""} {companyResult?.companyInfo?.ticker}</span>
                        {companyResult?.companyInfo?.ticker && <RBSFilings ticker={companyResult?.companyInfo?.ticker} />}
                    </div>
                </div>
            </div>}
            {companyInfoShown(companyResult, isPrinting) && <div className="wk-row read-checked-company">
                <h5 style={{ textAlign: 'center' }}>A subsidiary of</h5>
                <h3><span className={highlightField(companyResult?.parentCompanyInfo, "currentName")}>{companyResult?.parentCompanyInfo?.currentName}</span></h3>
                {companyResult?.parentCompanyInfo?.formerName ? <h5 style={{ textAlign: 'center', fontStyle: 'italic' }}>Formerly {companyResult?.parentCompanyInfo?.formerName}</h5> : ""}
                <div className="wk-col-12">
                    <div className="wk-col-8">
                        <span><div className={highlightField(companyResult?.parentCompanyInfo, "st1")}>{companyResult?.parentCompanyInfo?.st1}</div></span>
                        <span><div className={highlightField(companyResult?.parentCompanyInfo, "st2")}>{companyResult?.parentCompanyInfo?.st2}</div></span>
                        <span>{handleCityState(companyResult?.parentCompanyInfo?.city, companyResult?.parentCompanyInfo?.state)} &nbsp;<div className={highlightField(companyResult?.parentCompanyInfo, "zip")}>{companyResult?.parentCompanyInfo?.zip}</div></span>
                        <span><div className={highlightField(companyResult?.parentCompanyInfo, "country")}>{companyResult?.parentCompanyInfo?.country}</div></span>
                        {showField(companyResult?.companyInfo, "phone", companyResult?.companyInfo?.phone) && <span><div className={highlightField(companyResult?.parentCompanyInfo, "phone")}>Phone:&nbsp;{companyResult?.parentCompanyInfo?.phone}</div></span>}
                        {showField(companyResult?.companyInfo, "fax", companyResult?.companyInfo?.fax) && <span><div className={highlightField(companyResult?.parentCompanyInfo, "fax")}>Fax:&nbsp;{companyResult?.parentCompanyInfo?.fax}</div></span>}
                    </div>
                    <div className="wk-col-4">
                        <span>{companyResult?.parentCompanyInfo?.desc}</span>
                        <span>{companyResult?.parentCompanyInfo?.exchange ? (companyResult?.parentCompanyInfo?.exchange + ":") : ""} {companyResult?.parentCompanyInfo?.ticker}</span>
                        {companyResult?.parentCompanyInfo?.ticker && <RBSFilings ticker={companyResult?.parentCompanyInfo?.ticker} />}
                    </div>
                </div>
            </div>}
            <br />
            <div className="wk-row">
                <ReadCheckedLawyers toggleToolbar={toggleToolbar} companyLawyers={lawyersList(companyResult, isPrinting)} isPrinting={isPrinting} />
            </div>
        </>
    );
};
