import React from "react";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router";

const BreadcrumbComponent = ({ breadcrumbs }) => {
    const location = useLocation();
    const history = useHistory();
    let getBreadCrumb = (path, url, breadcrumb) => {
        if (path === "/search/readchecked") {
            return <li key={url}><span>Read</span></li>
        }
        if (location.pathname !== "/search" && path === "/search") {
            if (document.referrer.includes(document.location.host) && document.referrer.includes("/search?")) {
                let newUrl = document.referrer.replace(document.location.origin, "");
                return (
                    <li key={newUrl}>
                        <a
                            onClick={e => {
                                e.preventDefault();
                                history.push(newUrl);
                            }}
                            href={newUrl}>
                            <span>{breadcrumb}</span>
                        </a>
                    </li>);
            }
        }
        if (path === "/")
            return (
                <li key={url}>
                    <Link
                        to={url}>
                        <span>{breadcrumb}</span>
                    </Link>
                </li>);
        if (path === location.pathname)
            return <li key={url}><span>{breadcrumb}</span></li>
        return (
            <li key={url}>
                <a
                    onClick={e => {
                        e.preventDefault();
                        history.goBack();
                    }}
                    href={url}>
                    <span>{breadcrumb}</span>
                </a>
            </li>);
    };
    return (
        <div className="wk-breadcrumb-container">
            <ol className="wk-breadcrumb">
                {breadcrumbs.map(({ breadcrumb, match }) => getBreadCrumb(match.path, match.url, breadcrumb))}
            </ol>
        </div>
    )
}
export default withBreadcrumbs()(BreadcrumbComponent)
