import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import NoRecordFound from 'components/NoRecordFound';
import {
    toggleReadToolbar, updateCompanyLawyersData
} from "../../state/actions/readActions";
import { highlightField } from 'utils/highlightField';
import { READ_LAWYER_LABELS } from '../utils/common';

export const ReadLawyers = ({ toggleToolbar, isPrinting }) => {
    const dispatch = useDispatch();
    let companyLawyers = useSelector(state => state.read.currentCompanyLawyers);
    const [selectAll, setSelectAll] = useState(false);
    useEffect(() => {
        if (companyLawyers.length === companyLawyers.filter(item => item.isChecked === true).length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [companyLawyers]);
    if (isPrinting) {
        companyLawyers = companyLawyers.filter(item => item.isChecked);
    }
    const parseEducation = (colleges, degrees) => {
        return colleges?.split("|").map((clg, index) => {
            return (clg + parseDegree(degrees?.split("|")[index]))
        }).join("|");
    }
    const parseDegree = (degree) => {
        return ((degree && degree?.trim() != "0") ? (" (" + (degree?.replace(" 0", "").trim()) + ")") : "")
    }
    const handleAllChecked = (event) => {
        const lawyers = companyLawyers.map(lawyer => {
            return { ...lawyer, isChecked: event.target.checked }
        });
        dispatch(updateCompanyLawyersData(lawyers));
        handleReadToolbar(lawyers);
    }

    const handleCheckLawyerElement = (event) => {
        const lawyers = companyLawyers.map(lawyer => {
            if (lawyer.lId == event.target.value)
                return { ...lawyer, isChecked: event.target.checked }
            return lawyer;
        });
        dispatch(updateCompanyLawyersData(lawyers));
        handleReadToolbar(lawyers);
    }
    const handleReadToolbar = (lawyers) => {
        if (lawyers.some(item => item.isChecked == true)) {
            toggleToolbar(true);
        } else {
            toggleToolbar(false);
        }

    }
    return (
        <div id="ReadLawyers">
            {(companyLawyers && companyLawyers.length > 0) ? <div>
                <span id="readSelectAll">
                    <input type="checkbox"
                           onChange={(event) => handleAllChecked(event)}
                           checked={selectAll}/>
                    <b>Check/Uncheck All</b>
                </span>
                    {companyLawyers?.map((ccr, index) => (
                        <div key={ccr.lId} className="wk-row lawyer-list-item">
                            <div className="wk-col-12">
                                <div className="wk-col-4" id="LawyerPracticeareas">
                                    <input type="checkbox"
                                           value={ccr.lId}
                                           onChange={(event) => handleCheckLawyerElement(event)}
                                           checked={ccr.isChecked}/><span><b>
                                        <span className={highlightField(ccr, "firstName")}>{ccr?.firstName}</span>&nbsp;
                                    <span className={highlightField(ccr, "lastName")}>{ccr?.lastName}</span>&nbsp;
                                        {ccr?.lawyerSuffix ? <span>{ccr?.lawyerSuffix}&nbsp;</span> : <></>}
                                </b>{ccr?.email ? <span style={{ display: "inline-block" }}
                                                        className={highlightField(ccr, "email")}>{"(" + ccr?.email + ")"}</span> :
                                    <span></span>}</span>
                                    {(ccr?.practiceAreas && ccr?.practiceAreas?.split("|").length > 0) ?
                                        <div><span className="lawyer-info"><b>{ READ_LAWYER_LABELS.Practice_Areas }</b></span>
                                            <ul>{ccr?.practiceAreas?.split("|").map(pa => (
                                                <li key={ccr.lId + "-" + pa}>{pa}</li>))}
                                            </ul>
                                        </div> : ""}
                                    <span
                                        className="lawyer-info">{ccr?.yearJoined ? <span><b>{READ_LAWYER_LABELS.Joined_Firm}</b><span
                                            className={highlightField(ccr, "yearJoined")}>{ccr?.yearJoined}</span></span> :
                                        <span></span>}</span>
                                </div>
                                <div className="wk-col-4">
                                    <span
                                        className={highlightField(ccr, "position") + " lawyer-info"}>{ccr?.position}</span>
                                    {(ccr?.barInfo && ccr?.barInfo.split("|").length > 0) ? <div>
                                        <span className="lawyer-info"><b>{READ_LAWYER_LABELS.US_State_Non_US_Bars}</b></span>
                                        <ul>{ccr?.barInfo.split("|").map(bi => (<li key={ccr.lId + "-" + bi}>{bi}</li>))}
                                        </ul>
                                    </div> : ""}                                   
                                    {
                                        (ccr?.colleges || ccr?.degrees) ? <div>
                                            <span className="lawyer-info"><b>{READ_LAWYER_LABELS.Education}</b></span>
                                            <ul>{parseEducation(ccr?.colleges, ccr?.degrees).split("|").map(ed => (
                                                ed ? <li key={ccr.lId + "-" + ed}>{ed}</li> : ""
                                            ))}
                                            </ul>
                                        </div> : ""
                                    }
                                </div>
                                <div className="wk-col-4">
                                    {
                                        (ccr?.phone) ? <span
                                            className={highlightField(ccr, "phone") + " lawyer-info"}><b>{READ_LAWYER_LABELS.Telephone_Number}</b>&nbsp;<span>{ccr?.phone}</span></span> : ""
                                    }
                                    {(ccr?.prevPos && ccr?.prevPos.split("|").length > 0) ? <div>
                                        <span className="lawyer-info"><b>{READ_LAWYER_LABELS.Previous_Positions}</b></span>
                                        <ul className={highlightField(ccr, "prevPos")}>{ccr?.prevPos.split("|").map(pp => (
                                            <li key={ccr.lId + "-" + pp}>{pp}</li>))}
                                        </ul>
                                    </div> : ""}                                    
                                    {(ccr?.memberships && ccr?.memberships.split("|").length>0) ? <div>
                                        <span
                                        ><b>{READ_LAWYER_LABELS.Memberships_Affiliations}</b></span>
                                        <ul className={highlightField(ccr, "memberships")}>{ccr?.memberships.split("|").map(mem => (
                                            <li key={ccr.lId + "-" + mem}>{mem}</li>))}
                                        </ul>
                                        </div> : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                : <NoRecordFound/>
            }

        </div>
    );
};