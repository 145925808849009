import {
    SPINNER_CHANGED,
} from "../actions/spinnerActions";

const initialState = {
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SPINNER_CHANGED:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        default:
            return state;
    }
}
