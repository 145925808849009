import React  from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useHistory } from "react-router";
import color from "../../utils/color";
import { logAndRedirectToErrorPage } from 'components/ErrorComponent';
import { useSelector } from "react-redux";
import { createLoadingSelector } from "../../state/selectors/loadingSelector";

const TopCompaniesBi = ({ selectedStates, searchWarning}) => {
    const history = useHistory();
    const topCitiesBiData = useSelector(state => state.home.topCities);
    const loadingSelector = createLoadingSelector(['HOME_DATA_TOP_CITIES']);
    const loading = useSelector(state => loadingSelector(state));
    const triggerSearch = legendItem => {
        try {
            let queryString = `cc=${legendItem.text}`
            if (selectedStates && selectedStates.length > 0) {
                queryString = queryString.concat("&cs=" + selectedStates.join("|"))
            }
            searchWarning(queryString);
        } catch (error) { logAndRedirectToErrorPage(error, history);}
    }
    return (
        <div>
            {loading && <span wk-icon="spinner" className="wk-spin" style={{ "verticalAlign": "middle" }}/>}
            {!loading && <Piechart
                label="Top Cities"
                data={topCitiesBiData.map(item => item.data)}
                labels={topCitiesBiData.map(item => item.label)}
                ref={registerTestObject(BI.HOME_COMPANY_CITIES_CHART)}
                legendCallBack={(event, legendItem) => triggerSearch(legendItem)}
                backgroundColors={[...color(topCitiesBiData.length)]} />}
        </div>
    );
};
export default TopCompaniesBi;
