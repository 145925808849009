import dayjs from "dayjs";
import { logAndRedirectToErrorPage, redirectToErrorPage } from "../components/ErrorComponent";
import { history } from "../routerHistory";
import { AuthenticationError } from "../common/AuthenticationError";

const controller = new AbortController();
const { signal } = controller;

export const downloadLawyers = async lawyerIds => {
    try {
        const date = dayjs().format("DD-MM-YYYY");
        const response = await fetch('api/lawyer/download', {
            body: JSON.stringify({ lawyerIds }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            signal
        });
        if (response.status === 403) {
            controller.abort();
            window.location.href = window.location.origin + "/Account/Login?returnUrl=" + encodeURIComponent(window.location.pathname + window.location.search);
            throw new AuthenticationError("User not authenticated!");
        }
        if (response.status && (response.status === 400 || response.status >= 500)) {
            logAndRedirectToErrorPage(response.json(), history);
        }
        const blob = await response.blob();
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `ccp-data-${date}.csv`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    } catch (e) {
        if (e instanceof AuthenticationError)
            throw e;
        redirectToErrorPage(history);
    }
}