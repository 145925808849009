import { get, post } from "./ApiService";
import { prepareQueryString } from "utils/prepareQueryString";

export const getYearsAdmittedtoBarResultsBySearch = (payload) => {
    let url = 'api/yearsadmitedtobar/byBISRPSearch?';
    return get(prepareQueryString(url, payload)).then(response =>
        response
    );
};
export const getYearsAdmitedtoBarResults = async (lawyerIds) => {
    let url = 'api/yearsadmitedtobar/byLawyerId';
    return await post(url, {lawyerIds});
};