import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import SearchForm from '../Home/SearchForm';
import CollegeBi from "./CollegeBI";
import MapChart from "./MapChart";
import PracticeAreaBi from "./PracticeAreaBI";
import YearAdmittedtobarBI from './YearAdmittedtobarBI';
import StatesBi from "./StatesBI";
import TopCompanyCitiesChart from './CitiesBI';
import Statistics from "./Statistics";
import { get } from "services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { spinnerChangedAction } from "state/actions/spinnerActions"
import WaringDialog from "pages/Common/WarningDialog";
import { practiceAreas } from '../Home/Data/config';
import {
    loadHomeData,
    loadHomeDataByStates,
    loadTopCities,
    loadTopColleges,
    loadCounselCountByState,
    loadTopPracticeAreas,
    loadTopYearAdmittedToBars,
} from "state/actions/homeActions";
import { onLocationChanged } from "state/actions/locationActions";

const Home = () => {
    const dispatch = useDispatch();
    const selectedStates = useSelector(state => state.home.selectedStates);
    const totalCount = useSelector(state => state.home.totalCounselCount);
    const totalCountWithEmail = useSelector(state => state.home.totalCounselEmailCount);
    const [isSearchWarningVisible, setSearchWarningVisible] = useState(false);
    const [searchWarningHeader, setSearchWarningHeader] = useState("");
    const [searchWarningBody, setSearchWarningBody] = useState("");
    const [initialRender, setInitialRender] = useState(true);
    let history = useHistory();
    useEffect(() => {
        if (!initialRender)
            dispatch(loadHomeDataByStates(selectedStates));
    }, [selectedStates]);
    useEffect(() => {
        if (totalCount === undefined) {
            dispatch(loadHomeData(selectedStates));
            dispatch(loadTopCities(selectedStates));
            dispatch(loadTopColleges(selectedStates));
            dispatch(loadCounselCountByState(selectedStates));
            dispatch(loadTopPracticeAreas(selectedStates));
            dispatch(loadTopYearAdmittedToBars(selectedStates));
        }
        dispatch(onLocationChanged("/"));
        setInitialRender(false);
    }, []);
    const closeSearchWarningDialog = (e) => {
        setSearchWarningVisible(false);
    }
    const searchWarning = (queryString, header, body) => {
        dispatch(spinnerChangedAction(true));
        get('./api/search/count?' + queryString)
            .then(data => {
                dispatch(spinnerChangedAction(false));
                if (data > 10000) {
                    setSearchWarningHeader(header || "Search Warning");
                    setSearchWarningBody(body || "You have selected a combination of States that delivers too many results. Please remove a State or select a different combination.");
                    setSearchWarningVisible(true);
                } else {
                    setSearchWarningVisible(false);
                    history.push({ pathname: "/search", search: queryString });
                }
            })
            .catch(e => {
                dispatch(spinnerChangedAction(false));
            });
    };
    return (
        <div>
            <div className="wk-row">
                <div className="wk-col-3">
                    <SearchForm practiceAreas={practiceAreas} searchWarning={searchWarning}/>
                </div>
                <div className="wk-col-9" style={{ float: "left" }}>
                    <div className="wk-row">
                        <div className="wk-col-12">
                            <Statistics totalCount={totalCount} totalCountWithEmail={totalCountWithEmail}
                                        selectedStateCodes={selectedStates} searchWarning={searchWarning}/>
                        </div>
                    </div>
                    <div className="wk-row">
                        <div className="wk-col-12">
                            <MapChart/>
                        </div>
                    </div>
                    <div className="wk-row">
                        <div className="wk-col-6">
                            <h4>Counsel by State</h4>
                            <StatesBi selectedStates={selectedStates}
                                      searchWarning={searchWarning}/>
                        </div>
                        <div className="wk-col-6">
                            <h4>Top 5 US Colleges</h4>
                            <CollegeBi selectedStates={selectedStates} searchWarning={searchWarning}/>
                        </div>
                    </div>
                    <br/>
                    <div className="wk-row">
                        <div className="wk-col-6">
                            <h4>Top 5 Practice Areas</h4>
                            <PracticeAreaBi practiceAreas={practiceAreas} selectedStates={selectedStates}
                                            searchWarning={searchWarning}/>
                        </div>
                        <div className="wk-col-6">
                            <h4>Top 5 Year Admitted to Bar</h4>
                            <YearAdmittedtobarBI selectedStates={selectedStates} searchWarning={searchWarning}/>
                        </div>
                    </div>
                    <br/>
                    <div className="wk-row">
                        <div className="wk-col-6">
                            <h4>Top 5 Company Cities</h4>
                            <TopCompanyCitiesChart
                                selectedStates={selectedStates} searchWarning={searchWarning}/>
                        </div>
                        <div className="wk-col-6">
                        </div>
                    </div>
                </div>
            </div>
            <WaringDialog isVisible={isSearchWarningVisible} header={searchWarningHeader} message={searchWarningBody}
                          closeDialog={closeSearchWarningDialog}/>
        </div>
    );
}

export default Home;
