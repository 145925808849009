import React from 'react';
import { Piechart } from "components/charts/PieChart";
import { registerTestObject, BI } from 'utils/testUtils';
import { useDispatch, useSelector, useStore } from "react-redux";
import _ from "lodash";
import { filterMainResultsByLawyerIds } from "state/actions/searchActions";
import color from "../../utils/color";

const CitiesResultsBi = ({isOverlay = false}) => {
    const stats = useSelector(state => state.search.cityBiData);
    let data = stats;
    const dispatch = useDispatch();
    const store = useStore();
    let top = 5;
    if(!isOverlay){
        data = _.take(stats, top);
    }
    const filterByLawyerIds = legendItem => {
        const result = store.getState().search.currentMainResults;
        if (result) {
            const lawyerIds = result.filter(item => item.city === legendItem.text).map(item => item.lawyerId);
            dispatch(filterMainResultsByLawyerIds(lawyerIds));
        }
    }

    return (
        <div
            id="psfCities"
        >
            <Piechart
                label="Top Cities"
                data={data.map(item => item.value)}
                labels={data.map(item => item.data)}
                legendCallBack={(event, legendItem) => filterByLawyerIds(legendItem)}
                ref={registerTestObject(BI.PSF_COMPANY_CITIES_CHART)}
                backgroundColors={[...color(data.length)]}/>
        </div>
    );
};
export default CitiesResultsBi;
