import React, { useEffect, useState } from 'react';
import { get } from "services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { productsRequested } from "../../state/actions/homeActions";

function Products() {
    const items = useSelector(state => state.home.products);
    let sortedItems = items.sort((a, b) => (a.name > b.name) ? 1 : -1)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(productsRequested());
    }, []);

    return (
        <ul>
            {
                sortedItems?.filter((item) => item.url != null).map(item => (
                    <li key={item.cpid}>
                        <a href={item.url + "?cpid=" + item.cpid} target="_blank" rel="noopener noreferrer">
                            {item.name}
                        </a>
                    </li>
                ))
            }
        </ul>
    );
}

export default Products;